<template>
    
        <div>
            <Separador :id="1"/>
        </div>
<div  class="margin-q">
    <v-row justify="center">
        <v-col cols="4" >
            <v-img
                :class="'opacity-'+value1"
                src="../../assets/logos/EsamLatam-text-black.png"
                width="100%"
                aspect-ratio="1"
            >
            </v-img>
        </v-col>
        <v-col cols="12">
            <v-carousel
            cycle
            height="max-height"
            hide-delimiter-background
            :show-arrows="false"
            class="text-white "
            hide-delimiters
        >
            
            <v-carousel-item
            src="../../assets/sobreNosotros.png"
            cover
            >
            </v-carousel-item>
        </v-carousel>
        </v-col>
    </v-row>
  </div>
</template>

<script>

import Separador from '@/components/Separador.vue'
export default {
  name: 'EsamQuienesSomos',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
    Separador,
  },
  computed : {
    
    width_video() {
      if (screen.width > 0 && screen.width <= 600) {
        return "440";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "640";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "840";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "1040";
      }

      if (screen.width > 1904) {
        return "1240";
      }
      return "small";
    },
  },
 methods: {
     getheight(){
        if (screen.width > 0 && screen.width <= 600) {
            this.carouselheight=250
        }
        if (screen.width > 600 && screen.width <= 960) {
            this.carouselheight=350
        }
        if (screen.width > 960 && screen.width <= 1264) {
            this.carouselheight=400
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            this.carouselheight=500
        }
        if (screen.width > 1904) {
            this.carouselheight=650
        }
     },
  
   
 },
  data(){
    return {
      carouselheight: 0,
      baseUrl : "https://esamlatam.com/",
      //baseUrl : "http://localhost/",
       baseImagen : "imagenes/img-slider/",
      sliders : [],
      interval1: {},
        value1: 10,
    };

  },
  mounted(){
    this.interval1 = setInterval(() => {
      if (this.value1 === 100) {
        return (this.value1 = 10)
      }
      this.value1 += 10
    }, 280)
    }

      

}
</script>

<style scoped>
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}
.opacity-10{
opacity: 0.1
}
.opacity-20{
opacity: 0.2
}
.opacity-30{
opacity: 0.3
}
.opacity-40{
opacity: 0.4
}
.opacity-50{
opacity: 0.5
}

.opacity-60{
opacity: 0.6
}
.opacity-70{
opacity: 0.7
}
.opacity-80{
opacity: 0.8
}
.opacity-90{
opacity: 0.9
}
.opacity-100{
opacity: 1
}
.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.videotam{
 width: "50%" ;
}
.video-width{
 width: 100% ;
 height: auto;
}
.margin-q{
    margin: 50px 0px 50px 0px;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
 }
@media (min-width: 1905px)  { 
 }
</style>
