<template>
    <Navbar @id_categoria="set_id_cat=$event" @activar="categoria=$event" @activar1="posgrados=$event" @posgrados="posgrados=$event" @pagina_id="pagina_id =$event" @pagina_con="pagina=$event" />
    <div class="margin-nabar">

    </div>
    <v-row>
      <v-col cols="12" class="padding-col">
        <div id="inicio"  v-if="!activador">
          <Carousel/>
        </div>
      </v-col>
      <v-col cols="12">
        <Separador :id="1"/>
      </v-col>
    </v-row> 
    <h1>{{activar1}}</h1>
    <div id="posgrado" v-if="categoria">
      <Diplomando :id="set_id_cat"  @categoria_id="categoria_id=$event" @posgrados="posgrados=$event" @categoria="categoria=$event" />
    </div>
    <div id="postgrados" v-if="posgrados">
      <Postgrado :categoria_id="categoria_id"/>
    </div>
    <div id="pagina" v-if="pagina">
      <v-row justify="center">
        <v-col
          cols="12" class="padding-col">
          <QuienesSomos />
        </v-col>
      </v-row>
    </div>
    
  <Bandera  v-if="!activador" />
  
    <Convenios id="covenios" v-if="false" />
  <div id="tarjeta" v-if="!activador">
    <Tarjeta @id_categoria="set_id_cat=$event" @activar="categoria=$event" v-if="!setcondicion"/>
  </div>
    <div v-if="false">
      <Posgrado :id_posgrado="id_posgrado" :imgp="imgp" :condicionp="condicionp"/>
    </div>
    <div id="posgrado" v-if="false">
      <div id="programa" v-if="posgrados">
        <v-row justify="center">
          <v-col
            cols="10"
            sm="10" 
            md="10"
            lg="10"
            xl="10">
            

          </v-col>
          <v-col
            cols="10"
            sm="10"
            md="10"
            lg="10"
            xl="10"
          >
        <Programa :id_categoria="set_id_cat" :condicion="posgrados"  @condicionp="posgrados=$event" />
          </v-col>
        </v-row>
      </div>
    </div>
    <v-row justify="center" v-if="!activador">
      <v-col cols="12" v-if="1===0">
        <div class="background-001C29" v-if="!activador">
          
          <v-row justify="center">
            <v-col
              cols="12"
            >
              <Separador :id="3"/>
            </v-col>
            <v-col cols="12">
              <Estadisticas/>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="12">
        <Separador :id="2"/>
      </v-col>
  </v-row>



<!-- inicio de descuento -->
<v-row  v-if="!activador">
  <v-col cols="12">
    <div class=" background-DADADA " v-if="!activador">
      
      <v-row justify="center">
        <v-col
          cols="10"
          sm="10"
          md="10"
          lg="10"
          xl="10"
        >
          <h3 class="text-center text-001C29 text-keep-calm-font text-estudiar-tan"><v-icon class="text-F20780">mdi-chevron-right</v-icon>¿POR QUÉ ESTUDIAR CON NOSOTROS?</h3>
        </v-col>
        <v-col
          cols="12"
        >
          <Motivacion />
        </v-col>
      </v-row>
    </div>
  </v-col>
    <v-col cols="12">
      <Separador :id="3"/>
    </v-col>
  </v-row>
<div class="partners-margin" v-if="!activador">
  <v-carousel
    cycle
    hide-delimiter-background
    hide-delimiters
    :show-arrows="false"
    class="text-white "
    height="100%"
  >
  <template 
        v-for="(slid, index) in sliders" v-bind:key="index">
    <v-carousel-item
      :src="baseUrl+''+baseImagen+''+slid.imagen"
      
    >
        <!--<div class="padding-descuento">
            <h3 class="text-KeepCalm-Book  margin-0 text-descuento-title1 text-white">{{slid.nombre}}</h3>
            <h1 class="text-KeepCalm-Heavy text-descuento-30p text-F59C1B">{{slid.descripcion}}</h1>
          </div>-->
          <!--<div class="padding-descuento-bn">
            <v-btn class="background-F59C1B text-001C29 text-keep-calm-font  " rounded="pill" :size="sizeBoton">
              VER MÁS DETALLES
            </v-btn>
          </div>-->
    </v-carousel-item>
  </template>
  </v-carousel>

</div>
  <v-row justify="center" v-if="!activador">
    <v-col cols="12">
      <div class=" background-DADADA " v-if="!activador">
        <v-row justify="center">
            <v-col cols="12">
              <Separador :id="3"/>
            </v-col>
        <v-col
          cols="10"
          sm="10"
          md="10"
          lg="10"
          xl="10"
        >
          <h3 class="text-001C29 text-keep-calm-font text-logros-tan text-center"><v-icon class="text-F20780">mdi-chevron-right</v-icon>TUS LOGROS SON LO QUE NOS IMPULSA</h3>
        </v-col>
        
        <v-col
          cols="12"
        >
          <Testimonios />
        </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col cols="12">
      <Separador :id="2"/>
    </v-col>
    </v-row>


<!-- inicio de biblioteca -->


<Contacto v-if="!activador" />


    <div v-if="!activador" class="mb-6">
      <Contenido/>
    </div>
    <div class="div-fin">
      <Separador :id="1"/>
    </div>
<!-- fin de contenido sedes -->
<v-btn class="btn-flotante" icon="mdi-whatsapp" v-on:click="hrefopen('http://wa.me/59173606504')" ></v-btn>
<!--<a href="#" class="btn-flotante">Llamada a la acción</a>-->

   <v-btn 
        :icon="show ? 'mdi-window-minimize' : 'mdi-message'"
        @click="show = !show"
        class="background-1C75BC btn-chat1"
        v-if="!show"
      ></v-btn>
 <v-card
    class="mx-auto btn-chat"
    max-width="344"
    v-if="show"
  >
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn 
        :icon="show ? 'mdi-window-minimize' : 'mdi-message'"
        @click="show = !show"
        class="background-1C75BC"
      ></v-btn>
    </v-card-actions>

     

    <v-expand-transition class="background-DADADA">
      <div v-show="show">
        <v-divider></v-divider>

        <v-card-text>
          Hola, soy Bolt, el asistente virtual de Esam Latam ⚡️
        </v-card-text>
        <v-card-text>
          Opero mejor cuando me hacen preguntas cortas y directas
        </v-card-text>
        <v-card-text>
          ¿Cómo puedo ayudar
        </v-card-text>
        <v-btn
            variant="outlined"
            color="primary"
          >
            planes y Precios
          </v-btn>
      </div>
    </v-expand-transition>
  </v-card>
   <v-dialog
      v-model="dialog"
      persistent
    >
    <v-card class="dialog_tan">
      <v-form 
      v-model="valid"
      lazy-validation
      ref="form">
      <v-toolbar
        class="background-001C29 text-white text-center"
      >AYÚDANOS A MEJORAR TU EXPERIENCIA DE NAVEGACIÓN</v-toolbar>
      <v-card-text>
        <v-row justify="center">
            <v-col
              cols="12"
            >
            <h5 class=" text-001C29 text-KeepCalm-Medium text-numero-ta mb-6">Número de celular</h5>  
            <div class="d-flex justify-center">
              <v-btn
                rounded="lg"
                class="background-001C29 text-white"
                height="50"
              >
                <v-img
                  src="../assets/mexico.webp"
                  aspect-ratio="1"
                  class="tan-bandera "
                >
                </v-img>
                <v-icon>mdi-chevron-down</v-icon> +52
              </v-btn>
              <v-text-field
                  variant="outlined"
                  class="text-001C29"
                  label="Celular"
                  required
              ></v-text-field>
            </div>  
            <h5 class=" text-001C29 text-KeepCalm-Medium text-numero-ta mb-6">Correo Electrónico </h5>             
              <v-text-field
                  variant="outlined"
                  class="text-001C29"
                  label="Email"
                  required
              ></v-text-field>
              
              <v-checkbox v-model="checkbox" 
              color="orange-darken-3" class="text-001C29">
                <template v-slot:label>
                  <div>
                    He leído y acepto las 
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://vuetifyjs.com"
                          @click.stop
                          v-on="on"
                        >
                          Políticas de Privacidad
                        </a>
                      </template>
                      Opens in new window
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="dialog=false"
        >Cancelar</v-btn>
        <v-btn
          text
          @click="guardar_s"
        >Guardar</v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
    </v-dialog>
</template>

<script>

//import M from 'materialize-css'
//import $ from 'jquery'
import gql from "graphql-tag"
import Programa from "@/components/Programa.vue"
import Testimonios from "@/components/Testimonios.vue"
import Motivacion from "@/components/Motivacion.vue"
import Navbar from "@/components/Navbar/Navbar.vue"
import Carousel from '@/components/Carousel.vue'
import Separador from '@/components/Separador.vue'
import Tarjeta from '@/components/Tarjeta.vue'
import Posgrado from '@/components/Posgrado.vue'
import QuienesSomos from '@/components/QuienesSomos/QuienesSomos.vue'
import Contenido from '@/components/Contenido.vue'
import Contacto from '@/components/Contacto.vue'
import Diplomando from '@/components/Diplomados/Diplomados.vue'
import Postgrado from '@/views/Postgrados.vue'
import Bandera from '@/components/Bandera/Bandera.vue'
import Estadisticas from '@/components/Estadisticas/Estadisticas.vue'
import Convenios from '@/components/Convenios/Convenios.vue'
import axios from 'axios'
var url ='backend-esamlatam/crud-slider.php';

export default {
  /*apollo: {
    departamentos: gql`query{
    departamentos{
      nombre,
      id
    }
  }`,
  },*/
  components : {
    Programa, 
    Testimonios,
    Motivacion,
    Navbar,
    Carousel,
    Separador,
    Tarjeta,
    Posgrado,
    QuienesSomos,
    Contenido,
    Contacto,
    Diplomando,
    Bandera,
    Estadisticas,
    Convenios,
    Postgrado,
  },
 methods: {
  
   hrefopen(href){
    window.open(href);
   },
   mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1,tipo_slider :'P'})
      .then(Response =>{
        this.sliders=Response.data
      })
    },
   getFecha(fec){
     fec=parseInt(fec);
    this.fecha =new Date(fec).toLocaleDateString();
    //window.console.log(this.fecha);
   },
   getModalidad(moda){
    if(moda=="V"){
      this.modalidad="Virtual"
    }
    if(moda=="S"){
      this.modalidad="Semiprensencial"
    }
   },
   getPosgrado(id,fecha,imagen){
     this.finicio=fecha;
     this.setimgens='https://esam.edu.bo/webdata/portadas/'+imagen;
     var q=gql`query {
              postgrado(id:${id}
              ){
                id,nombre, objetivo, dirigido, duracion, carga_horaria, portada
              }
          }`;
      this.$apollo.query({
        query: q
      }).then((data) => {
          window.console.error("hola: ",data);
          //this.setPrograma=data.data.postgrado;
         window.console.error("ASIG: ",this.setPrograma);
      }).catch((error) => {
          window.console.error(error)
      });
    },
   enviarId(id){
      this.set_id_cat=id;
      this.setcondicion=true;
    },
   getProgramas(categoria1){
     
      
      var p=document.getElementById("posgrado");
      p.style.display="block";
      this.set_id_cat=categoria1;
      this.setcondicion=true;
      var programa1=document.getElementById("programa1");
      programa1.style.display="none";
    },
    Mprograma(){
      this.setcondicion=false;

    },
    getCategoria(idnegosio){
     var q=gql`query {
              categorias(estado:true,unidad_negocio_id: ${idnegosio}
              ){
                id,nombre
              }
          }`;
      this.$apollo.query({
        query: q,
          variables : {
            categoria : 1
          }
      }).then((data) => {
          //window.console.error("hola: ",data);
          this.categorias=data.data.categorias;
          //window.console.error("ASIG: ",this.categorias);
      }).catch((error) => {
          window.console.error(error)
      })
    },
    changeFocus: function (hash) {
     window.location.hash = hash;
  },
     getheight(){
        if (screen.width > 0 && screen.width <= 600) {
            this.carouselheight=250
        }
        if (screen.width > 600 && screen.width <= 960) {
            this.carouselheight=350
        }
        if (screen.width > 960 && screen.width <= 1264) {
            this.carouselheight=400
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            this.carouselheight=500
        }
        if (screen.width > 1904) {
            this.carouselheight=650
        }
     },
   
 },
 computed : {

    activador(){

      if ( this.pagina || this.posgrados || this.categoria ) {
        return true;
      }
      return false;
    },
    sizeBoton(){
        if (screen.width > 0 && screen.width <= 600) {
            return "x-small"
        }
        if (screen.width > 600 && screen.width <= 960) {
            return "small"
        }
        if (screen.width > 960 && screen.width <= 1264) {
            return "large"
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            return "large"
        }
        if (screen.width > 1904) {
            return "x-large"
        }
        
        return "small"
     },
 },
  data(){
    return {
      show : false,
      programas : [],
      categorias : [],
      fecha :"",
      modalidad: "",
      setPrograma : [],
      finicio :"",
      setcondicion : false,
      posgrados : false,
      set_id_cat : -1,
      id_posgrado : -1,
      imgp : "",
      condicionp : false,
      pagina_id : 0,
      pagina_con : false,
      pagina : false,
      categoria : false,
      categoria_id : 0,
      baseUrl : "https://esamlatam.com/",
      //baseUrl : "http://localhost/",
      baseImagen : "imagenes/img-slider/",
      sliders : [],
      carouselheight : 0,
      dialog : false,
    };
  },
  name: 'NavbarEsam',
  props: {
    msg1: String
  },
  mounted(){
    this.getCategoria(1);
    this.mostrar();
      }

      

}

 /*document.addEventListener('DOMContentLoaded', function() {
    var elems = document.querySelectorAll('.carousel');
    var instances = M.Carousel.init(elems, options);
  });*/

  // Or with jQuery
 
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.padding-col{
  padding: 0px;
}
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.btn-chat{
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: white; /* Color del texto */
	background-color: #1C759F; 
	position: fixed;
	bottom: 100px;
  right: 5%;
  left: 5%;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.div-fin{
  position :absolute;
  left: 0%;
  right: 0%;
  bottom: 0%;
  top: 100%;
}
  .btn-chat1{
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: white; /* Color del texto */
	background-color: #1C759F; 
	position: fixed;
	bottom: 100px;
	right: 5%;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
  .btn-flotante {
	font-size: 20px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	background-color: #5bec76; /* Color de fondo */
	/*padding: 18px 30px; /* Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 5%;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.btn-flotante:hover {
	background-color: #C2D13D; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.btn-chat1:hover {
	background-color: #F59C1B; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
.doctorado{
  background-image: url("../assets/logosEsamLatam.webp");
}
.pleft{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 5%;
    padding-bottom: 32%;
    padding-top: 0%;
}


/** fin nav */
nav{
    background-color: #001C29;
}
.nav-wrapper .input-field input[type=search] {
    /* height: inherit; */
    padding-left: 4rem;
    width: calc(100% - 4rem);
    border: 0;
    box-shadow: none;
}

.input-field input[type=search] {
    /* display: block; */
    line-height: inherit;
    transition: .3s background-color;
}
/** fin nav */
/** Banner */

.banner {
    position: relative;
}
.banner .banner-image {
    position: relative;
}
/** Fin banner */
/** inicio descuento */
.descuento {
    position: relative;
}
.descuento .descuento-image {
    position: relative;
}
/** fin descuento */

.text-0-8vw{
   font-size: 0.8vw
}


 

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}
.partners-margin{
  
  margin: 10px 0px 70px 0px;
}
.img-nosotros-ta{
  height: 100px;
  margin: 0px;
}
.titulo-nosotros-ta{
  height: 40px;
  margin: 0px;
}
.text-nosotros-ta{
  height: 140px;
  margin: 0px;
}
.img-prog-ta{
  height: 210px;
  margin: 0px;
}
.titulo-prog-ta{
  height: 90px;
  margin: 0px;
}
.text-prog-ta{
  height: 140px;
  margin: 0px;
}
.margin-70{
  margin: 70px 0 0 -20px;
}
.margin-50{
  margin: 50px 0 0 -20px;
}
.margin-30{
  margin: 30px 0 0 -20px;
}
.margin-20{
  margin: 20px 0 0 -20px;
}
.margin-0{
  margin: 0px;
}
.div-margin{
  margin: 40px;
}
.div-ta{
  height: 300px;
  margin: 10px;
}
.div-ta0{
  height: 10px;
  margin: -12px;
}

.div-ta1{
  height: 10px;
  margin: 0px;
}
.div-ta2{
  height: 40px;
}
.div-ta3{
  height: 50px;
}
.div-ta4{
  height: 400px;
  margin: 0px;
}
.div-ta5{
  height: 700px;
  margin: 0px;
}
.div-ta6{
  height: 500px;
  margin: 0px;
}

.div-ta7{
  height: 700px;
  margin: 0px;
}

::placeholder { 
  color: #001C29; 
  padding: 15px;  
  }

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}

.background-white{
  background-color: white;
}
.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.tam-logo{
  height: 45px;
  margin: 10px 0 0 20px;
}
.btn-floatings {
    display: inline-block;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    border-radius: 50%;
    transition: background-color .3s;
    cursor: pointer;
}
.padding-b{
padding-bottom : 0%
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  .text-llamadas-ta{
    font-size: 5vw;
  }
  
  .text-numero-ta{
    font-size: 2.2vw;
  }
  .btn-chat{
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: white; /* Color del texto */
	background-color: #1C759F; 
	position: fixed;
	bottom: 100px;
  right: 5%;
  left: 5%;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
  .btn-chat1{
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: white; /* Color del texto */
	background-color: #1C759F; 
	position: fixed;
	bottom: 100px;
	right: 5%;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
  .btn-flotante {
	font-size: 20px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	background-color: #5bec76; /* Color de fondo */
	/*padding: 18px 30px; /* Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 5%;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
  .margin-nabar{
  margin: 75px 0px 0px 0px ;
 }
.margin-contenido{
  margin: 0px  0px 20px 0px;
}
.padding-novedad{
    padding-left: 8%;
    padding-right: 8%;
}
.padding-descuento{
    padding-left: 48%;
    padding-right: 13%;
    padding-bottom: 0%;
    padding-top: 10%;
}
.padding-descuento-bn{
    padding-left: 39%;
    padding-right: 2%;
    padding-bottom: 0%;
    padding-top: 28%;
}
.img-doctorado{
    content:url("../assets/maestrias/1.webp");
}

.img-maestria{
    content:url("../assets/maestrias/2.webp");
}

.img-especialidad{
    content:url("../assets/maestrias/3.webp");
}

.img-diplomado{
    content:url("../assets/maestrias/4.webp");
}

.img-curso{
    content:url("../assets/maestrias/1.webp");
}

  .p-table{
    display: none
  }
  .p-cel{
    display: block
  }
  .su-table{
    display: none;
  }
  .su-cel{
    display: block;
    padding : 25px 0 0 0;
  }
  .gmail-table{
    display: none
  }
  .gmail-cel{
    display: block
  }
  .sede-table{
    display: none
  }
  .sede-cel{
    display: block
  }
  .bi-table{
    display: none
  }
  .bi-cel{
    display: block
  }
  .t-table{
    display: none
  }
  .t-compu{
    display: none
  }
  .t-cel{
    display: block
  }
  .e-table{
    display: none
  }
  .e-compu{
    display: none
  }
  .e-cel{
    display: block
  }
   .p-carousel{
     height: 129px;
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .p-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.p-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
  .e-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .e-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.e-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
  .b-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 0%;
    padding-bottom: 32%;
    padding-top: 23%;
  }
  .b-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 75%;
    padding-bottom: 32%;
    padding-top: 23%;
  }
  .bi-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 0%;
    padding-bottom: 32%;
    padding-top: 50%;
  }
  .bi-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 68%;
    padding-bottom: 32%;
    padding-top: 50%;
  }
  .t-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .t-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.t-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
  .bi-carousel{
    height: 484px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .bi-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.bi-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
}
  .align-n{
    text-align: center;
  }
   /** inicio descuento */
  .text-descuento-30p{
    font-size: 12vw
  }
  .text-descuento-title{
    font-size: 5vw
  }
  .text-descuento-title1{
    font-size: 3vw
  }
  /*.descuento .descuento-title{
      font-size: 1vw;
  }*/
  .descuento .descuento-image .descuento-title {
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      /*padding-top: 80%;*/
      padding-right: 10%;
      padding-left: 40%;
      padding-bottom: 10%;
  }
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 65px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 22vw
  }
  .text-concluido-titulo{
    font-size: 5vw
  }
  .margin-curso{
    margin : -25px 0px 0px 0px;
  }
/** fin de cuso concluido */
/** inicio de estudiar con nosotros */
  .text-estudiar-tan{
    font-size: 5.5vw
  }
/** fin de estuar con nosotros  */
/** inicio de asociados */
  .text-asociados-tan{
    font-size: 5vw
  }
/** fin de asociados  */
/** inicio de logros */
  .text-logros-tan{
    font-size: 5vw
  }
/** fin de logros  */
/** Banner */
/*.banner-titulo{
     font-size: 10vw;
}*/
.title-futuro{
  font-size: 5vw;
}
.text-descrip{
  display: none
}
.title-inge{
  font-size: 4vw;
}
.text-tan{
  font-size: 2vw;
}
.margin-ing{
  margin: 8px;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 6px;
    display: inline-block;
    height: 13px;
    line-height: 16px;
    /* padding: 0px 20px; */
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 10%;
    padding-right: 0%;
}
.padding-banner1{
    padding-left: 32%;
    padding-bottom: 55%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 50%;
}
/** fin Banner */
/** maestrias */
  /*.table{
    display: none
  }*/
  .cel{
    display: block
  }
  /** fin maestrias */
  /** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 6vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 0%;
    padding-top: 0%;
    padding-right: 0%;
    padding-bottom: 2%;
}
.padding-doctorado{
    padding-left: 20%;
}
.padding-maestria{
    padding-left: 25%;
}
.padding-especialidades{
    padding-left: 11%;
}
.padding-diplomado{
    padding-left: 20%;
}
.padding-cursos{
    padding-left: 30%;
}
/** fin Doctorados */
/* Incio de nemu*/
.nav .brand-logo {
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;
}

/* Fin de nemu*/
.btn1 {
    text-decoration: none;
    color: #fff;
    background-color: #26a69a;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
}

.tan-bandera{
  
    width : 30px;
}
.margin-ban{
    margin: 10px 0 10px 0px;
  }
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
  .text-llamadas-ta{
    font-size: 3vw;
  }
  
  .text-numero-ta{
    font-size: 2.2vw;
  }
  .margin-nabar{
  margin: 90px 0px 0px 0px ;
 }
  .margin-contenido{
  margin: 0px  0px 50px 0px;
}
  .padding-novedad{
    padding-left: 23%;
    padding-right: 23%;
}
    .padding-descuento{
    padding-left: 48%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%;
}
.padding-descuento-bn{
    padding-left: 44%;
    padding-right: 2%;
    padding-bottom: 0%;
    padding-top: 29%;
}
  .p-table{
    display: block
  }
  .p-cel{
    display: none;
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display : none;
    
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: block
  }
  .t-compu{
    display: none
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: block
  }
  .e-compu{
    display: none
  }
  .e-cel{
    display: none
  }
  .tiltle-content-tan{
    font-size: 2vw;
  }
  .padding-content{
    /*padding: 10px 20px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;*/
    padding-left: 0px;
  }
  .collection.with-header .collection-item {
    padding-left: 0px;
  }
   /** inicio descuento */
  .text-descuento-30p{
    font-size: 12vw
  }
  .text-descuento-title{
    font-size: 5vw
  }
  .text-descuento-title1{
    font-size: 3vw
  }

  /*.descuento .descuento-title{
      font-size: 1vw;
  }*/
  .descuento .descuento-image .descuento-title {
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding-top: 0%;
      padding-right: 10%;
      padding-left: 47%;
      padding-bottom: 15%;
  }
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 25px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 80px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 70px;
    margin: 0px;
  }
  /** fin Bilotecas masterclass */
  /** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 22vw
  }
  .text-concluido-titulo{
    font-size: 5.1vw
  }
  .margin-curso{
    margin : -55px 0px 0px 0px;
  }
  .text-concluido-conte{
    font-size: 1.6vw
  }
/** fin de cuso concluido */
  
/** inicio de estudiar con nosotros */
  .text-estudiar-tan{
    font-size: 3.3vw
  }
/** fin de estuar con nosotros  */
/** inicio de asociados */
  .text-asociados-tan{
    font-size: 3vw
  }
/** fin de asociados  */
/** inicio de logros */
  .text-logros-tan{
    font-size: 2.5vw
  }
/** fin de logros  */
/** Banner */

/*.banner .banner-title{
     font-size: 1vw;
}*/

   .p-carousel{
     height: 294px;
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .p-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.p-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
.banner-tittulo{
     font-size: 6vw;
}

.title-inge{
  font-size: 3vw;
}
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 10%;
}
.padding-banner1{
    padding-left: 25%;
    padding-bottom: 8%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 8%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
 .b-t-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 4%;
    padding-bottom: 32%;
    padding-top: 28%;
  }
  .b-t-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 92%;
    padding-bottom: 32%;
    padding-top: 28%;
  }
  .b-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 6%;
    padding-bottom: 32%;
    padding-top: 30%;
  }
  .b-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 92%;
    padding-bottom: 32%;
    padding-top: 30%;
  }
  .t-t-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .t-t-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.t-t-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}

  .e-t-carousel{
    height: 400px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .e-t-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.e-t-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
}

.tan-bandera{
  
   width : 30px;
}
.margin-ban{
    margin: 10px 0 10px 0px;
  }
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
  .text-numero-ta{
    font-size: 1.8vw;
  }
  .text-llamadas-ta{
    font-size: 2vw;
  }
   .margin-nabar{
  margin: 100px 0px 0px 0px ;
 }
   .margin-contenido{
  margin: 0px  0px 50px 0px;
}
  .text-estudiar-tan{
    font-size: 3vw
  }
  .text-asociados-tan{
    font-size: 3vw
  }
  .text-logros-tan{
    font-size: 2.5vw
  }
  .padding-novedad{
    padding-left: 5%;
    padding-right: 20%;
}
  .text-descuento-title{
    font-size: 5vw
  }
  .text-descuento-title1{
    font-size: 3vw
  }
.padding-descuento{
    padding-left: 45%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 15%;
}
.padding-descuento-bn{
    padding-left: 47%;
    padding-right: 2%;
    padding-bottom: 0%;
    padding-top: 31%;
}
  .p-table{
    display: block
  }
  .p-cel{
    display: none
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display: none
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: none
  }
  .t-compu{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: none
  }
  .e-compu{
    display: block
  }
  .e-cel{
    display: none
  }
  /** inicio descuento */
.text-descuento-30p{
   font-size: 12vw
}
/*.descuento .descuento-title{
     font-size: 1vw;
}*/
.descuento .descuento-image .descuento-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 10%;
    padding-left: 52%;
    padding-bottom: 20%;
}
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 60px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
   .p-carousel{
     height: 440px;
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .p-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.p-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 13vw
  }
  
  .text-concluido-titulo1{
    font-size: 4.5vw
  }  
  .text-concluido-titulo{
    font-size: 3vw
  }
  .margin-curso{
    margin : -55px 0px 0px 0px;
  }
  .text-concluido-conte{
    font-size: 1.5vw
  }
/** fin de cuso concluido */
/** Banner */

/*.banner .banner-title{
     font-size: 1vw;
}*/
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 35%;
}
.padding-banner1{
    padding-left: 35%;
    padding-bottom: 5%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 6%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
.b-t-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 6%;
    padding-bottom: 32%;
    padding-top: 28%;
  }
  .b-t-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 92%;
    padding-bottom: 32%;
    padding-top: 28%;
  }
  
  .b-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 6%;
    padding-bottom: 32%;
    padding-top: 30%;
  }
  .b-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 92%;
    padding-bottom: 32%;
    padding-top: 30%;
  }
  
  .t-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .t-c-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.t-c-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
.e-c-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .e-c-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.e-c-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}

.tan-bandera{
  
  width : 30px;
}
.margin-ban{
    margin: 10px 0 10px 0px;
  }
 }

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) {  
  .text-llamadas-ta{
    font-size: 2vw;
  }
  
  .text-numero-ta{
    font-size: 1.8vw;
  }
   .margin-nabar{
  margin: 100px 0px 0px 0px ;
 }
  .margin-contenido{
  margin: 0px  0px 50px 0px;
}
  .text-estudiar-tan{
    font-size: 2.5vw
  }
  .text-asociados-tan{
    font-size: 2.5vw
  }
  .text-logros-tan{
    font-size: 2.5vw
  }
  .padding-novedad{
    padding-left: 5%;
    padding-right: 20%;
}
  .text-descuento-title{
    font-size: 5vw
  }
  .text-descuento-title1{
    font-size: 3vw
  }
.padding-descuento{
    padding-left: 45%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 15%;
}
.padding-descuento-bn{
   padding-left: 50%;
    padding-right: 2%;
    padding-bottom: 0%;
    padding-top: 31%;
}
  .p-table{
    display: block
  }
  .p-cel{
    display: none
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display: none
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: none
  }
  .t-compu{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: none
  }
  .e-compu{
    display: block
  }
  .e-cel{
    display: none
  }
   /** inicio descuento */
.text-descuento-30p{
   font-size: 12vw
}
/*.descuento .descuento-title{
     font-size: 1vw;
}*/
.descuento .descuento-image .descuento-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 10%;
    padding-left: 52%;
    padding-bottom: 20%;
}
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 60px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 13.1vw
  }
  .text-concluido-titulo1{
    font-size: 4vw
  }
  .margin-curso{
    margin : -60px 0px 0px 0px;
  }
  .text-concluido-titulo{
    font-size: 3vw
  }
  .text-concluido-conte{
    font-size: 1.5vw
  }
/** fin de cuso concluido */
/** Banner */
/*.banner .banner-title{
     font-size: 1vw;
}*/
 .p-carousel{
     height: 440px;
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .p-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.p-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 35%;
}
.padding-banner1{
    padding-left: 35%;
    padding-bottom: 5%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 6%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
.b-t-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 6%;
    padding-bottom: 32%;
    padding-top: 20%;
  }
  .b-t-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 92%;
    padding-bottom: 32%;
    padding-top: 20%;
  }
  
  .b-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 6%;
    padding-bottom: 32%;
    padding-top: 23%;
  }
  .b-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 92%;
    padding-bottom: 32%;
    padding-top: 23%;
  }
  
  .t-c-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .t-c-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.t-c-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}

.e-c-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .e-c-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.e-c-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
.tan-bandera{
  
  width : 30px;
}
.margin-ban{
    margin: 10px 0 10px 0px;
  }
 }

 @media (min-width: 1905px)  { 
  .text-llamadas-ta{
    font-size: 2vw;
  }
  
  .text-numero-ta{
    font-size: 1.5vw;
  }
   .margin-nabar{
  margin: 110px 0px 0px 0px ;
 }
    .margin-contenido{
  margin: 0px  0px 50px 0px;
}
   .text-estudiar-tan{
    font-size: 2.5vw
  }
   .text-asociados-tan{
    font-size: 2vw
  }
   .text-logros-tan{
    font-size: 2.5vw
  }
   .padding-novedad{
    padding-left: 5%;
    padding-right: 20%;
}
  .text-descuento-title{
    font-size: 5vw
  }
  .text-descuento-title1{
    font-size: 3vw
  }
  .padding-descuento{
    padding-left: 45%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 15%;
}
.padding-descuento-bn{
    
   padding-left: 52%;
    padding-right: 2%;
    padding-bottom: 0%;
    padding-top: 32%;
}   
  .p-table{
    display: block
  }
  .p-cel{
    display: none
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display: none
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: none
  }
  .t-compu{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: none
  }
  .e-compu{
    display: block
  }
  .e-cel{
    display: none
  }
   /** inicio descuento */
.text-descuento-30p{
   font-size: 12vw
}
/*.descuento .descuento-title{
     font-size: 1vw;
}*/
.descuento .descuento-image .descuento-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 10%;
    padding-left: 52%;
    padding-bottom: 20%;
}
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 60px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 13.1vw
  }
  .text-concluido-titulo1{
    font-size: 4vw
  }
  .margin-curso{
    margin : -120px 0px 0px 0px;
  }
  .text-concluido-titulo{
    font-size: 3vw
  }
  .text-concluido-conte{
    font-size: 1.5vw
  }
/** fin de cuso concluido */
/** Banner */
/*.banner .banner-title{
     font-size: 1vw;
}*/
 .p-carousel{
     height: 440px;
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .p-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.p-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 35%;
}
.padding-banner1{
    padding-left: 35%;
    padding-bottom: 5%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 6%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
.b-t-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 6%;
    padding-bottom: 32%;
    padding-top: 20%;
  }
  .b-t-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 92%;
    padding-bottom: 32%;
    padding-top: 20%;
  }
  
  .b-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 6%;
    padding-bottom: 32%;
    padding-top: 23%;
  }
  .b-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 92%;
    padding-bottom: 32%;
    padding-top: 23%;
  }
  
  .t-c-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .t-c-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.t-c-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}

.e-c-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .e-c-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.e-c-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
.tan-bandera{
  
  width : 30px;
}
.margin-ban{
    margin: 10px 0 10px 0px;
  }
 }



</style>
