<template>

     <v-card>
      <v-layout>
        <v-app-bar
          class="background-001C29 text-white"
          prominent
        >
         <v-list-item-avatar left>
          <v-avatar
            rounded="0" 
          >
             <v-img src="../assets/logosEsamLatam.webp"></v-img>
          </v-avatar>
        </v-list-item-avatar>
          <v-spacer></v-spacer>
          <v-btn variant="text" icon="mdi-home"></v-btn>
          <v-btn variant="text" v-on:click="carouselF()">Carousel</v-btn>
          <v-btn variant="text" v-on:click="informacionF()" >Informacion</v-btn>
          <v-btn variant="text" v-on:click="suscripcionF()" >Suscripcion</v-btn>
          <v-btn variant="text" v-on:click="paginaF()" >Pagina</v-btn>
          <v-btn variant="text" v-on:click="testimonioF()" >Testimonio</v-btn>
          <v-btn variant="text" v-on:click="motivacionF()" >Motivacion</v-btn>
          <v-btn variant="text" v-on:click="socioF()" >Socios</v-btn>
        </v-app-bar>


        <v-main>
        </v-main>
      </v-layout>
    </v-card>
  <div class="hello">
    <div v-if="carousel" class="margin-config">
      <CarouselConfig/>
    </div>
    <div v-if="informacion" class="margin-config">
      <Informacion/>
    </div>
    <div v-if="suscripcion" class="margin-config">
      <Suscripcion/> 
    </div>
    <div v-if="pagina" class="margin-config">
      <Pagina/>
    </div>
    <div v-if="socio" class="margin-config">
      <Socios/>
    </div>
    <div v-if="testimonio" class="margin-config">
      <TestimonioConf/>
    </div>
    <div v-if="motivacion" class="margin-config">
      <MotivacionConf/>
    </div>
  </div>

</template>

<script>
import CarouselConfig from '@/components/configuracion/CarouselConfig.vue'
import Informacion from '@/components/configuracion/Soliciita-informacion.vue'
import Suscripcion from '@/components/configuracion/Suscripciones.vue'
import Pagina from '@/components/configuracion/Paginas.vue'
import Socios from '@/components/configuracion/Socios.vue'
import TestimonioConf from '@/components/configuracion/TestimonioConfi.vue'
import MotivacionConf from '@/components/configuracion/MotivacionConfi.vue'
export default {
  name: 'EsamConfiguracion',
  components : {
    CarouselConfig,
    Informacion,
    Suscripcion,
    Pagina,
    Socios,
    TestimonioConf,
    MotivacionConf,
  },
  props: {
  },
 methods: {
    carouselF(){
      this.carousel = true;
      this.informacion = false;
      this.suscripcion = false;
      this.pagina  = false;
      this.testimonio = false;
      this.motivacion = false;
      this.socio = false;
    },
    informacionF(){
      this.carousel = false;
      this.informacion = true;
      this.suscripcion = false;
      this.pagina  = false;
      this.testimonio = false;
      this.motivacion = false;
      this.socio = false;
    },
    suscripcionF(){
      this.carousel = false;
      this.informacion = false;
      this.suscripcion = true;
      this.pagina  = false;
      this.testimonio = false;
      this.motivacion = false;
      this.socio = false;
    },
    paginaF(){
      this.carousel = false;
      this.informacion = false;
      this.suscripcion = false;
      this.pagina  = true;
      this.testimonio = false;
      this.motivacion = false;
      this.socio = false;
    },
    testimonioF(){
      this.carousel = false;
      this.informacion = false;
      this.suscripcion = false;
      this.pagina  = false;
      this.testimonio = true;
      this.motivacion = false;
      this.socio = false;
    },
    motivacionF(){
      this.carousel = false;
      this.informacion = false;
      this.suscripcion = false;
      this.pagina  = false;
      this.testimonio = false;
      this.motivacion = true;
      this.socio = false;
    },
    socioF(){
      this.carousel = false;
      this.informacion = false;
      this.suscripcion = false;
      this.pagina  = false;
      this.testimonio = false;
      this.motivacion = false;
      this.socio = true;
    },
 },
  data(){
    return {
      carousel : false,
      informacion : false,
      suscripcion : false,
      pagina  : false,
      testimonio : false,
      motivacion : false,
      socio : false,
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>



/** fin nav */



.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}


.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.margin-config{
  margin : 50px 0px 0px 0px
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
 }
@media (min-width: 1905px)  { 
 }


</style>
