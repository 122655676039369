<template>
  <div class="hello" >
    <div  > 
      <div > 
      <v-carousel
          cycle
          height="max-height"
          hide-delimiters
        >
        
        <template v-slot:prev="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-F20780">mdi-chevron-double-left</v-icon></v-btn>
          </template>
          <template v-slot:next="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-F20780">mdi-chevron-double-right</v-icon></v-btn>
          </template>  
        <v-row justify="center">
          <v-col
            cols="10"   >     
         
          <v-carousel-item  
            v-for="(j, i) in 
            (Math.ceil(motivaciones.length/columns))"
            v-bind:key="i"
           >
          
              <v-row >
                <template
               v-for="(moti, index) in motivaciones" v-bind:key="index" >
                <v-col  
                  :cols="col_tam"
                  v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
                >
                
                <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                    <v-card
                      class="mx-auto background-DADADA mb-6"
                      max-width="344"
                    >
                    
                    <v-row justify="center" class="mb-6">
                      <v-col
                        cols="6"
                      >
                        <v-img
                              :src="baseUrl+''+baseImagen+''+moti.imagen"
                              width="100%"
                              aspect-ratio="1"
                          >
                          </v-img>
                      </v-col>
                    </v-row>
                    <div class="d-flex justify-center">
                      <span class="text-001C29 text-keep-calm-font text-center size-text-titulo padding-titulo">{{moti.titulo }}</span>
                    </div>
                    <v-card  width ="100%" class=" background-DADADA">
                      <v-card-text  class="d-flex justify-center">
                        <p class="text-001C29 text-MontserratAlternates-Regular text-justify size-text-descripcion">{{moti.descripcion }}</p>
                      </v-card-text>
                    </v-card>
                      <!--<v-card-subtitle class="d-flex justify-center">
                        <p class="text-001C29 text-MontserratAlternates-Regular text-justify">{{moti.descripcion }}</p>
                      </v-card-subtitle>-->
                    </v-card>
                  </div>
                </v-col>
                </template>
              </v-row>
              
          </v-carousel-item>
          </v-col>
        </v-row>
        </v-carousel>
    </div>    
      <!--<div v-if="md1===1">
        <v-carousel
          cycle
          height="320"
          hide-delimiters
          progress="primary"
        >
        <template v-slot:prev="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-F20780">mdi-chevron-double-left</v-icon></v-btn>
          </template>
          <template v-slot:next="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-F20780">mdi-chevron-double-right</v-icon></v-btn>
          </template>  
        <v-row >
          <v-col
            cols="10"
            sm="10"
            md="10"
            lg="10"
            xl="10"
            offset="1"
            offset-sm="1"
            offset-md="1"
            offset-lg="1"
            offset-xl="1"
          >
          <v-carousel-item >
              
              <v-row >
                <v-col
                  xs="3"
                  sm="3"
                  md="3"
                  lg="3"
                  xl="3"
    
                >
                  <v-card
                    class="mx-auto background-DADADA"
                    max-width="344"
                  >
                  
                  <v-row >
                    <v-col
                      cols="6"
                      offset="3"
                    >
                      <v-img
                            src="../assets/cafe.webp"
                            width="100%"
                            aspect-ratio="1"
                        >
                        </v-img>
                    </v-col>
                  </v-row>

                    <v-card-title class="d-flex justify-center">
                      <h6 class="text-001C29 text-KeepCalm-Heavy text-center">Aprende a tu ritmo</h6>
                    </v-card-title>

                    <v-card-subtitle class="d-flex justify-center">
                      <p class="text-001C29 text-KeepCalm-Book text-justify">Disfruta aprendiendo desde casa, sin horarios y a tu ritmo. Tú decides cuándo seguir con cada unidad.</p>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                
                <v-col
                  xs="3"
                  sm="3"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <v-card
                    class="mx-auto background-DADADA"
                    max-width="344"
                  >
                  
                  <v-row >
                    <v-col
                      cols="6"
                      offset="3"
                    >
                      <v-img
                            src="../assets/personas.webp"
                            width="100%"
                            aspect-ratio="1"
                        >
                        </v-img>
                    </v-col>
                  </v-row>

                    <v-card-title class="d-flex justify-center">
                      <h6 class="text-001C29 text-KeepCalm-Heavy text-center">Aprende de los mejores profesionales</h6>
                    </v-card-title>

                    <v-card-subtitle class="d-flex justify-center">
                      <p class="text-001C29 text-KeepCalm-Book text-justify">Aprende los métodos y técnicas más útiles con los mejores profesionales del sector creativo.</p>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                
                <v-col
                  xs="3"
                  sm="3"
                  md="3"
                  lg="3"
                  xl="3"
    
                >
                  <v-card
                    class="mx-auto background-DADADA"
                    max-width="344"
                  >
                  
                  <v-row >
                    <v-col
                      cols="6"
                      offset="3"
                    >
                      <v-img
                            src="../assets/mujer.webp"
                            width="100%"
                            aspect-ratio="1"
                        >
                        </v-img>
                    </v-col>
                  </v-row>

                    <v-card-title class="d-flex justify-center">
                      <h6 class="text-001C29 text-KeepCalm-Heavy text-center">Conoce a profesores expertos</h6>
                    </v-card-title>

                    <v-card-subtitle class="d-flex justify-center">
                      <p class="text-001C29 text-KeepCalm-Book text-justify">Cada profesor te transmitirá sus conocimientos con pasión, ofreciéndote explicaciones claras y una perspectiva profesional en cada lección.</p>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                
                <v-col
                  xs="3"
                  sm="3"
                  md="3"
                  lg="3"
                  xl="3"
                >
                  <v-card
                    class="mx-auto background-DADADA"
                    max-width="344"
                  >
                  
                  <v-row >
                    <v-col
                      cols="6"
                      offset="3"
                    >
                      <v-img
                            src="../assets/libro.webp"
                            width="100%"
                            aspect-ratio="1"
                        >
                        </v-img>
                    </v-col>
                  </v-row>

                    <v-card-title class="d-flex justify-center">
                      <h6 class="text-001C29 text-KeepCalm-Heavy text-center">Consigue un certificado con cada curso</h6>
                    </v-card-title>

                    <v-card-subtitle class="d-flex justify-center">
                      <p class="text-001C29 text-KeepCalm-Book text-justify">Obtén un certificado personalizado y firmado por tu profesor con cada curso. Compártelo en tu porfolio, en las redes sociales o donde quieras.</p>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
          </v-carousel-item>
          </v-col>
        </v-row>  
        <v-row >
          <v-col
            cols="10"
            sm="10"
            md="10"
            lg="10"
            xl="10"
            offset="1"
            offset-sm="1"
            offset-md="1"
            offset-lg="1"
            offset-xl="1"
          >
          <v-carousel-item >
            <v-row >
                <v-col
                  xs="3"
                  sm="3"
                  md="3"
                  lg="3"
                  xl="3"
    
                >
                  <v-card
                    class="mx-auto background-DADADA"
                    max-width="344"
                  >
                  
                  <v-row >
                    <v-col
                      cols="6"
                      offset="3"
                    >
                      <v-img
                            src="../assets/libro.webp"
                            width="100%"
                            aspect-ratio="1"
                        >
                        </v-img>
                    </v-col>
                  </v-row>

                    <v-card-title class="d-flex justify-center">
                      <h6 class="text-001C29 text-KeepCalm-Heavy text-center">Consigue un certificado con cada curso</h6>
                    </v-card-title>

                    <v-card-subtitle class="d-flex justify-center">
                      <p class="text-001C29 text-KeepCalm-Book text-justify">Obtén un certificado personalizado y firmado por tu profesor con cada curso. Compártelo en tu porfolio, en las redes sociales o donde quieras.</p>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
            </v-row>
          </v-carousel-item>
          </v-col>
        </v-row>
        </v-carousel>
      </div>-->
      <div class="d-flex justify-center pa-2">
        
          <v-btn class="background-F59C1B text-001C29 " >
             Más detalles <v-icon>mdi-menu-right</v-icon>
          </v-btn>
      </div>
    </div>
  </div>

</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';

import axios from 'axios'
var url ='backend-esamlatam/crud-motivacion.php';

export default {
  name: 'EsamMotivacion',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.motivaciones=Response.data
      })
    },
     getheight(){
      
        if (screen.width > 0 && screen.width <= 600) {
            this.col_tam=12;
            this.height_tan=500;
            this.x1=true;
        }
        if (screen.width > 600 && screen.width <= 960) {
            this.col_tam=4;
            this.height_tan=400;
            this.x1=false;
        }
        if (screen.width > 960 && screen.width <= 1264) {
            this.col_tam=4;
            this.height_tan=300;
            this.x1=false;
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            this.col_tam=3;
            this.height_tan=300;
            this.x1=false;
        }
        if (screen.width > 1904) {
            this.col_tam=3;
            this.height_tan=300;
            this.x1=false;
        }
     },
 },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return 3;
      }
      if (screen.width > 1264 && screen.width <= 1904) {
        return 4;
      }
      if (screen.width > 1904) {
        return 4;
      }

      return 1;
    }
  },
  data(){
    return {
      x1 : true,
      //baseUrl : "http://localhost/",
      baseUrl : "https://esamlatam.com/",
      baseImagen : "imagenes/img-motivacion/",
      motivaciones : [],
      col_tam : 0,
      height_tan : 0,

    }
  },
  mounted(){
      this.getheight();
      this.mostrar();
    }

      

}
</script>

<style scoped>
.v-card--variant-contained {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%);
}
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}


@media (min-width: 0px) and (max-width: 600px) { 
  
 .video-width{
    width: 100px;
  }
  .size-text-titulo{
    font-size: 1.9vh;
  }
  .padding-titulo{
    padding: 0% 6% 0% 6%;
  }
  .size-text-descripcion{
    font-size: 2vh;
  }
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
   .height-descripcion{
    height: 105px;
  }
  .size-text-descripcion{
    font-size: 1.5vh;
  }
  .video-width{
    width: 180px;
  }
  
  .size-text-titulo{
    font-size: 1.8vh;
  }
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
.video-width{
    width: 200px;
  }
   .height-descripcion{
    height: 80px;
  }
  .size-text-descripcion{
    font-size: 1.4vh;
  }
  .size-text-titulo{
    font-size: 1.7vh;
  }
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
  .video-width{
    width: 240px;
  } 
   .height-descripcion{
    height: 85px;
  }
  .size-text-descripcion{
    font-size: 1.6vh;
  }
  .size-text-titulo{
    font-size: 1.8vh;
  }

 }
@media (min-width: 1905px)  { 
  .size-text-titulo{
    font-size: 2vh;
  }
  
   .height-descripcion{
    height: 80px;
  }
  .size-text-descripcion{
    font-size: 1.6vh;
  }
  
  .size-text-titulo{
    font-size: 1.8vh;
  }
 }

</style>
