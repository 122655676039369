<template>
<div>
    
    <v-row >
      <v-col
        cols="10"
        offset="1"
      >
        
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="dialog"
          persistent
        >
          <template v-slot:activator="{ props }">
            <v-btn
              color="success"
              v-bind="props"
            >
                CREAR <v-icon>mdi-table-plus</v-icon>
            </v-btn>
          </template>
          <template v-slot:default="{ isActive }">
            <v-card width="100%">
              <v-toolbar
                color="success"
              >Nuevo Slider</v-toolbar>
              <v-card-text>
                   <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                    >
                      <v-text-field
                        label="Nombre"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                    >
                      <v-text-field
                        label="Descipcion"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                    >
                      <v-text-field
                        label="imagen"
                        required
                      ></v-text-field>
                    </v-col>
                    
                  </v-row>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  text
                  @click="isActive.value = false"
                >Close</v-btn>
                <v-btn
                  text
                >Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
      <v-col
        cols="10"
        offset="1"
      >
        <v-table
            fixed-header
            height="300px" 
        >
            <thead >
            <tr class="light-blue darken-2" >
                <th class="text-left">
                #
                </th>
                <th class="text-left">
                POSGRADO
                </th>
                <th class="text-left">
                NOMBRE
                </th>
                <th class="text-left">
                APELLIDO
                </th>
                <th class="text-left">
                CELULAR
                </th>
                <th class="text-left">
                CORREO
                </th>
                <th class="text-left">
                ACCCIONES
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(infor, index) in informacions" v-bind:key="index"
            >
                <td>{{index+1}}</td>
                <td>{{infor.nombre_posgrado}}</td>
                <td>{{infor.nombre}}</td>
                <td>{{infor.apellido}}</td>
                <td>{{infor.celular}}</td>
                <td>{{infor.correo}}</td>
                <td class="text-white">
                <v-btn
                    icon="mdi-table-edit"
                    color="info"
                ></v-btn>
                <v-btn
                    icon="mdi-delete"
                    class="background-red"
                ></v-btn>
                </td>
            </tr>
            </tbody>
        </v-table>
        </v-col>
    </v-row>

    <v-row justify="space-around">
    <v-col cols="auto">
    </v-col>
  </v-row>
  </div>

  
</template>

<script>

import axios from 'axios'
var url ='http://localhost/backend-esamlatam/crud-informacion.php';
export default {
  name: 'EsaminformacionConfig',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
    mostrar(){
      axios.post(url,{opcion:1})
      .then(Response =>{
        this.informacions=Response.data
      })
    },
     getheight(){
        if (screen.width > 0 && screen.width <= 600) {
            this.carouselheight=250
        }
        if (screen.width > 600 && screen.width <= 960) {
            this.carouselheight=350
        }
        if (screen.width > 960 && screen.width <= 1264) {
            this.carouselheight=400
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            this.carouselheight=500
        }
        if (screen.width > 1904) {
            this.carouselheight=650
        }
     },
  
   
 },
  data(){
    return {
      carouselheight: 0,
      informacions : [],
      informacion : {
        id: null,
        id_posgrado : null,
        nombre_posgrado : "",
        nombre : "",
        apellido : "",
        celular : "",
        correo : "",
        estado : false,
        imagen : ""
      }
    };
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.background-red{
  background-color: red;
}
.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
 
/** sive todo */
.text-descrip{
  display: none
}
.title-inge{
  font-size: 6vw;
}
.title-futuro{
  font-size: 7.1vw;
}
.padding-banner1{
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */

.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 23%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 8%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 5%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
 .title-inge{
  font-size: 4vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
 }
@media (min-width: 1905px)  { 
 .title-inge{
  font-size: 3vw;
}
.title-futuro{
  font-size: 4vw;
}
.padding-banner1{
    padding-left: 35%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 3%
} 
 }



</style>
