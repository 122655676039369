<template>
  <div class="hello" >
    <div :v-model="getheight()" > 
      <div >
        <v-carousel
          cycle
          height="max-height"
          hide-delimiters
        >
        <template v-slot:prev="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-F20780">mdi-chevron-double-left</v-icon></v-btn>
          </template>
          <template v-slot:next="{ props }">
            <v-btn
              variant="outlined"
              class="text-transparent"
              icon
              @click="props.onClick"
            ><v-icon class="text-F20780">mdi-chevron-double-right</v-icon></v-btn>
          </template>   
        <v-row >
          <v-col
            cols="10"
            offset="1"
          >     
          <v-carousel-item  
            v-for="(j, i) in 
            (Math.ceil(testimonios.length/columns))"
            v-bind:key="i"
           >
          
              <v-row >
                <template
                v-for="(testi, index) in testimonios" v-bind:key="index" >
                <v-col  
                  :cols="col_tam"
                  v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns"
                >
                
                <div v-if="index < ((i+1)*columns)  && index >=((i+1)*columns)-columns">
                  <v-card
                    class="mx-auto background-DADADA mb-6"
                    max-width="344"
                  >
                  <div class="d-flex justify-center">
                    
                    <iframe 
                        :src="baseVideo+''+testi.video" 
                        class="embed-responsive-item video-width" 
                        frameborder="0"  allowfullscreen cover></iframe>
                  </div>

                    <v-card-title class="d-flex justify-center">
                      <h6 class="card-title text-001C29 text-keep-calm-font text-center">{{testi.titulo}}</h6>
                    </v-card-title>

                    <v-card-subtitle class="d-flex justify-center">
                     <p class="text-MontserratAlternates-Regular">{{testi.descripcion}}</p>
                    </v-card-subtitle>
                  </v-card>
                  </div>
                </v-col>
                </template>
              </v-row>
              
          </v-carousel-item>
          </v-col>
        </v-row>
           
        </v-carousel>
      </div>
      <div class="d-flex justify-center pa-2">
        
          <v-btn class="background-F59C1B text-001C29 " >
             Testimonios <v-icon>mdi-menu-right</v-icon>
          </v-btn>
      </div>
    </div>
  </div>

</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';
import axios from 'axios'
var url ='backend-esamlatam/crud-testimonio.php';

export default {
  name: 'EsamTestimonio',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
   
      mostrar(){
        axios.post(this.baseUrl+''+url,{opcion:1})
        .then(Response =>{
          this.testimonios=Response.data
          this.tam=Math.round((this.testimonios.length)/4)
          console.log(this.tam);
        })
      },
      sumar(){
        this.n=this.n+4;
        console.log(this.n);
      },
     getheight(){
        if (screen.width > 0 && screen.width <= 600) {
            this.col_tam=12;
            this.height_tan=300;
            this.x1=true;
        }
        if (screen.width > 600 && screen.width <= 960) {
            this.col_tam=4;
            this.height_tan=400;
            this.x1=false;
        }
        if (screen.width > 960 && screen.width <= 1264) {
            this.col_tam=3;
            this.height_tan=300;
            this.x1=false;
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            this.col_tam=3;
            this.height_tan=300;
            this.x1=false;
        }
        if (screen.width > 1904) {
            this.col_tam=3;
            this.height_tan=300;
            this.x1=false;
        }
     },
 },
  data(){
    return {
      col_tan : 0,
      x1 : true,
      height_tan : 0,
      //baseUrl : "http://localhost/",
      baseUrl : "https://esamlatam.com/",
      baseVideo : "https://www.youtube.com/embed/",
      testimonios : {},
      testimonio : [],
    }
  },
  computed: {
    columns() {
      if (screen.width > 0 && screen.width <= 600) {
        return 1;
      }

      if (screen.width > 600 && screen.width <= 960) {
        return 3;
      }

      if (screen.width > 960 && screen.width <= 1904) {
        return 4;
      }
      if (screen.width > 1904) {
        return 4;
      }
      return 1;
    }
  },
  mounted(){
      this.getheight();
      this.mostrar();
    }

      

}
</script>

<style scoped>
.v-card--variant-contained {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%);
}
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.padding-next{
  
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-prev{

    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
@media (min-width: 0px) and (max-width: 600px) { 
 .video-width{
    width: 210px;
  }
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
  .video-width{
    width: 180px;
  }
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
.video-width{
    width: 200px;
  }
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
  .video-width{
    width: 240px;
  }

 }
@media (min-width: 1905px)  { 
 
 }

</style>
