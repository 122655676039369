<template>
  <div class="home">
    <NavbarEsam />
  </div>
</template>

<script>
// @ is an alias to /src
import NavbarEsam from '@/components/NavbarEsam.vue'

export default {
  name: 'NavbarEsamView',
  components: {
    NavbarEsam
  }
}
</script> 
