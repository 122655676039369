<template>
    <v-crad-title  v-for="(cat, index) in areas" v-bind:key="index"
    class="d-flex justify-center size-text-titulo mb-6 margin-title">
        <h2 class="text-keep-calm-font"  :style="'color : rgb('+cat.color+')'"> {{cat.nombre}}</h2>
    </v-crad-title>
   <div class="mb-6">
      
        <v-row justify="center" class="mb-6">
            <v-col cols="10"
                    sm="10"
                    md="11"
                    lg="10"
                    xl="10">
                <v-row>
                  <template  v-for="(postgrado, index) in posgrados" v-bind:key="index"> 
                    <v-col cols="12"
                            sm="6"
                            md="4"
                            lg="4"
                            xl="4">
                        <v-card
                            class="mx-auto cursor"                            
                        >
                            <v-img
                            class="align-end text-white"
                            :src="baseUrl+''+baseImagen+''+postgrado.imagen"  
                            v-on:click="enviarId(postgrado.id)"                          
                            >
                                <!--<div class="bordes-negro" 
                                :style="'background: linear-gradient(to  right, rgba('+postgrado.color+',0), rgba('+postgrado.color+',1),rgba('+postgrado.color+',1), rgba('+postgrado.color+',0))'">
                                    <p class="text-white text-keep-calm-font size-text-postgrado text-center py-2">{{postgrado.nombre}}</p>
                                </div>-->
                                <div class="background-071D28">

                                </div>
                            </v-img>                        
                        </v-card>
                        <v-card>
                          <v-tabs
                            v-model="tab"
                            class="background-071D28 text-white"
                            centered
                            stacked
                          >
                            <v-tab value="tab-1">                              
                              <h3 class="text-center text-white text-size-title">INICIO</h3>
                            </v-tab>

                            <v-tab value="tab-2">                         
                              <h3 class="text-center text-white text-size-title">DURACIÓN</h3>
                            </v-tab>

                            <v-tab value="tab-3">
                              <h3 class="text-center text-white text-size-title">INVERSIÓN</h3>
                            </v-tab>
                          </v-tabs>

                          <v-window v-model="tab" >
                            <v-window-item
                              value="tab-1"
                            >
                              <v-card class="background-071D28 ">
                                <v-card-text class="text-center text-size-subtitle text-white">26 de Septiembre</v-card-text>
                              </v-card>
                            </v-window-item>
                            <v-window-item
                              value="tab-2"
                            >
                              <v-card class="background-071D28 ">
                                <v-card-text class="text-center text-size-subtitle text-white">6 días </v-card-text>
                              </v-card>
                            </v-window-item>
                            <v-window-item
                              value="tab-3"
                            >
                              <v-card class="background-071D28 ">
                                <v-card-text class="text-center text-size-subtitle text-white">600 Bs</v-card-text>
                              </v-card>
                            </v-window-item>
                          </v-window>
                        </v-card>   
                    </v-col>
                  </template>
                </v-row>
            </v-col>
        </v-row>
   </div>
</template>

<script>

import axios from 'axios'
var url ='backend-esamlatam/crud-posgrado.php';
var url_area ='backend-esamlatam/crud-area.php';

export default {
  name: 'EsamPostgrado',
  props: {
    categoria_id: Number,
  },
  components : {
  },
 methods: { 
   href(href){
    window.open(href);
   },
    
    mostrar_area(id){
      axios.post(this.baseUrl+''+url_area,{opcion:6,id:id})
      .then(Response =>{
        this.areas=Response.data
      })
    },   
    mostrar_posgrado(id){
      axios.post(this.baseUrl+''+url,{opcion:1,areas_id:id})
      .then(Response =>{
        this.posgrados=Response.data
      })
    },  
   enviarId(id){
     this.$emit("categoria_id",this.categoria_id),
     this.$emit("postgrado_id",id),
     this.$emit("PostgradoView",true)
    },
 },
  computed: {
    ifcol(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return true;
      }

      return true;
    },
      
  },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://esamlatam.com/",
       baseImagen : "imagenes/img-postgrado/",
       areas : {},
       posgrados : {},
      tab: 'option-1',
    };
  },
  mounted(){
    this.mostrar_area(this.categoria_id);
    this.mostrar_posgrado(this.categoria_id);
    }     

}
</script>

<style scoped>
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}
.margin-title{
  margin: 50px 10px 10px 10px;
}

.text-001C29{
  color: #001C29;
}
.text-94ccd8{
  color: #94ccd8;
}
.text-df9d34{
  color: #df9d34;
}
.text-cb1c7d{
  color: #cb1c7d;
}
.text-c6d04c{
  color: #c6d04c;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-f8e865{
  color : #f8e865;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.text-1c75bc{
  color: #1c75bc;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}
.background-071D28{
  background-color: #071D28;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.text-0-8vw{
   font-size: 0.8vw
}
.text-size-icon{
   font-size: 6vw
}
.text-areas{
  color: #0076c0;
}
.avtar-text{ 
    -webkit-margin-end: 2px; 
    margin-inline-end: 2px;
}
.size-text-titulo{
    font-size: 5vh;
}
.width-buscador{
    width: 39%;
}
.cursor{
 cursor: pointer;
    color: inherit;
}
.cursor:hover {
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
} 


.bordes-negro{
letter-spacing: 0;
text-shadow: -1px -1px 0.2px #333, 0.5px -1px 0.2px #333;
}
.background-degradado{

background: linear-gradient(to  right, rgba(15,117,188,0), rgba(15,117,188,1),rgba(15,117,188,1), rgba(15,117,188,0))
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
/** fin Banner */
.p-text{
    padding: 0% 0% 30% 0%;
}
.size-text-titulo{
    font-size: 1.8vh;
}
.size-text-area{
  font-size: 3.5vh;
}
.width-buscador{
    width: 80%;
}
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
    .size-text-titulo{
    font-size: 2.4vh;
}

.width-buscador{
    width: 58%;
}
.p-text{
    padding: 0% 0% 26% 0%;
}
.size-text-area{
  font-size: 3.5vh;
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 

.size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 57%;
}

.p-text{
    padding: 0% 0% 26% 0%;
}
.size-text-area{
  font-size: 3.8vh;
}
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
    .size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 42%;
}
.p-text{
    padding: 0% 0% 20% 0%;
}
.size-text-area{
  font-size: 3.8vh;
}
 }
@media (min-width: 1905px)  { 
  .size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 42%;
}
.p-text{
    padding: 0% 0% 20% 0%;
}
.size-text-area{
  font-size: 3vh;
}
 }



</style>
