import { InMemoryCache, ApolloClient , createHttpLink  } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";

const cache =new InMemoryCache();
const httplink =createHttpLink({
    uri: "https://webapi.cybercorp.com.bo/graphql",
});
const client = new ApolloClient ({
    link: httplink,
    cache,
});

export const prvider =createApolloProvider({
    defaultClient : client,
})