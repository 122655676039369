<template >
<div :v-model="getPosgrado(id_posgrado,condicionp)">
<div class="margen" v-if="condicionp" >
  {{posgrado}}
  <div v-if="conprogra">
  <v-row>
        <v-col
        cols="10"
        sm="10"
        md="10"
        lg="10"
        xl="10"
        offset="1"
        offset-sm="1"
        offset-md="1"
        offset-lg="1"
        offset-xl="1"
        
        >
            <v-row>
                <v-col
                cols="12"
                sm="7"
                md="7"
                lg="7"
                xl="7"
                >
                    <h5 class="center-align text-001C29 text-KeepCalm-Medium">{{posgrado.nombre}}</h5>
                    <h6 class="text-001C29 text-KeepCalm-Heavy">OBJETIVO</h6>
                    <span class="text-001C29 text-KeepCalm-Book">{{posgrado.objetivo}}</span>
                    <h6 class="text-001C29 text-KeepCalm-Heavy">Duración: {{posgrado.duracion}} meses</h6>
                    <h6 class="text-001C29 text-KeepCalm-Heavy">{{posgrado.carga_horaria}} horas academicas</h6>
                    <h6 class="text-001C29 text-KeepCalm-Heavy">Inicio: {{fecha}}</h6>
                    <h6 class="text-001C29 text-KeepCalm-Heavy">DIRIGIDO A</h6>
                    <span class="text-001C29 text-KeepCalm-Book">{{posgrado.dirigido}}</span>
                </v-col>
                <v-col
                cols="12"
                sm="5"
                md="5"
                lg="5"
                xl="5"
                >
                    <v-img
                        :src="'https://esam.edu.bo/webdata/portadas/'+imgp"
                        aspect-ratio="1"
                        width="100%"
                    >
                    </v-img>
                </v-col>
            </v-row>

        </v-col>
    </v-row>
    
  </div>
</div>
</div>
</template>

<script>

import gql from "graphql-tag"

export default {
  name: 'EsamPosgrado',
  props: {
    id_posgrado: Number,
    condicionp : Boolean,
    imgp : String,
  },
  components : {
  },
 methods: {
     
   getPosgrado(id , c){
    
     ///this.imagen='https://esam.edu.bo/webdata/portadas/'+imagen;
    //console.log("aqqqqqqqqqqqqqqqqqqqqqqqq"+c+id+this.conprogra)
     var q=gql`query {
              postgrado(id:${id}
              ){
                id,nombre, objetivo, dirigido, duracion, carga_horaria, portada
              }
          }`;
      this.$apollo.query({
        query: q
      }).then((data) => {
          //window.console.error("hola: ",data);
          this.posgrado=data.data.postgrado;
         /*window.console.error("ASIG: ",this.posgrado);
         window.console.error("ASIG: ",this.conprogra);*/
      }).catch((error) => {
          window.console.error(error)
      });
      
     if(!c){
       this.conprogra=false;
     }
     if(c){
       
      this.conprogra=true;
     }
   
     
    },
   getFecha(fec,moda){
      this.con++;
    //window.console.log("get posgrado  = "+this.i++ +" fechas =  sssss" +this.con);
     
     fec=parseInt(fec);
    this.fecha =new Date(fec).toLocaleDateString();
    if(moda=="V"){
      this.modalidad="Virtual"
    }
    if(moda=="S"){
      this.modalidad="Semiprensencial"
    }
    return ;
    //window.console.log(this.fecha);
   },
   getModalidad(moda){
     
console.log("nontador "+ this.con);
    if(moda=="V"){
      this.modalidad="Virtual"
    }
    if(moda=="S"){
      this.modalidad="Semiprensencial"
    }
   },
   getcondi (c){

     if(!c){
       this.conposgrado=false;
       this.con=0;
      //break;
       //break;
     }
   },
   getimagen(img){
     this.imagen='https://esam.edu.bo/webdata/portadas/'+img;
   },
  getCategoria(idnegosio){
    var q=gql`query {
            categorias(estado:true,unidad_negocio_id: ${idnegosio}
            ){
              id,nombre
            }
        }`;
    this.$apollo.query({
      query: q,
        variables : {
          categoria : 1
        }
    }).then((data) => {
        //window.console.error("hola: ",data);
        this.categorias=data.data.categorias;
        //window.console.error("ASIG: ",this.categorias);
    }).catch((error) => {
        window.console.error(error)
    })
  },
   enviarId(id){
     this.id_cat=id,
     this.$emit("id_categoria",id),
     this.$emit("activar",true)
    },
   getProgramas(categoria1,c){
     
     var q=gql`query {
              programas(por_fecha:false,latest:  false,iduniversidad:128,
              categoria: ${categoria1},gestion:2022,unidad_negocio:1
              ){
                id,nombre_compuesto, version,grupo,fecha_inicio,modalidad,portada,
    						postgrado{nombre,id},sede{nombre},
    						universidad{nombre}
              }
          }`;
      this.$apollo.query({
        query: q,
          variables : {
            categoria : 1
          }
      }).then((data) => {
          //this.programas=data.data.programas;
          //this.setPrograma=data.data.programas;
          this.programas=data.data.programas;
          
         window.console.error("ASIG: ",this.programas);
      }).catch((error) => {
          window.console.error(error)
      });
     if(!c){
       this.conposgrado=false;
       this.con=0;
       this.conprogra=false;
     }
     if(c){
       
      this.conprogra=true;
     }
     
    },
   
 },
  data(){
    return {
      programas : [],
      categorias : [],
      posgrado : {"id": 0, "nombre": "1", "objetivo": "1", "dirigido": "", "duracion": 0, "carga_horaria": 0, "portada": "" },
      fecha :"",
      modalidad: "",
      imagen :"",
      conposgrado : false,
      i: 0,
      con :0 ,
      conprogra : false,
      id_cat : "",
      activar :false,
      img : "",
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
.doctorado{
  background-image: url("../assets/logosEsamLatam.webp");
}

/** fin nav */
nav{
    background-color: #001C29;
}
.nav-wrapper .input-field input[type=search] {
    /* height: inherit; */
    padding-left: 4rem;
    width: calc(100% - 4rem);
    border: 0;
    box-shadow: none;
}

.input-field input[type=search] {
    /* display: block; */
    line-height: inherit;
    transition: .3s background-color;
}
/** fin nav */
/** Banner */

.banner {
    position: relative;
}
.banner .banner-image {
    position: relative;
}
/** Fin banner */
/** inicio descuento */
.descuento {
    position: relative;
}
.descuento .descuento-image {
    position: relative;
}
/** fin descuento */

.text-0-8vw{
   font-size: 0.8vw
}

.b-left {
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 5%;
    padding-bottom: 32%;
    padding-top: 0%;
}

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}
.img-nosotros-ta{
  height: 100px;
  margin: 0px;
}
.titulo-nosotros-ta{
  height: 40px;
  margin: 0px;
}
.text-nosotros-ta{
  height: 140px;
  margin: 0px;
}
.img-prog-ta{
  height: 130px;
  margin: 0px;
}
.titulo-prog-ta{
  height: 90px;
  margin: 0px;
}
.div-prog-ta{
  height: 350px;
}
.text-prog-ta{
  height: 150px;
  margin: 0px;
}
.margin-70{
  margin: 70px 0 0 -20px;
}
.margin-50{
  margin: 50px 0 0 -20px;
}
.margin-30{
  margin: 30px 0 0 -20px;
}
.margin-20{
  margin: 20px 0 0 -20px;
}
.margin-0{
  margin: 0px;
}
.div-margin{
  margin: 40px;
}
.div-ta{
  height: 300px;
  margin: 10px;
}
.div-ta0{
  height: 5px;
  margin: 0px;
}
.div-ta1{
  height: 10px;
  margin: 0px;
}
.div-ta2{
  height: 40px;
}
.div-ta3{
  height: 50px;
}
.div-ta4{
  height: 400px;
  margin: 0px;
}
.div-ta5{
  height: 700px;
  margin: 0px;
}
.div-ta6{
  height: 500px;
  margin: 0px;
}

.div-ta7{
  height: 700px;
  margin: 0px;
}

::placeholder { 
  color: #001C29; 
  padding: 15px;  
  }


.text-001C29{
  color: #001C29;
}
.margen{
  margin: 70px 0px 0px 0px;

}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.tam-logo{
  height: 45px;
  margin: 10px 0 0 20px;
}

  .text-ta{
    font-size: 0.9vw;
  }
/** Mobile Devices  **/
@media (min-width: 350px) and (max-width: 601px) { 
  
  .p-table{
    display: none
  }
  .p-cel{
    display: block
  }
  .su-table{
    display: none;
  }
  .su-cel{
    display: block;
    padding : 25px 0 0 0;
  }
  .gmail-table{
    display: none
  }
  .gmail-cel{
    display: block
  }
  .sede-table{
    display: none
  }
  .sede-cel{
    display: block
  }
  .bi-table{
    display: none
  }
  .bi-cel{
    display: block
  }
  .t-table{
    display: none
  }
  .t-cel{
    display: block
  }
  .e-table{
    display: none
  }
  .e-cel{
    display: block
  }
  .e-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .e-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.e-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
  .b-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 2%;
    padding-bottom: 32%;
    padding-top: 33%;
  }
  .b-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 70%;
    padding-bottom: 32%;
    padding-top: 33%;
  }
  .t-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .t-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.t-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
  .bi-carousel{
    height: 484px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .bi-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.bi-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
}
  .align-n{
    text-align: center;
  }
   /** inicio descuento */
  .text-descuento-30p{
    font-size: 12vw
  }
  .text-descuento-title{
    font-size: 5vw
  }
  .text-descuento-title1{
    font-size: 3vw
  }
  /*.descuento .descuento-title{
      font-size: 1vw;
  }*/
  .descuento .descuento-image .descuento-title {
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      /*padding-top: 80%;*/
      padding-right: 10%;
      padding-left: 40%;
      padding-bottom: 10%;
  }
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 65px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 18vw
  }
  .text-concluido-titulo{
    font-size: 4vw
  }
/** fin de cuso concluido */
/** inicio de estudiar con nosotros */
  .text-estudiar-tan{
    font-size: 6vw
  }
/** fin de estuar con nosotros  */
/** inicio de asociados */
  .text-asociados-tan{
    font-size: 8vw
  }
/** fin de asociados  */
/** inicio de logros */
  .text-logros-tan{
    font-size: 7vw
  }
/** fin de logros  */
/** Banner */
/*.banner-titulo{
     font-size: 10vw;
}*/
.title-futuro{
  font-size: 5vw;
}
.text-descrip{
  display: none
}
.title-inge{
  font-size: 4vw;
}
.text-tan{
  font-size: 2vw;
}
.margin-ing{
  margin: 8px;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 6px;
    display: inline-block;
    height: 13px;
    line-height: 16px;
    /* padding: 0px 20px; */
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 10%;
    padding-right: 0%;
}
.padding-banner1{
    padding-left: 32%;
    padding-bottom: 80%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 77%;
}
/** fin Banner */
/** maestrias */
  .table{
    display: none
  }
  .cel{
    display: block
  }
  /** fin maestrias */
  /** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 6vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 2%;
}
.padding-doctorado{
    padding-left: 20%;
}
.padding-maestria{
    padding-left: 25%;
}
.padding-especialidades{
    padding-left: 11%;
}
.padding-diplomado{
    padding-left: 20%;
}
.padding-cursos{
    padding-left: 30%;
}
/** fin Doctorados */
/* Incio de nemu*/
.nav .brand-logo {
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;
}

/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 991px) { 
    
  .p-table{
    display: block
  }
  .p-cel{
    display: none;
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display : none;
    
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: block
  }
  .e-cel{
    display: none
  }
  .tiltle-content-tan{
    font-size: 3vw;
  }
  .padding-content{
    /*padding: 10px 20px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;*/
    padding-left: 0px;
  }
  .collection.with-header .collection-item {
    padding-left: 0px;
  }
   /** inicio descuento */
  .text-descuento-30p{
    font-size: 12vw
  }
  .text-descuento-title{
    font-size: 5vw
  }
  .text-descuento-title1{
    font-size: 3vw
  }

  /*.descuento .descuento-title{
      font-size: 1vw;
  }*/
  .descuento .descuento-image .descuento-title {
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding-top: 80%;
      padding-right: 10%;
      padding-left: 47%;
      padding-bottom: 15%;
  }
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 25px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 80px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 70px;
    margin: 0px;
  }
  /** fin Bilotecas masterclass */
  /** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 18vw
  }
  .text-concluido-titulo{
    font-size: 5vw
  }
  .text-concluido-titulo1{
    font-size: 5vw
  }
  .text-concluido-conte{
    font-size: 1.6vw
  }
/** fin de cuso concluido */
  
/** inicio de estudiar con nosotros */
  .text-estudiar-tan{
    font-size: 3.3vw
  }
/** fin de estuar con nosotros  */
/** inicio de asociados */
  .text-asociados-tan{
    font-size: 3vw
  }
/** fin de asociados  */
/** inicio de logros */
  .text-logros-tan{
    font-size: 3vw
  }
/** fin de logros  */
/** Banner */

/*.banner .banner-title{
     font-size: 1vw;
}*/
.banner-tittulo{
     font-size: 6vw;
}

.title-inge{
  font-size: 3vw;
}
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 10%;
}
.padding-banner1{
    padding-left: 25%;
    padding-bottom: 20%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 20%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
 }

/** Desktop Devices */
@media (min-width: 992px) and (max-width: 1199px) { 
     
  .p-table{
    display: block
  }
  .p-cel{
    display: none
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display: none
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: block
  }
  .e-cel{
    display: none
  }
  /** inicio descuento */
.text-descuento-30p{
   font-size: 12vw
}
/*.descuento .descuento-title{
     font-size: 1vw;
}*/
.descuento .descuento-image .descuento-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 80%;
    padding-right: 10%;
    padding-left: 52%;
    padding-bottom: 20%;
}
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 60px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 12vw
  }
/** fin de cuso concluido */
/** Banner */

/*.banner .banner-title{
     font-size: 1vw;
}*/
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 35%;
}
.padding-banner1{
    padding-left: 35%;
    padding-bottom: 5%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 6%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
 }

/** Large Desktop Devices */
@media (min-width: 1200px) and (max-width: 1400px) { 
     
  .p-table{
    display: block
  }
  .p-cel{
    display: none
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display: none
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: block
  }
  .e-cel{
    display: none
  }
   /** inicio descuento */
.text-descuento-30p{
   font-size: 12vw
}
/*.descuento .descuento-title{
     font-size: 1vw;
}*/
.descuento .descuento-image .descuento-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 80%;
    padding-right: 10%;
    padding-left: 52%;
    padding-bottom: 20%;
}
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 60px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 12vw
  }
/** fin de cuso concluido */
/** Banner */
/*.banner .banner-title{
     font-size: 1vw;
}*/
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 35%;
}
.padding-banner1{
    padding-left: 35%;
    padding-bottom: 5%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 6%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
 }



</style>
