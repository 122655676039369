<template>
    <div v-if="!PostgradoView">
        <Postgrados :categoria_id="categoria_id"  @postgrado_id="postgrado_id=$event"  @PostgradoView="PostgradoView=$event"/>
   </div>
   <div v-if="PostgradoView">
        <PostgradosView :categoria_id="categoria_id" :postgrado_id="postgrado_id" />
   </div>
</template>

<script>

import PostgradosView from '@/components/Postgrados/PostgradosView.vue'
import Postgrados from '@/components/Postgrados/Postgrados.vue'
import axios from 'axios'
var url ='backend-esamlatam/crud-posgrado.php';
var url_area ='backend-esamlatam/crud-area.php';

export default {
  name: 'EsamPostgrado',
  props: {
    categoria_id: Number,
  },
  components : {
    Postgrados,
    PostgradosView,
  },
 methods: { 
   href(href){
    window.open(href);
   },
    
    mostrar_area(){
      axios.post(this.baseUrl+''+url_area,{opcion:6,id:6})
      .then(Response =>{
        this.areas=Response.data
      })
    },   
    mostrar_posgrado(){
      axios.post(this.baseUrl+''+url,{opcion:1,areas_id:6})
      .then(Response =>{
        this.posgrados=Response.data
      })
    }, 
 },
  computed: {
    ifcol(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return true;
      }

      return true;
    },
      
  },
  data(){
    return {
      baseUrl : "http://localhost/",
      //baseUrl : "https://esamlatam.com/",
       baseImagen : "imagenes/img-postgrado/",
       areas : {},
       posgrados : {},
      tab: 'option-1',
      PostgradoView : false,
    };
  },
  mounted(){
    }     

}
</script>

<style scoped>
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}
.margin-title{
  margin: 50px 10px 10px 10px;
}

.text-001C29{
  color: #001C29;
}
.text-94ccd8{
  color: #94ccd8;
}
.text-df9d34{
  color: #df9d34;
}
.text-cb1c7d{
  color: #cb1c7d;
}
.text-c6d04c{
  color: #c6d04c;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-f8e865{
  color : #f8e865;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.text-1c75bc{
  color: #1c75bc;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}
.background-071D28{
  background-color: #071D28;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.text-0-8vw{
   font-size: 0.8vw
}
.text-size-icon{
   font-size: 6vw
}
.text-areas{
  color: #0076c0;
}
.avtar-text{ 
    -webkit-margin-end: 2px; 
    margin-inline-end: 2px;
}
.size-text-titulo{
    font-size: 5vh;
}
.width-buscador{
    width: 39%;
}
.cursor{
 cursor: pointer;
    color: inherit;
}
.cursor:hover {
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
} 


.bordes-negro{
letter-spacing: 0;
text-shadow: -1px -1px 0.2px #333, 0.5px -1px 0.2px #333;
}
.background-degradado{

background: linear-gradient(to  right, rgba(15,117,188,0), rgba(15,117,188,1),rgba(15,117,188,1), rgba(15,117,188,0))
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
/** fin Banner */
.p-text{
    padding: 0% 0% 30% 0%;
}
.size-text-titulo{
    font-size: 1.8vh;
}
.size-text-area{
  font-size: 3.5vh;
}
.width-buscador{
    width: 80%;
}
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
    .size-text-titulo{
    font-size: 2.4vh;
}

.width-buscador{
    width: 58%;
}
.p-text{
    padding: 0% 0% 26% 0%;
}
.size-text-area{
  font-size: 3.5vh;
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 

.size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 57%;
}

.p-text{
    padding: 0% 0% 26% 0%;
}
.size-text-area{
  font-size: 3.8vh;
}
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
    .size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 42%;
}
.p-text{
    padding: 0% 0% 20% 0%;
}
.size-text-area{
  font-size: 3.8vh;
}
 }
@media (min-width: 1905px)  { 
  .size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 42%;
}
.p-text{
    padding: 0% 0% 20% 0%;
}
.size-text-area{
  font-size: 3vh;
}
 }



</style>
