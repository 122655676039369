<template>
<div class="margin-contenido">
  <v-row justify="center">
    
    <v-col
      cols="12"
      sm="12"
      md="7"
      lg="7"
      xl="7"
      class="background-001625"
      
    >
    <v-carousel
    cycle
    hide-delimiter-background
    hide-delimiters
    :show-arrows="false"
    class="text-white magin-mapa"
    height="100%"
    width="100%"
  >
    <v-carousel-item
      src="../assets/mapa.png"
      
    >
      <div class="d-flex justify-center"> 
        <p class="text-white text-center text-KeepCalm-Medium size-text-title padding-title">SEDES</p>
      </div>
      <div class="card-eeuu">
      <v-card
        class="mx-auto width-card"
          variant="outlined"
        v-if="show_eeuu"
        max-width="180"
      >
        <v-expand-transition class="d-flex justify-center">
          <div v-show="show_eeuu">
            <div class="d-flex justify-center">
              <v-card-text class="text-white size-text-titulo">
                MIAMI, EEUU
              </v-card-text>
            </div>
          </div>
        </v-expand-transition>
      </v-card>
      </div>
      <div class="card-panama">
        <v-card
          class="mx-auto width-card"
          max-width="200"
          v-if="show_panama"
          variant="outlined"
        >
          <v-expand-transition class="d-flex justify-center">
            <div v-show="show_panama" >
              <div class="d-flex justify-center">
                <v-card-text class="text-white size-text-titulo">
                  PANAMÁ CITY, PANAMÁ
                </v-card-text>
              </div>
            </div>
          </v-expand-transition>
        </v-card>
      </div>
      <div class="card-mexico">
        <v-card
          variant="outlined"
          class="mx-auto width-card"
          max-width="180"
          v-if="show_mexico"
        >
          <v-expand-transition class="d-flex justify-center">
            <div v-show="show_mexico">
              <div class="d-flex justify-center">
                <v-card-text class="text-white size-text-titulo">
                  CDMX, MÉXICO
                </v-card-text>
              </div>
            </div>
          </v-expand-transition>
        </v-card>
      </div>
      <div class="card-espania">
        <v-card
          variant="outlined"
          class="mx-auto width-card "
          max-width="180"
          v-if="show_espania"
        >
          <v-expand-transition class="d-flex justify-center">
            <div v-show="show_espania">
              <div class="d-flex justify-center">
                <v-card-text class="text-white size-text-titulo">
                  MURCIA, ESPAÑA
                </v-card-text>
              </div>
            </div>
          </v-expand-transition>
        </v-card>
      </div>
      <div class="card-colombia">
        <v-card
          variant="outlined"
          class="mx-auto width-card"
          max-width="180"
          v-if="show_colombia"
        >
          <v-expand-transition class="d-flex justify-center">
            <div v-show="show_colombia">
              <div class="d-flex justify-center">
                <v-card-text class="text-white size-text-titulo">
                  BOGOTÁ, COLOMBIA
                </v-card-text>
              </div>
            </div>
          </v-expand-transition>
        </v-card>
      </div>
      <div class="card-bolivia">
        <v-card
          variant="outlined"
          class="mx-auto width-card "
          max-width="180"
          v-if="show_bolivia"
        >
          <v-expand-transition class="d-flex justify-center">
            <div v-show="show_bolivia">
              <div class="d-flex justify-center">
              <v-card-text class="text-white  size-text-titulo">
                SANTA CRUZ, BOLIVIA
              </v-card-text>
              </div>
            </div>
          </v-expand-transition>
        </v-card>
      </div>
      <div class="card-ecuador">
        <v-card
          variant="outlined"
          class="mx-auto width-card "
          max-width="180"
          v-if="show_ecuador"
        >
          <v-expand-transition class="d-flex justify-center">
            <div v-show="show_ecuador">
              <div class="d-flex justify-center">
              <v-card-text class="text-white  size-text-titulo">
                QUITO, ECUADOR
              </v-card-text>
              </div>
            </div>
          </v-expand-transition>
        </v-card>
      </div>

      <div class="ecuador-ubi d-flex">
        <v-btn
          variant="outlined"
          @click="show_ecuador = !show_ecuador"
          icon="mdi-map-marker-outline"
          color="white"
          :size="sizeBoton"
          class="size-bn opacity ecuador"
        >
        </v-btn>
      </div>
      <div class="d-flex">
        <div  class="mexico-ubi d-flex">
            <v-btn
              variant="outlined"
              icon="mdi-map-marker-outline"
              color="white"
              @click="show_mexico = !show_mexico"
              :size="sizeBoton"
          class="size-bn opacity mexico"
            >
            </v-btn>
        </div>
        <div class="eeuu-ubi d-flex ">
          <v-btn
            variant="outlined"
            color="white"
              @click="show_eeuu = !show_eeuu"
            :size="sizeBoton"
            icon="mdi-map-marker-outline"
          class="size-bn opacity eeuu"
          >
          </v-btn>
        </div>
        <div class="espania-ubi d-flex">
          <v-btn
            variant="outlined"
              @click="show_espania = !show_espania"
            icon="mdi-map-marker-outline"
            color="white"
            :size="sizeBoton"
          class="size-bn opacity espania"
          >
          </v-btn>
        </div>
      </div>
      <div class="d-flex">
        <div class="panama-ubi d-flex">
          <v-btn
            variant="outlined"
              @click="show_panama = !show_panama"
            icon="mdi-map-marker-outline"
            color="white"
            :size="sizeBoton"
          class="size-bn opacity panama"
          >
          </v-btn>
        </div>
        <div class="colombia-ubi d-flex">
          <v-btn
            variant="outlined"
              @click="show_colombia = !show_colombia"
            icon="mdi-map-marker-outline"
            color="white"
            :size="sizeBoton"
          class="size-bn opacity colombia"
          >
          </v-btn>
        </div>
      </div>
      <div class="bolivia-ubi d-flex">
        <v-btn
          variant="outlined"
          @click="show_bolivia = !show_bolivia"
          icon="mdi-map-marker-outline"
          color="white"
          :size="sizeBoton"
          class="size-bn opacity bolivia"
        >
        </v-btn>
      </div>
    </v-carousel-item>
    </v-carousel>
     
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="5"
      lg="5"
      xl="5"
    >      
      <v-row justify="center">
        <v-col
          cols="10"
          sm="6"
          md="10"
          lg="10"
          xl="10"
          class="background-1C759F"
        >
          <h5 class="text-white text-center text-KeepCalm-Medium text-llamadas-ta margin-0">Si tienes dudas, te llamamos</h5>
        </v-col>
        <v-col
          cols="10"
          sm="10"
          md="11"
          lg="11"
          xl="11"
        >
          <v-row justify="center">

            <v-col
              cols="12"
              sm="8"
              md="8"
              lg="8"
              xl="8"
            >
            <h5 class=" text-001C29 text-KeepCalm-Medium text-numero-ta">Número de celular</h5>  
            <div class="d-flex justify-center">
              <v-btn
                rounded="lg"
                class="background-001C29 text-white"
                height="50"
              >
                <v-img
                  src="../assets/mexico.webp"
                  aspect-ratio="1"
                  class="tan-bandera "
                >
                </v-img>
                <v-icon>mdi-chevron-down</v-icon> +52
              </v-btn>
              <v-text-field
                  variant="outlined"
                  class="text-001C29"
                  label="Celular"
                  required
              ></v-text-field>
            </div>  
            <h5 class=" text-001C29 text-KeepCalm-Medium text-numero-ta">Correo Electrónico </h5>             
              <v-text-field
                  variant="outlined"
                  class="text-001C29"
                  label="Email"
                  required
              ></v-text-field>
              
              <v-checkbox v-model="checkbox" 
              color="orange-darken-3" class="text-001C29">
                <template v-slot:label>
                  <div>
                    He leído y acepto las 
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://vuetifyjs.com"
                          @click.stop
                          v-on="on"
                        >
                          Políticas de Privacidad
                        </a>
                      </template>
                      Opens in new window
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="8"
              lg="8"
              xl="8"
            >
                <v-btn 
                  class="background-F59C1B text-001C29 " 
                  href="tel:65262795"
                  rounded="pill"
                  block>
                  Llámame ahora
                </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
      <v-col clos="12">
        <Separador :id="2"/>
      </v-col>
  </v-row>
  
    
</div>

</template>

<script>

import Separador from '@/components/Separador.vue'

export default {
  name: 'EsamContacto',
  props: {
    id: Number,
  },
  components : {
    Separador,
  },
 methods: { 
   href(href){
    window.open(href);
   }
   
 },
  data(){
    return {
      show_eeuu : false,
      show_panama : false,
      show_mexico : false,
      show_espania : false,
      show_colombia : false,
      show_bolivia : false,
      show_ecuador : false,
    };
  },
  mounted(){
    },
    computed : {
    sizeBoton(){
        if (screen.width > 0 && screen.width <= 600) {
            return "x-small"
        }
        if (screen.width > 600 && screen.width <= 960) {
            return "small"
        }
        if (screen.width > 960 && screen.width <= 1264) {
            return "large"
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            return "large"
        }
        if (screen.width > 1904) {
            return "x-large"
        }
        
        return "small"
     }
    }

      

}
</script>

<style scoped>



.opacity{
   opacity: 0.4;
}
.eeuu{
  cursor: pointer;
  color: inherit;
}
.eeuu:hover {
	color: #0077b7; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
  opacity: 1;
}
.panama{
  cursor: pointer;
  color: inherit;
}
.panama:hover {
	color: #0077b7; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
  opacity: 1;
}
.mexico{
  cursor: pointer;
  color: inherit;
}
.mexico:hover {
	color: #0077b7; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
  opacity: 1;
}
.espania{
  cursor: pointer;
  color: inherit;
}
.espania:hover {
	color: #0077b7; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
  opacity: 1;
}
.colombia{
  cursor: pointer;
  color: inherit;
}
.colombia:hover {
	color: #0077b7; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
  opacity: 1;
}
.bolivia{
  cursor: pointer;
  color: inherit;
}
.bolivia:hover {
	color: #0077b7; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
  opacity: 1;
}
.ecuador{
  cursor: pointer;
  color: inherit;
}
.ecuador:hover {
	color: #0077b7; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
  opacity: 1;
}
.div-ta0{
  height: 10px;
  margin: -12px;
}
.linkedin{
    cursor: pointer;
    color: inherit;
}
.linkedin:hover {
	color: #0077b7; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.instragram{
    cursor: pointer;
    color: inherit;
}
.instragram:hover {
	color: #b31373;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.facebook{
    cursor: pointer;
    color: inherit;
}
.facebook:hover {
	color: #0037c1;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.youtube{
    cursor: pointer;
    color: inherit;
}
.youtube:hover {
	color: #ff0000;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.twitter{
    cursor: pointer;
    color: inherit;
}
.twitter:hover {
	color: #1d9bf0;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.bn-text{
    cursor: pointer;
    color: inherit;
}
.bn-text:hover {
	color: #F59C1B;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-5px);
}

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-001625{
  background-color: #001625;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.text-0-8vw{
   font-size: 0.8vw
}
.text-size-icon{
   font-size: 3vw
}
.avtar-text{ 
    -webkit-margin-end: 2px; 
    margin-inline-end: 2px;
}
/** Mobile Devices  **/

@media (min-width: 0px) and (max-width: 320px) { 
    .width-card{
      width: 120px;
    }
    .size-text-titulo{
      font-size: 1.2vh;
    }
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 53%;
    bottom: 0%;
    top: 10%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 20%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 62%;
    bottom: 0%;
    top: 13%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 5%;
    bottom: 0%;
    top: 12%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 47%;
    bottom: 0%;
    top: 24%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 43%;
    bottom: 0%;
    top: 35%;
}

   .card-ecuador{
    position: absolute;
    left: 0%;
    right: 51%;
    bottom: 0%;
    top: 21%;
}
  .size-bn{
    --v-btn-height: 4px;
  }
  .bolivia-ubi{
    padding-left: 25%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  }
  .ecuador-ubi{
    position: absolute;
    padding-left: 21%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 16%;
  } 
  .colombia-ubi{
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .espania-ubi{
    padding-left: 17%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .mexico-ubi{ 
    padding-left: 15%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 6%;
  } 
  .panama-ubi{
    padding-left: 19%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 1%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 4%;
  }
}

@media (min-width: 320px) and (max-width: 375px) { 
    .width-card{
      width: 120px;
    }
    .size-text-titulo{
      font-size: 1.2vh;
    }
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 53%;
    bottom: 0%;
    top: 10%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 20%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 62%;
    bottom: 0%;
    top: 13%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 5%;
    bottom: 0%;
    top: 12%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 47%;
    bottom: 0%;
    top: 24%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 43%;
    bottom: 0%;
    top: 35%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 47%;
    bottom: 0%;
    top: 29%;
}
  .size-bn{
    --v-btn-height: 4px;
  }
  .bolivia-ubi{
    padding-left: 25%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 22%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 17%;
  } 
  .colombia-ubi{
    padding-left: 1%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 2%
  } 
  .espania-ubi{
    padding-left: 18%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 2%
  } 
  .mexico-ubi{ 
    padding-left: 15%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 8%;
  } 
  .panama-ubi{
    padding-left: 19%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 2%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 6%;
  }
}

@media (min-width: 375px) and (max-width: 425px) { 
    .width-card{
      width: 120px;
    }
    .size-text-titulo{
      font-size: 1.2vh;
    }
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 53%;
    bottom: 0%;
    top: 10%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 20%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 62%;
    bottom: 0%;
    top: 13%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 5%;
    bottom: 0%;
    top: 12%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 47%;
    bottom: 0%;
    top: 24%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 43%;
    bottom: 0%;
    top: 35%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 46%;
    bottom: 0%;
    top: 30%;
}
  .size-bn{
    --v-btn-height: 4px;
  }
  .bolivia-ubi{
    padding-left: 26%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 2%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 22%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 19%;
  } 
  .colombia-ubi{
    padding-left: 1%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 2%
  } 
  .espania-ubi{
    padding-left: 18%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .mexico-ubi{ 
    padding-left: 15%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 10%;
  } 
  .panama-ubi{
    padding-left: 19%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 3%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 8%;
  }
}
@media (min-width: 425px) and (max-width: 600px ) { 
    .width-card{
      width: 120px;
    }
    .size-text-titulo{
      font-size: 1.2vh;
    }
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 53%;
    bottom: 0%;
    top: 13%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 24%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 66%;
    bottom: 0%;
    top: 18%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 5%;
    bottom: 0%;
    top: 6%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 47%;
    bottom: 0%;
    top: 29%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 43%;
    bottom: 0%;
    top: 41%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 43%;
    bottom: 0%;
    top: 22%;
}
  .size-bn{
    --v-btn-height: 4px;
  }
  .bolivia-ubi{
    padding-left: 26%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 23%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 14%;
  } 
  .colombia-ubi{
    padding-left: 1%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .espania-ubi{
    padding-left: 19%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 6%
  } 
  .mexico-ubi{ 
    padding-left: 16%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 12%;
  } 
  .panama-ubi{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 3%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 10%;
  }
}
@media (min-width: 0px) and (max-width: 600px) { 
 .padding-title{
    padding-top: 4%
}
.size-text-title{
  font-size: 4vh;;
}
.avtar-text{ 
    -webkit-margin-end: 0px; 
    margin-inline-end: 0px;
}
.text-0-8vw{
   font-size: 1.8vw
}
.tan-bandera{
  
    width : 32px;
}
  .text-llamadas-ta{
    font-size: 5vw;
  }
  
  .text-numero-ta{
    font-size: 2.2vw;
  }
/** sive todo */
.text-descrip{
  display: none
}
.title-inge{
  font-size: 6vw;
}
.title-futuro{
  font-size: 7.1vw;
}
.padding-banner1{
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 

    .width-card{
      width: 125px;
    }
    .size-text-titulo{
      font-size: 1.4vh;
    }
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 53%;
    bottom: 0%;
    top: 20%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 31%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 66%;
    bottom: 0%;
    top: 24%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 11%;
    bottom: 0%;
    top: 13%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 47%;
    bottom: 0%;
    top: 33%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 43%;
    bottom: 0%;
    top: 46%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 51%;
    bottom: 0%;
    top: 39%;
}
   .size-bn{
    --v-btn-height: 20px;
  }
  .bolivia-ubi{
    padding-left: 26%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 21%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 22%;
  } 
  .colombia-ubi{
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 1%
  } 
  .espania-ubi{
    padding-left: 17%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 9%
  } 
  .mexico-ubi{ 
    padding-left: 15%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 15%;
  } 
  .panama-ubi{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 2%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 13%;
  }
   /** sive todo */
    .padding-title{
    padding-top: 1%
}
.size-text-title{
  font-size: 5vh;;
}
.tan-bandera{
  
   width : 33px;
}
  .text-llamadas-ta{
    font-size: 3vw;
  }
  
  .text-numero-ta{
    font-size: 2.2vw;
  }
.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 23%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 8%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
}
/** fin Banner */
 }
@media (min-width: 961px) and (max-width: 1024px) { 
   
    .width-card{
      width: 125px;
    }
    .size-text-titulo{
      font-size: 1.4vh;
    }
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 53%;
    bottom: 0%;
    top: 23%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 30%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 64%;
    bottom: 0%;
    top: 25%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 7%;
    bottom: 0%;
    top: 17%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 47%;
    bottom: 0%;
    top: 32%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 43%;
    bottom: 0%;
    top: 40%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 50%;
    bottom: 0%;
    top: 37%;
}
   .size-bn{
    --v-btn-height: 12px;
  }
  .bolivia-ubi{
    padding-left: 26%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 21%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 23%;
  } 
  .colombia-ubi{
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 1%
  } 
  .espania-ubi{
    padding-left: 18%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 13%
  } 
  .mexico-ubi{ 
    padding-left: 15%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 19%;
  } 
  .panama-ubi{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 2%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 17%;
  }

  }
@media (min-width: 1024px) and (max-width: 1264px) { 
   
    .width-card{
      width: 125px;
    }
    .size-text-titulo{
      font-size: 1.4vh;
    }
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 53%;
    bottom: 0%;
    top: 23%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 30%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 64%;
    bottom: 0%;
    top: 25%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 7%;
    bottom: 0%;
    top: 17%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 47%;
    bottom: 0%;
    top: 32%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 43%;
    bottom: 0%;
    top: 40%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 50%;
    bottom: 0%;
    top: 37%;
}
   .size-bn{
    --v-btn-height: 12px;
  }
  .bolivia-ubi{
    padding-left: 26%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 21%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 23%;
  } 
  .colombia-ubi{
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 1%
  } 
  .espania-ubi{
    padding-left: 18%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 10%
  } 
  .mexico-ubi{ 
    padding-left: 15%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 16%;
  } 
  .panama-ubi{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 3%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 14%;
  }

  }
/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) {
    .padding-title{
    padding-top: 6%
}
.size-text-title{
  font-size: 6vh;;
}
    .tan-bandera{
  
  width : 33px;
}
     .text-numero-ta{
    font-size: 1.8vw;
  }
  .text-llamadas-ta{
    font-size: 2vw;
  }
.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 5%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 

.text-size-icon{
   font-size: 2vw
}
}
  @media (min-width: 1265px) and (max-width: 1440px) { 
   
    .width-card{
      width: 160px;
    }
    .size-text-titulo{
      font-size: 1.5vh;
    }
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 53%;
    bottom: 0%;
    top: 24%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 34%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 64%;
    bottom: 0%;
    top: 26%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 10%;
    bottom: 0%;
    top: 18%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 47%;
    bottom: 0%;
    top: 37%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 42%;
    bottom: 0%;
    top: 48%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 52%;
    bottom: 0%;
    top: 41%;
}
   .size-bn{
    --v-btn-height: 18px;
  }
  .bolivia-ubi{
    padding-left: 26%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 21%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 26%;
  } 
  .colombia-ubi{
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 1%
  } 
  .espania-ubi{
    padding-left: 18%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 13%
  } 
  .mexico-ubi{ 
    padding-left: 15%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 19%;
  } 
  .panama-ubi{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 2%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 17%;
  }

  }
  
  @media (min-width: 1440px) and (max-width: 1904px) { 
    .width-card{
      width: 170px;
    }
    .size-text-titulo{
      font-size: 1.2vh;
    }
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 51%;
    bottom: 0%;
    top: 21%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 34%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 64%;
    bottom: 0%;
    top: 26%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 7%;
    bottom: 0%;
    top: 15%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 44%;
    bottom: 0%;
    top: 36%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 42%;
    bottom: 0%;
    top: 48%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 51%;
    bottom: 0%;
    top: 42%;
}
   .size-bn{
    --v-btn-height: 28px;
  }
  .bolivia-ubi{
    padding-left: 26%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 21%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 22%;
  } 
  .colombia-ubi{
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 1%
  } 
  .espania-ubi{
    padding-left: 17%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 7%
  } 
  .mexico-ubi{ 
    padding-left: 15%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 14%;
  } 
  .panama-ubi{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 2%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 11%;
  }

  }
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
.padding-title{
    padding-top: 2%
}
.size-text-title{
  font-size: 4.5vh;;
}
.tan-bandera{
  
  width : 33px;
}
 .text-llamadas-ta{
    font-size: 2vw;
  }
  
  .text-numero-ta{
    font-size: 1.8vw;
  }
 .title-inge{
  font-size: 4vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 

.text-size-icon{
   font-size: 1.9vw
}
 }

 
  @media (min-width: 1905px) and (max-width: 2105px) { 
    .card-eeuu{
    position: absolute;
    left: 0%;
    right: 53%;
    bottom: 0%;
    top: 24%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 37%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 64%;
    bottom: 0%;
    top: 29%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 10%;
    bottom: 0%;
    top: 16%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 46%;
    bottom: 0%;
    top: 38%;
}

   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 42%;
    bottom: 0%;
    top: 52%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 50%;
    bottom: 0%;
    top: 44%;
}
   .size-bn{
    --v-btn-height: 39px;
  }
  .bolivia-ubi{
    padding-left: 26%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 22%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 22%;
  } 
  .colombia-ubi{
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 1%
  } 
  .espania-ubi{
    padding-left: 17%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 7%
  } 
  .mexico-ubi{ 
    padding-left: 15%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 14%;
  } 
  .panama-ubi{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 2%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 11%;
  }

  }
 
  @media (min-width: 2105px)  { 
   .card-eeuu{
    position: absolute;
    left: -3%;
    right: 48%;
    bottom: 0%;
    top: 27%;
}
   .card-panama{
    position: absolute;
    left: 0%;
    right: 55%;
    bottom: 0%;
    top: 40%;
}
   .card-mexico{
    position: absolute;
    left: 0%;
    right: 62%;
    bottom: 0%;
    top: 30%;
}
   .card-espania{
    position: absolute;
    left: 0%;
    right: 9%;
    bottom: 0%;
    top: 19%;
}
   .card-colombia{
    position: absolute;
    left: 0%;
    right: 46%;
    bottom: 0%;
    top: 40%;
}
   .card-bolivia{
    position: absolute;
    left: 0%;
    right: 42%;
    bottom: 0%;
    top: 53%;
}
.card-ecuador{
    position: absolute;
    left: 0%;
    right: 51%;
    bottom: 0%;
    top: 47%
}
   .size-bn{
    --v-btn-height: 53px;
  }
  .bolivia-ubi{
    padding-left: 26%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 3%
  } 
  .ecuador-ubi{
    position: absolute;
    padding-left: 22%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 24%;
  } 
  .colombia-ubi{
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 1%
  } 
  .espania-ubi{
    padding-left: 17%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 9%
  } 
  .mexico-ubi{ 
    padding-left: 16%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 15%;
  } 
  .panama-ubi{
    padding-left: 20%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 0%
  } 
  .eeuu-ubi{
    padding-left: 1%;
    padding-right: 0%;
    padding-bottom: 0%;
    padding-top: 13%;
  }
  }
@media (min-width: 1905px)  { 
  
  .padding-title{
    padding-top: 2%
}
.size-text-title{
  font-size: 4.5vh;;
}  
.magin-mapa{
  margin: -10px 0px 0px 0px;
}
.tan-bandera{
  
  width : 33px;
}
  .text-llamadas-ta{
    font-size: 2vw;
  }
  
  .text-numero-ta{
    font-size: 1.5vw;
  }
 .title-inge{
  font-size: 3vw;
}
.title-futuro{
  font-size: 4vw;
}
.padding-banner1{
    padding-left: 35%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 3%
} 
.text-size-icon{
   font-size: 1.5vw
}

 }



</style>
