<template>
<div>
    <v-carousel
    cycle
    height="max-height"
    hide-delimiter-background
    :show-arrows="false"
    class="text-white "
  >
    
    <v-carousel-item
      src="../assets/fondo1.webp"
      cover
    >
    <div
      class="d-flex justify-center">

      <video 
            src="https://esamlatam.com/video/video.mp4" 
            class="video-width video-responsive"
             autoplay loop  muted></video>
    </div>
    </v-carousel-item>
  <template v-if="sliders.length == 0">
    
            
    <v-carousel-item
      src="../assets/fondo1.webp"
      cover
    >
        <div class="padding-banner1 d-flex justify-center">
          <h1 class="text-KeepCalm-Medium  title-futuro text-center">TU FUTURO PROFESIONAL ES HOY</h1>
        </div>
    </v-carousel-item>
  </template>
  <template 
        v-for="(slid, index) in sliders" v-bind:key="index" >
    <v-carousel-item
      :src="baseUrl+''+baseImagen+''+slid.imagen"
      cover
      v-if="slid.estado_nombre == 0"
    >
        <div class="padding-banner1 d-flex justify-center">
          <h1 class="text-KeepCalm-Medium  title-futuro text-center">{{slid.nombre}}</h1>
        </div>
    </v-carousel-item>
      <v-carousel-item
        :src="baseUrl+''+baseImagen+''+slid.imagen"
        cover
        v-if="slid.estado_nombre == 1"
      >
        <div class="padding-banner2">
            <h3 class=" text-KeepCalm-Medium title-inge">{{slid.nombre}}</h3>
            <p class="text-KeepCalm-Medium text-descrip">{{slid.descripcion}}</p>     
        </div>
      </v-carousel-item>
  </template>
  </v-carousel>
  </div>
</template>

<script>

import axios from 'axios'
var url ='backend-esamlatam/crud-slider.php';
export default {
  name: 'EsamCarousel',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
  computed : {
    
    width_video() {
      if (screen.width > 0 && screen.width <= 600) {
        return "440";
      }

      if (screen.width > 600 && screen.width <= 960) {
        return "640";
      }

      if (screen.width > 960 && screen.width <= 1264) {
        return "840";
      }

      if (screen.width > 1264 && screen.width <= 1904) {
        return "1040";
      }

      if (screen.width > 1904) {
        return "1240";
      }
      return "small";
    },
  },
 methods: {
   mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1,tipo_slider :'B'})
      .then(Response =>{
        this.sliders=Response.data
      })
    },
     getheight(){
        if (screen.width > 0 && screen.width <= 600) {
            this.carouselheight=250
        }
        if (screen.width > 600 && screen.width <= 960) {
            this.carouselheight=350
        }
        if (screen.width > 960 && screen.width <= 1264) {
            this.carouselheight=400
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            this.carouselheight=500
        }
        if (screen.width > 1904) {
            this.carouselheight=650
        }
     },
  
   
 },
  data(){
    return {
      carouselheight: 0,
      baseUrl : "https://esamlatam.com/",
      //baseUrl : "http://localhost/",
       baseImagen : "imagenes/img-slider/",
      sliders : [],
    };
  },
  mounted(){
      this.mostrar();
    }

      

}
</script>

<style scoped>
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}


.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.videotam{
 width: "50%" ;
}
.video-width{
 width: 100% ;
 height: auto;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
/** sive todo */
.text-descrip{
  /*display: none*/
  display : block
}
.title-inge{
  font-size: 6vw;
}
.title-futuro{
  font-size: 7.1vw;
}
.padding-banner1{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */

.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 8%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 5%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
 .title-inge{
  font-size: 4vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
 }
@media (min-width: 1905px)  { 
  
 .title-inge{
  font-size: 3vw;
}
.title-futuro{
  font-size: 4vw;
}
.padding-banner1{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 3%
} 
 }
</style>
