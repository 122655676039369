<template>

  <v-row justify="center">
    
    <v-col
      cols="10"
      sm="6"
      md="6"
      lg="6"
      xl="6"
    >
    
    <v-sheet
      elevation="10"
      rounded="xl"
    >
      <v-sheet
        class="pa-3 primary text-right"
        dark
        rounded="t-xl"
      >
        <v-row justify="center" >
          <v-col
            cols="5"
            sm="3"
            md="3"
            lg="3"
            xl="3"
          >
            <v-img
                  src="../assets/logosEsamLatam1.webp"
                  width="100%"
                  aspect-ratio="1"
              >
              </v-img>
          </v-col>
        </v-row>
        <v-row justify="center" >
          <v-col
            cols="10"
            sm="7"
            md="7"
            lg="7"
            xl="7"
          >
            <v-card-text>
              <v-text-field
                label="User"
                placeholder="User"
                v-model="user.users"
              ></v-text-field>
              <v-text-field
                label="Password"
                type="password"
                v-model="user.passwords"
              ></v-text-field>
            </v-card-text>
            <v-card-actions >
              <v-btn
                rounded="pill"
                block
                class="background-1C75BC text-white"
                @click="mostrar"
              >
                Ingresar
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>
    </v-col>
  </v-row>
</template>

<script>

//import { Carousel, Slide, Navigation } from 'vue3-carousel';

import axios from 'axios'
var url ='backend-esamlatam/crud-login.php';
export default {
  name: 'EsamLogin',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
   
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1,users : this.user.users, passwords : this.user.passwords})
      .then(Response =>{
        this.users=Response.data;
        if(this.users.length == 0){
          console.log("ssasas");
        }else{

          console.log("ssasas");
        }

      })
    },
 },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://esamlatam.com/",
      users : [],
      user : {
        id: null,
        users : "",
        passwords : "",
      },

    }
  },
  mounted(){
    }

      

}
</script>

<style scoped>
.v-card--variant-contained {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 0%);
}

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}


@media (min-width: 0px) and (max-width: 600px) { 
 .video-width{
    width: 100px;
  }
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
  .video-width{
    width: 180px;
  }
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
.video-width{
    width: 200px;
  }
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
  .video-width{
    width: 240px;
  }

 }
@media (min-width: 1905px)  { 
 
 }

</style>
