<template>
<div>
    
    <v-row >
      <v-col
        cols="10"
        offset="1"
      >
        <v-btn
          color="success"
           @click="formNuevo()"
        >
            CREAR <v-icon>mdi-table-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="10"
        offset="1"
      >
        <v-table
            fixed-header
            height="900px" 
        >
            <thead >
            <tr class="light-blue darken-2" >
                <th class="text-left">
                #
                </th>
                <th class="text-left">
                TITULO
                </th>
                <th class="text-left">
                DESCRIPCION
                </th>
                <th class="text-left">
                IMAGEN
                </th>
                <th class="text-left">
                ESTADO
                </th>
                <th class="text-left">
                ACCCIONES
                </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="(moti, index) in motivaciones" v-bind:key="index"
            >
                <td>{{index+1}}</td>
                <td>{{moti.titulo}}</td>
                <td>{{moti.descripcion}}</td>
                <td> 
                  <v-img
                      :src="baseUrl+''+baseImagen+''+moti.imagen"
                      width="100%"
                      aspect-ratio="1"
                      class="cursor"
                      @click="formImagen (moti)"
                  >
                  </v-img>
                </td>
                <td class="text-blue" v-if="moti.estado == 1">
                  HABILITADO
                </td>
                <td class="text-red" v-if="moti.estado == 0">
                  DESHABILITADO 
                </td>
                <td class="text-white">
                <v-btn
                  icon="mdi-table-edit"
                  color="info"
                  @click="formEditar (moti)"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye-off"
                    @click="formElimina(moti.id)"
                    class="background-red"
                    v-if="moti.estado == 1"
                ></v-btn>
                <v-btn
                    icon="mdi-table-eye"
                    @click="formHabilitar(moti.id)"
                    class="background-1C759F"
                    v-if="moti.estado == 0"
                ></v-btn>
                </td>
            </tr>
            </tbody>
        </v-table>
        </v-col>
    </v-row>
    
    <v-dialog
      v-model="dialog"
      persistent
    >
    <v-card class="dialog_tan">
      <v-form 
      v-model="valid"
      lazy-validation
      ref="form">
      <v-toolbar
        color="success"
      >Nuevo Slider</v-toolbar>
      <v-card-text>
            <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              v-if="!if_imagen"
              class="padding-0"
            >
              <v-text-field
              :rules="textRules"
                label="Titulo"
                required
                v-model="motivacion.titulo"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              v-if="!if_imagen"
              class="padding-0"
            >
              <v-text-field
              :rules="textRules"
                label="Descripcion"
                required
                v-model="motivacion.descripcion"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              v-if="if_imagen == if_crear"
              class="padding-0"
            >
              <v-file-input 
                label="imagen"
                show-size
                filled
                prepend-icon="mdi-camera"
                @change="cargarImagen" 
              ></v-file-input>
            </v-col>
            
          </v-row>
        <!--<div class="text-h2 pa-12">Hello world!</div>-->
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="dialog=false"
        >Cancelar</v-btn>
        <v-btn
          text
          @click="guardar_s"
        >Guardar</v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEliminar"
    >
    <v-toolbar
      class="background-red text-white"
    >DESHABILITAR</v-toolbar>
    <v-card>
      <v-card-text>
        <span >Esta seguro de desHabiliatar ?</span>
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          color="success"
          @click="dialogEliminar=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-red "
          @click="eliminar_slider"
        >Si</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogHabilitar"
    >
    <v-toolbar
      class="background-1C759F text-white"
    >HABILITAR</v-toolbar>
    <v-card>
      <v-card-text>
        <span >Esta seguro de Habiliatar ?</span>
      </v-card-text>
      <v-card-actions class="justify-end text-white">
        <v-btn
          color="success"
          @click="dialogHabilitar=false"
        >Cancelar</v-btn>
        <v-btn
          class="background-red "
          @click="habilitar_slider"
        >Si</v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>

  
</template>

<script>

import axios from 'axios'
var url ='backend-esamlatam/crud-motivacion.php';
var urlsubirimagen ='backend-esamlatam/upload-motivacion.php'
export default {
  name: 'EsamMotivacionConfi',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
    formNuevo (){
      this.dialog = true
      this.operacion='crear';
      this.motivacion.titulo = "";
      this.motivacion.descripcion = "";
      this.motivacion.imagen = "";
      this.if_imagen=false;
      this.if_crear = false;
    },
    formElimina(id){
      this.motivacion.id=id;
      this.dialogEliminar = true
    },
    formHabilitar(id){
      this.motivacion.id=id;
      this.dialogHabilitar = true
    },
    formEditar (motivacion1){
      this.dialog = true;
      this.operacion='editar';
      this.motivacion.id = motivacion1.id;
      this.motivacion.titulo = motivacion1.titulo;
      this.motivacion.descripcion = motivacion1.descripcion;
      this.motivacion.imagen = motivacion1.imagen;
      this.if_imagen = false;
      this.if_crear = true;
    },
    formImagen (motivacion1){
      this.dialog = true;
      this.operacion='img';
      this.motivacion.id = motivacion1.id;
      this.motivacion.titulo = motivacion1.titulo;
      this.motivacion.descripcion = motivacion1.descripcion;
      this.motivacion.imagen = motivacion1.imagen;
      this.if_crear = true;
      this.if_imagen = true;
    },
    guardar_slider (){
      if(this.operacion=='crear'){
        this.crear_slider();                 
      }
      if(this.operacion=='img'){ 
        this.subirImagen();                           
      }
      if(this.operacion=='editar'){ 
        this.editar_slider();                           
      }
      this.dialog=false;  
    },
    eliminar_slider (){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.motivacion.id, estado: false })
        .then(response =>{
          response;
          this.mostrar();

        });  
      
      this.motivacion.id = null;
      this.dialogEliminar= false;

    },
    habilitar_slider(){
      axios.post(this.baseUrl+''+url, {opcion:4, id:this.motivacion.id, estado: true })
        .then(response =>{
          response;
          this.mostrar();
        });  
      
      this.motivacion.id = null;
      this.dialogHabilitar= false;

    },
    crear_slider (){
        
      axios.post(this.baseUrl+''+url, {opcion:2, titulo:this.motivacion.titulo, 
                                descripcion:this.motivacion.descripcion, imagen:this.motivacion.imagen })
        .then(response =>{
          response;
          this.mostrar();
        });  
      this.motivacion.titulo = "";
      this.motivacion.descripcion = "";
      this.motivacion.imagen = "";
      this.dialog= false;

    },
    editar_slider (){
      axios.post(this.baseUrl+''+url, {opcion:3,id:this.motivacion.id,titulo: this.motivacion.titulo, 
                                descripcion:this.motivacion.descripcion, imagen:this.motivacion.imagen })
        .then(response =>{
          response;
          this.mostrar();
        });  
      this.motivacion.titulo = "";
      this.motivacion.descripcion = "";
      this.motivacion.imagen = "";
      this.dialog= false;

    },
   cargarImagen(e){
    this.file = e.target.files[0];
   },
   subirImagen(){
    var fd = new FormData();
    fd.append("file", this.file, this.file.name)
    //console.log(fd.getAll("file"));
    
    axios
    .post(this.baseUrl+''+urlsubirimagen, fd)
    .then(response => {
        //console.log(response.data[1])
      this.motivacion.imagen=response.data[1];
      if(this.operacion=='img'){ 
        this.editar_slider();                          
      }else{
        this.guardar_slider();
      }
      this.mostrar();
    })
    .catch(error => {
        console.log(error.data)
    })
   },
   guardar_s(){
     
    if(this.operacion=='crear' || this.operacion=='img'){
      this.subirImagen();              
    }
    if(this.operacion=='editar'){ 
      this.editar_slider();                           
    }
   },
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:5})
      .then(Response =>{
        this.motivaciones=Response.data
      })
    },
     getheight(){
        if (screen.width > 0 && screen.width <= 600) {
            this.carouselheight=250
        }
        if (screen.width > 600 && screen.width <= 960) {
            this.carouselheight=350
        }
        if (screen.width > 960 && screen.width <= 1264) {
            this.carouselheight=400
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            this.carouselheight=500
        }
        if (screen.width > 1904) {
            this.carouselheight=650
        }
     },
  
   
 },
  data(){
    return {
      carouselheight: 0,
      valid : true,
      d : null,
      if_imagen : true,
      if_crear : true,
      operacion : '',
      ubicaciones : ['Inferios',"Superior"],
      estado_nombre : ['Activado',"Desactivar"],
      tipo_slider : ['Banner',"Promociones"],
      motivaciones : [],
      motivacion : {
        id: null,
        titulo : "",
        descripcion : "",
        imagen : "",
        estado : ""
      },
       file: null,
       dialog : false,
       dialogEliminar : false,
       dialogHabilitar : false,
       baseUrl : "http://localhost/",
       baseImagen : "imagenes/img-motivacion/",
       
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      textRules: [
        v => !!v || 'Es requerido el campo',
      ],
    };
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.background-red{
  background-color: red;
}
.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.cursor{
 cursor: pointer;
    color: inherit;
}

.padding-0{
    padding: 0px;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
 .dialog_tan{
   width : 320px
 }
/** sive todo */
.text-descrip{
  display: none
}
.title-inge{
  font-size: 6vw;
}
.title-futuro{
  font-size: 7.1vw;
}
.padding-banner1{
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */
.dialog_tan{
   width : 601px
 }
.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 23%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 8%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
  .dialog_tan{
   width : 930px
 }
.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 5%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
  .dialog_tan{
   width : 1200px
 }
 .title-inge{
  font-size: 4vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
 }
@media (min-width: 1905px)  { 
  .dialog_tan{
   width : 1890px
 }
 .title-inge{
  font-size: 3vw;
}
.title-futuro{
  font-size: 4vw;
}
.padding-banner1{
    padding-left: 35%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 3%
} 
 }



</style>
