<template>
    <div >
    <v-row justify="center">
        <v-col cols="10">
            <v-row justify="center">
                <template 
                v-for="(socio, index) in socios" v-bind:key="index">
                    <v-col
                        cols="4"
                        sm="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="d-flex justify-center"
                    >       
                        <v-img
                            :src="baseUrl+''+baseImagen+''+socio.imagen"
                            width="100%"
                            aspect-ratio="1"
                            class="cursor"
                            @click="logohref(socio.enlace)"
                        >
                        </v-img>
                    </v-col>
                    <v-col
                        cols="8"
                        sm="8"
                        md="8"
                        lg="8"
                        xl="8"
                    >
                        <v-card-title>
                            {{socio.nombre}}
                        </v-card-title>
                        <v-card-subtitle>
                            {{socio.descripcion}}
                        </v-card-subtitle>
                    </v-col>
                </template>
            </v-row>
        </v-col>
    </v-row>
    </div>
    
    
</template>

<script>
import axios from 'axios'
var url ='backend-esamlatam/crud-socio.php';

export default {
  name: 'EsamConvenios',
  props: {
    id: Number,
  },
  components : {
  },
 methods: { 
  
   logohref(href){
    window.open(href);
   },
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1})
      .then(Response =>{
        this.socios=Response.data
      })
    },
   
 },
  data(){
    return {
      carouselheight: 0,
       baseUrl : "http://localhost/",
       //baseUrl : "https://esamlatam.com/",
       baseImagen : "imagenes/img-socio/",
       socios : [],
    };
  },
  mounted(){
    this.mostrar();
    }

      

}
</script>

<style scoped>
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}

.cursor{
 cursor: pointer;
    color: inherit;
}
.cursor:hover {
	transform: translateY(-7px);
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
 
/** sive todo */
.text-descrip{
  display: none
}
.title-inge{
  font-size: 6vw;
}
.title-futuro{
  font-size: 7.1vw;
}
.padding-banner1{
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */

.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 23%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 8%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 5%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
 .title-inge{
  font-size: 4vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
 }
@media (min-width: 1905px)  { 
 .title-inge{
  font-size: 3vw;
}
.title-futuro{
  font-size: 4vw;
}
.padding-banner1{
    padding-left: 35%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 3%
} 
 }



</style>
