<template>
  <div :v-model="mostrar()"></div>
  <div :v-model="mostrar_categoria()"></div>
    <v-crad-title  v-for="(cat, index) in categoria" v-bind:key="index"
    class="d-flex justify-center size-text-titulo mb-6 margin-title">
        <h2 class="text-001C29 text-keep-calm-font">NUESTROS</h2>
        <h2 class="text-6CD6E0 text-keep-calm-font"> &nbsp;{{cat.nombre}}</h2>
    </v-crad-title>
   <div class="mb-6">
      
        <v-row justify="center" class="mb-6">
            <v-col cols="10"
                    sm="10"
                    md="11"
                    lg="10"
                    xl="10">
                <v-row justify="center">
                  <template  v-for="(area, index) in areas" v-bind:key="index"> 
                    <v-col cols="12"
                            sm="6"
                            md="4"
                            lg="4"
                            xl="4"
                            class="">
                        <v-card
                            class="mx-auto cursor"                            
                        >
                        <v-hover v-slot="{ isHovering, props }">
                            <v-img
                              v-bind="props"
                              class="align-end text-white"
                              :src="baseUrl+''+baseImagen+''+area.imagen"
                              v-on:click="enviarId(area.id)"   
                            >
                                <v-expand-transition>
                                <div
                                    v-if="isHovering"                                   
                                >
                                  <video 
                                      :src="baseVideo+''+area.video" 
                                      class="video-width video-responsive"
                                      autoplay loop  muted></video>
                                </div>
                                </v-expand-transition>
                            </v-img>
                        </v-hover>
                        </v-card>
                    </v-col>
                  </template>
                </v-row>
            </v-col>
        </v-row>
   </div>
   <div>
        <v-crad-title class="d-flex justify-center size-text-titulo mb-6 margin-title">
            <h4 class="text-001C29 text-keep-calm-font">¿Qué te gustaría   </h4>
            <h4 class="text-6CD6E0 text-keep-calm-font"> &nbsp;aprender hoy?</h4>
        </v-crad-title>
        <div class="d-flex justify-center">
            <div class="width-buscador rounded-pill">
                <v-text-field 
                    class="rounded-pill"
                    variant="outlined"
                    rounded="pill"
                >
                </v-text-field>
            </div>
        </div>
        
   </div>
</template>

<script>

import axios from 'axios'
var url ='backend-esamlatam/crud-area.php';
var url_categoria ='backend-esamlatam/crud-categoria.php';

export default {
  name: 'EsamDiplomado',
  props: {
    id: Number,
  },
  components : {
  },
 methods: { 
   href(href){
    window.open(href);
   },
    
    mostrar(){
      axios.post(this.baseUrl+''+url,{opcion:1,categorias_id:this.id})
      .then(Response =>{
        this.areas=Response.data
      })
    },   
    mostrar_categoria(){
      axios.post(this.baseUrl+''+url_categoria,{opcion:1,id:this.id})
      .then(Response =>{
        this.categoria=Response.data
      })
    },     
   enviarId(id){
     this.$emit("categoria_id",id),
     this.$emit("posgrados",true),
     this.$emit("categoria",false)
    },
 },
  computed: {
    ifcol(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return true;
      }

      return true;
    }
      
  },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://esamlatam.com/",
       baseImagen : "imagenes/img-area/",
       baseVideo : "https://esamlatam.com/video/",
       areas : {},
       categoria : {}
    };
  },
  mounted(){
    //this.mostrar();
    this.mostrar_categoria();
    }     

}
</script>

<style scoped>
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}
.margin-title{
  margin: 50px 10px 10px 10px;
}

.text-001C29{
  color: #001C29;
}
.text-94ccd8{
  color: #94ccd8;
}
.text-df9d34{
  color: #df9d34;
}
.text-cb1c7d{
  color: #cb1c7d;
}
.text-c6d04c{
  color: #c6d04c;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-f8e865{
  color : #f8e865;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.text-1c75bc{
  color: #1c75bc;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.text-0-8vw{
   font-size: 0.8vw
}
.text-size-icon{
   font-size: 6vw
}
.text-areas{
  color: #0076c0;
}
.avtar-text{ 
    -webkit-margin-end: 2px; 
    margin-inline-end: 2px;
}
.size-text-titulo{
    font-size: 5vh;
}
.width-buscador{
    width: 39%;
}
.cursor{
 cursor: pointer;
    color: inherit;
}
.cursor:hover {
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
} 


.bordes-negro{
letter-spacing: 0;
text-shadow: -1px -1px 0.2px #333, 0.5px -1px 0.2px #333;
}
.background-degradado{

background: linear-gradient(to  right, rgba(15,117,188,0), rgba(15,117,188,1),rgba(15,117,188,1), rgba(15,117,188,0))
}
.video-width{
 width: 100% ;
 height: auto;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
/** fin Banner */
.p-text{
    padding: 0% 0% 30% 0%;
}
.size-text-titulo{
    font-size: 1.8vh;
}
.size-text-area{
  font-size: 3.5vh;
}
.width-buscador{
    width: 80%;
}
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
    .size-text-titulo{
    font-size: 2.4vh;
}

.width-buscador{
    width: 58%;
}
.p-text{
    padding: 0% 0% 26% 0%;
}
.size-text-area{
  font-size: 3.5vh;
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 

.size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 57%;
}

.p-text{
    padding: 0% 0% 26% 0%;
}
.size-text-area{
  font-size: 3.8vh;
}
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
    .size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 42%;
}
.p-text{
    padding: 0% 0% 20% 0%;
}
.size-text-area{
  font-size: 3.8vh;
}
 }
@media (min-width: 1905px)  { 
  .size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 42%;
}
.p-text{
    padding: 0% 0% 20% 0%;
}
.size-text-area{
  font-size: 3vh;
}
 }



</style>
