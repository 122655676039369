<template>
<v-row justify="center" class="margin-estadisita">
    <v-col 
        cols="12">
        <p class="text-center text-white text-keep-calm-font text-concluido-2000 ">41.638</p>
        <p class="text-center text-6CD6E0 text-keep-calm-font text-concluido-titulo margin-curso ">ESTUDIANTES ANUALES </p>
    </v-col>
    <v-col
        cols="12"
        sm="10"
        md="10"
        lg="10"
        xl="10"
    >
        <v-row >
        <v-col
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
        >
            <h3 class="text-center text-white text-keep-calm-font text-concluido-titulo1">12.966</h3>
            <p class="text-center text-6CD6E0 text-keep-calm-font text-concluido-conte">POSGRADOS</p>
        </v-col>
        <v-col
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
        >
        <h3 class="text-center text-white text-keep-calm-font text-concluido-titulo1">9.950</h3>
            <p class="text-center text-6CD6E0 text-keep-calm-font text-concluido-conte">CURSOS</p>
        </v-col>
        <v-col
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
        >
            <h3 class="text-center text-white text-keep-calm-font text-concluido-titulo1">12</h3>
            <p class="text-center text-6CD6E0 text-keep-calm-font text-concluido-conte">EMPRESAS PARTNERS </p>
        </v-col>
        <v-col
            cols="6"
            sm="3"
            md="3"
            lg="3"
            xl="3"
        >
            <h3 class="text-center text-white text-keep-calm-font text-concluido-titulo1">7</h3>
            <p class="text-center text-6CD6E0 text-keep-calm-font text-concluido-conte">SEDES</p>
        </v-col>
        </v-row>
    </v-col>
</v-row>
</template>

<script>


export default {
  name: 'EsamEstadisticas',
  props: {
  },
  components : {
  },
  computed : {
  },
 methods: {
     getheight(){
        if (screen.width > 0 && screen.width <= 600) {
            this.carouselheight=250
        }
        if (screen.width > 600 && screen.width <= 960) {
            this.carouselheight=350
        }
        if (screen.width > 960 && screen.width <= 1264) {
            this.carouselheight=400
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            this.carouselheight=500
        }
        if (screen.width > 1904) {
            this.carouselheight=650
        }
     },
  
   
 },
  data(){
    return {
    }
  },
  mounted(){
    }

      

}
</script>

<style scoped>

.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}


.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}

.margin-estadisita{
    margin:  0px 0px 50px 0px;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
/** fin Banner */
  .text-concluido-2000{
    font-size: 22vw
  }
  .text-concluido-titulo{
    font-size: 5vw
  }
  .margin-curso{
    margin : -25px 0px 0px 0px;
  }
  .margin-estadisita{
    margin:  0px 0px 10px 0px;
}

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
      .text-concluido-2000{
    font-size: 22vw
  }
  .text-concluido-titulo{
    font-size: 5.1vw
  }
  .margin-curso{
    margin : -55px 0px 0px 0px;
  }
  .text-concluido-conte{
    font-size: 1.6vw
  }
  .margin-estadisita{
    margin:  0px 0px 20px 0px;
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
     .text-concluido-2000{
    font-size: 13vw
  }
  
  .text-concluido-titulo1{
    font-size: 4.5vw
  }  
  .text-concluido-titulo{
    font-size: 3vw
  }
  .margin-curso{
    margin : -55px 0px 0px 0px;
  }
  .text-concluido-conte{
    font-size: 1.5vw
  }
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
    
  .text-concluido-2000{
    font-size: 13.1vw
  }
  .text-concluido-titulo1{
    font-size: 4vw
  }
  .margin-curso{
    margin : -60px 0px 0px 0px;
  }
  .text-concluido-titulo{
    font-size: 3vw
  }
  .text-concluido-conte{
    font-size: 1.5vw
  }
 }
@media (min-width: 1905px)  { 
    
  .text-concluido-2000{
    font-size: 13.1vw
  }
  .text-concluido-titulo1{
    font-size: 4vw
  }
  .margin-curso{
    margin : -120px 0px 0px 0px;
  }
  .text-concluido-titulo{
    font-size: 3vw
  }
  .text-concluido-conte{
    font-size: 1.5vw
  }
 }
</style>
