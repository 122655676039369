<template>
    <div v-if="id === 1" class="padding-divs">
    <v-row >
      <v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        class="padding-col"
      >
        <div class=" div-ta0 background-F20780"></div>
      </v-col>
      <v-col
        xs="2"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        class="padding-col"
      >
        <div class=" div-ta0 background-F59C1B"></div>
      </v-col>
      <v-col
        xs="2"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        class="padding-col"
      >
        <div class=" div-ta0 background-FFEA5A"></div>
      </v-col>
      <v-col
        xs="2"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        class="padding-col"
      >
        <div class=" div-ta0 background-C2D13D"></div>
      </v-col>
      <v-col
        xs="2"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        class="padding-col"
      >
        <div class=" div-ta0 background-6CD6E0"></div>
      </v-col>
      <v-col
        xs="2"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        class="padding-col"
      >
        <div class=" div-ta0 background-1C75BC"></div>
      </v-col>
      <v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        
       class="padding-col"
      >
        <div class=" div-ta0 background-001C29"></div>
      </v-col>
    </v-row>
    </div>
    
    <div v-if="id === 2">
    <v-row >
      <v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        offset="5"
        offset-sm="5"
        offset-md="5"
        offset-lg="5"
        offset-xl="5"
        class="padding-col"
      >
        <div class=" div-ta0 background-F20780"></div>
      </v-col>
      <v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        class="padding-col"
      >
        <div class=" div-ta0 background-F59C1B"></div>
      </v-col>
      <v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
       class="padding-col"
      >
        <div  class=" div-ta0 background-FFEA5A"></div>
      </v-col>
      <v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        class="padding-col"
      >
        <div class=" div-ta0 background-C2D13D"></div>
      </v-col>
      <v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        class="padding-col"
      >
        <div class=" div-ta0 background-6CD6E0"></div>
      </v-col>
      <v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        class="padding-col"
      >
        <div class=" div-ta0 background-1C75BC"></div>
      </v-col>
      <v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        class="padding-col"
      >
        <div class=" div-ta0 background-001C29"></div>
      </v-col>
    </v-row>
    </div>
    <div v-if="id === 3" class="padding-div">
    <v-row >
      <!--<v-col
        xs="1"
        sm="1"
        md="1"
        lg="1"
        xl="1"
        class="padding-col"
      >
        <div class=" div-ta0 background-001C29"></div>
      </v-col>-->
      <v-row>
        <v-col cols="8">
          <v-row>
            <v-col
              cols="2"
              class="padding-col"
            >
            
              <div class=" div-ta0 background-1C75BC"></div>
            </v-col>
            <v-col
              cols="2"
              class="padding-col"
            >
              <div class=" div-ta0 background-6CD6E0"></div>
            </v-col>
            <v-col
              cols="2"
              class="padding-col"
            >
              
              <div class=" div-ta0 background-C2D13D"></div>
            </v-col>
            <v-col
              cols="2"
              class="padding-col"
            >
              
              <div class=" div-ta0 background-FFEA5A"></div>
            </v-col>
            <v-col
              cols="2"
            class="padding-col"
            >
              
              <div  class=" div-ta0 background-F59C1B"></div>
            </v-col>
            <v-col
              cols="2"
              class="padding-col"
            >
              <div class=" div-ta0 background-F20780"></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      
    </v-row>
    </div>
</template>

<script>


export default {
  name: 'EsamSeparador',
  props: {
    id: Number,
  },
  components : {
  },
 methods: { 
   
 },
  data(){
    return {
      carouselheight: 0,
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}

.div-ta0{
  height: 10px;
  margin: 0px;
}
.padding-div{
  padding: 12px;
}  
.padding-col{
  padding: 0px;
}    
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
 
/** sive todo */
.text-descrip{
  display: none
}
.title-inge{
  font-size: 6vw;
}
.title-futuro{
  font-size: 7.1vw;
}
.padding-banner1{
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 15%
}
/** fin Banner */

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */

.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 23%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 8%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
.title-inge{
  font-size: 5vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 5%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
 .title-inge{
  font-size: 4vw;
}
.title-futuro{
  font-size: 6vw;
}
.padding-banner1{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 5%
} 
 }
@media (min-width: 1905px)  { 
 .title-inge{
  font-size: 3vw;
}
.title-futuro{
  font-size: 4vw;
}
.padding-banner1{
    padding-left: 35%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-banner2{
    padding-left: 10%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 3%
} 
 }



</style>
