<template>
    <v-row justify="center">
        <v-col 
        cols="11">
            <v-row justify="center">
                <v-col 
                    cols="6"
                    sm="5"
                    md="3"
                    lg="3"
                    xl="3">    
                    <v-img
                        src="../assets/logos/EsamLatam-text-black.png"
                        width="100%"
                        aspect-ratio="1"
                    >
                    </v-img>
                    <v-row>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-col
                                cols="3"
                                sm="4"
                                md="2"
                                lg="2"
                                xl="2">
                                    <v-icon 
                                        class="text-size-icon text-001C29 linkedin" 
                                        v-on:click="href('https://www.linkedin.com/in/esam-latam-b1b669241/')" >mdi-linkedin</v-icon>
                                </v-col>
                                <v-col
                                cols="3"
                                sm="4"
                                md="2"
                                lg="2"
                                xl="2">
                                    <v-icon 
                                        class="text-size-icon text-001C29 instragram"
                                        v-on:click="href('https://www.instagram.com/esam_latam/')" >mdi-instagram</v-icon>
                                </v-col>
                                <v-col
                                cols="3"
                                sm="4"
                                md="2"
                                lg="2"
                                xl="2">
                                    <v-icon 
                                        class="text-size-icon text-001C29 facebook"
                                        v-on:click="href('https://www.facebook.com/EsamLatam')">mdi-facebook</v-icon>
                                </v-col>
                                <v-col
                                cols="3"
                                sm="4"
                                md="2"
                                lg="2"
                                xl="2">
                                    <v-icon 
                                        class="text-size-icon text-001C29 youtube"
                                        v-on:click="href('https://www.youtube.com/channel/UCFfn1S109bl3ySZm_sdevfA')">mdi-youtube</v-icon>
                                </v-col>
                                <v-col
                                cols="3"
                                sm="4"
                                md="2"
                                lg="2"
                                xl="2">
                                    <v-icon 
                                        class="text-size-icon text-001C29 twitter"
                                        v-on:click="href('https://twitter.com/EsamLatam')">mdi-twitter</v-icon>
                                </v-col>
                                <v-col
                                cols="3"
                                sm="4"
                                md="2"
                                lg="2"
                                xl="2">
                                    <v-img
                                        src="../assets/logos/tik-tok-001C29.png"
                                        :width="width_tiktok"
                                        aspect-ratio="1"
                                        v-on:click="href('https://vm.tiktok.com/ZMNXKVYRd/')"
                                        class="tiktok imagen-tiktok"
                                    >
                                    </v-img>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    cols="3"
                    v-if="!ifcol">
                    <v-list >
                    <v-list-subheader class="text-keep-calm-font">ESAM LATAM</v-list-subheader>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text">CONTACTO</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text">NEWS & MEDIA</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text">BLOG</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text"  >RESPONSABILIDAD SOCIAL</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text"
                        v-on:click="this.$router.push('/Partners') && scrollL()">
                            PARTNERS Y CONVENIOS
                        </v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-col>
                <v-col
                    cols="3"
                    v-if="!ifcol">
                    <v-list >
                    <v-list-subheader class="text-keep-calm-font">RECURSOS</v-list-subheader>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text">ESTUDIANTES</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text">DOCENTES</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text">REVISTA CIENTÍFICA</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text">BIBLIOTECA VIRTUAL</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text">ALUMNI</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text" v-on:click="href('https://esamtalentum.com/')">ÚNETE AL EQUIPO</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-col>
                <v-col
                    cols="3"
                    v-if="!ifcol">
                    <v-list >
                    <v-list-subheader class="text-keep-calm-font">AVISO LEGAL</v-list-subheader>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text" 
                        v-on:click="
                        this.$router.push('/Politicas-privacidad') && scrollL()" >
                        POLÍTICA DE PRIVACIDAD
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="padding-v-list-item">
                        <v-list-item-avatar start class="avtar-text">
                            <v-icon class="text-F20780 text-size-icon">mdi-chevron-right</v-icon>
                        </v-list-item-avatar> 
                        <v-list-item-title class="text-001C29 text-keep-calm-font text-0-8vw bn-text"
                        v-on:click="
                        this.$router.push('/Politicas-Cookies') && scrollL()">
                            POLÍTICA DE COOKIES
                        </v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-col>
                <v-col
                    cols="6"
                    v-if="ifcol">
                    <v-menu location="center">
                        <template v-slot:activator="{ props }">
                        <v-btn
                            class="text-001C29 text-keep-calm-font"
                            v-bind="props"
                        >
                            ESAM LATAM<v-icon>mdi-menu</v-icon>
                        </v-btn>
                        </template>
                        <v-list>
                        
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >ESAM LATAM</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >CONTACTO</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >NEWS & MEDIA</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >BLOG</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >RESPONSABILIDAD SOCIAL</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular"
                            v-on:click="this.$router.push('/Partners') && scrollL()">
                                <v-list-item-title  >PARTNERS Y CONVENIOS</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                          <v-menu transition="slide-x-transition" class="loat-right">
                        <template v-slot:activator="{ props }">
                        <v-btn
                            class="text-001C29 text-keep-calm-font"
                            v-bind="props"
                        >
                            RESCURSOS<v-icon>mdi-menu</v-icon>
                        </v-btn>
                        </template>
                        <v-list>
                        
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >RECURSOS</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >ESTUDIANTES</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >DOCENTES</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >REVISTA CIENTÍFICA</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >BIBLIOTECA VIRTUAL</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >ALUMNI</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular" v-on:click="href('https://esamtalentum.com/')">
                            <v-list-item-title  >ÚNETE AL EQUIPO</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                          <v-menu transition="slide-x-transition" class="loat-right" >
                        <template v-slot:activator="{ props }">
                        <v-btn
                            class="text-001C29 text-keep-calm-font"
                            v-bind="props"
                        >
                            POLÍTICAS<v-icon>mdi-menu</v-icon>
                        </v-btn>
                        </template>
                        <v-list>
                        
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular"
                            v-on:click="
                            this.$router.push('/Politicas-privacidad') && scrollL()">
                                <v-list-item-title  >POLÍTICA DE PRIVACIDAD</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular"
                            v-on:click="
                            this.$router.push('/Politicas-Cookies') && scrollL()">
                                <v-list-item-title  >POLÍTICA DE COOKIES</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        <v-list-item class="padding-v-list-item">
                            <v-btn variant="text" class="text-MontserratAlternates-Regular">
                            <v-list-item-title  >AVISO LEGAL</v-list-item-title>
                            </v-btn>
                        </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>


export default {
  name: 'EsamContenido',
  props: {
    id: Number,
  },
  components : {
  },
 methods: { 
   href(href){
    window.open(href);
   }
   
 },
  computed: {
    ifcol(){
      
      if (screen.width > 0 && screen.width <= 960) {
        return true;
      }
      return false;
    },
    width_tiktok(){
      if (screen.width > 0 && screen.width <= 600) {
        return "100%";
      }
    if (screen.width > 601  && screen.width <=  960)
    {
        return "55%";
    }
    if (screen.width > 961  && screen.width <=  1264)
    {
        return "100%";
    }
    if (screen.width > 1265  && screen.width <=  1904)
    {
        return "80%";
    }

      return "50%";
    }
  },
  data(){
    return {
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.linkedin{
    cursor: pointer;
    color: inherit;
}
.linkedin:hover {
	color: #0077b7; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.instragram{
    cursor: pointer;
    color: inherit;
}
.instragram:hover {
	color: #b31373;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.facebook{
    cursor: pointer;
    color: inherit;
}
.facebook:hover {
	color: #0037c1;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.youtube{
    cursor: pointer;
    color: inherit;
}
.youtube:hover {
	color: #ff0000;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.twitter{
    cursor: pointer;
    color: inherit;
}
.twitter:hover {
	color: #1d9bf0;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
.tiktok{
  cursor: pointer;
    color: inherit;
}
.tiktok:hover {
  content:url("../assets/logos/tik-tok.png");
	transform: translateY(-7px);
}
.bn-text{
    cursor: pointer;
    color: inherit;
}
.bn-text:hover {
	color: #F20780;
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-5px);
}

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.text-0-8vw{
   font-size: 0.8vw
}
.text-size-icon{
   font-size: 6vw
}
.avtar-text{ 
    -webkit-margin-end: 2px; 
    margin-inline-end: 2px;
}
.padding-v-list-item{
    padding: 0px;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
/** fin Banner */
    .imagen-tiktok{
        width: 60px;
    }
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 

.text-size-icon{
   font-size: 2vw
}
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 

.text-size-icon{
   font-size: 1.9vw
}
 }
@media (min-width: 1905px)  { 
.text-size-icon{
   font-size: 1.5vw
}

 }



</style>
