<template>

<div class="ma-5 pa-5 text-white">
  <div :v-model="getheight()">
    <div v-if="sm1===1">
    <v-row >
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="1"
        offset-md="1"
        offset-lg="1"
        offset-xl="1"
      >
        <v-img
            src="../assets/maestrias/cuadros-3_01.jpg"
            aspect-ratio="1"
            cover
            class="img-doctorado cursor"
            href="#posgrado" v-on:click="enviarId(1)"
        >
            <!--<div  class="padding-doctorado"> 
                <span class="doct-title  text-keep-calm-font bordes-negro">DIPLOMADOS</span>
            </div>-->
        
        </v-img>
      </v-col>
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
      >
        <v-img
            src="../assets/maestrias/cuadros-3_02.jpg"
            aspect-ratio="1"
            cover
            class="img-maestria cursor"
            href="#posgrado" v-on:click="enviarId(2)"
        >
        <!--<div class="padding-maestria">
            <span class="doct-title  text-keep-calm-font bordes-negro">MAESTRÍAS</span>
        </div>-->
        </v-img>
      </v-col>
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
      >
        <v-img
            src="../assets/maestrias/cuadros-3_03.jpg"
            aspect-ratio="1"
            cover
            class="img-especialidad cursor"
            href="#posgrado" v-on:click="enviarId(3)"
        >
            <!--<div class="padding-especialidades">
                <span class="doct-title  text-keep-calm-font bordes-negro">DOCTORADOS</span>
            </div>-->
        </v-img>
      </v-col>
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
      >
        <v-img
            src="../assets/maestrias/cuadros-3_04.jpg"
            aspect-ratio="1"
            cover
            class="img-diplomado cursor"
            href="#posgrado" v-on:click="enviarId(5)"
        >
            <!--<div class="padding-diplomado d-flex justify-center">
                <span class="doct-title  text-keep-calm-font text-center bordes-negro">PROGRAMAS SENIOR & EJECUTIVO </span>
            </div>-->
        </v-img>
      </v-col>
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
      >
        <v-img
            src="../assets/maestrias/cuadros-3_05.jpg"
            aspect-ratio="1"
            cover
            class="img-curso cursor"
            v-on:click="enviarId(6)"
        >
            <!--<div class="padding-cursos d-flex justify-center">
                <span class="doct-title  text-keep-calm-font text-center bordes-negro">ESPECIALIDADES Y OTROS CURSOS </span>
            </div>-->
        </v-img>
      </v-col>
    </v-row>
    </div>


    <div v-if="xs1===1">
    <v-row >
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="1"
        offset-md="1"
        offset-lg="1"
        offset-xl="1"
      >
        <v-img
            src="../assets/maestrias/cuadros.jpg"
            aspect-ratio="1"
            width="100%"
            height = 40px
            class="cursor"
            href="#posgrado" v-on:click="enviarId(1)"
        >
            <div  class="d-flex justify-center padding-doctorado"> 
                <p class="doct-title  text-keep-calm-font bordes-negro text-center">DIPLOMADOS</p>
            </div>
        
        </v-img>
      </v-col>
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
      >
        <v-img
            src="../assets/maestrias/cuadros.jpg"
            aspect-ratio="1"
            width="100%"
            height = 40px
            class="cursor"
            href="#posgrado" v-on:click="enviarId(2)"
        >
        <div class="d-flex justify-center padding-maestria">
            <span class="doct-title  text-keep-calm-font bordes-negro ">MAESTRÍAS</span>
        </div>
        </v-img>
      </v-col>
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
      >
        <v-img
            src="../assets/maestrias/cuadros.jpg"
            aspect-ratio="1"
            width="100%"
            height = 40px
            class="cursor"
            href="#posgrado" v-on:click="enviarId(3)"
        >
            <div class="d-flex justify-center padding-especialidades">
                <span class="doct-title  text-keep-calm-font  bordes-negro">DOCTORADOS</span>
            </div>
        </v-img>
      </v-col>
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
      >
        <v-img
            src="../assets/maestrias/cuadros.jpg"
            aspect-ratio="1"
            width="100%"
            height = 40px
            class="cursor"
            href="#posgrado" v-on:click="enviarId(5)"
        >
            <div class="d-flex justify-center padding-diplomado">
                <span class="doct-title  text-keep-calm-font  bordes-negro">ESPECIALIDADES</span>
            </div>
        </v-img>
      </v-col>
      <v-col
        cols="10"
        sm="2"
        md="2"
        lg="2"
        xl="2"
        offset="1"
        offset-sm="0"
        offset-md="0"
        offset-lg="0"
        offset-xl="0"
      >
        <v-img
            src="../assets/maestrias/cuadros.jpg"
            aspect-ratio="1"
            width="100%"
            height = 40px
            class="cursor"
            v-on:click="enviarId(6)"
        >
            <div class="d-flex justify-center padding-cursos ">
                <span class="doct-title  text-keep-calm-font text-center bordes-negro">CURSOS</span>
            </div>
        </v-img>
      </v-col>
    </v-row>
    </div>
    </div>
</div>
</template>

<script>
import gql from "graphql-tag"

export default {
  name: 'EsamTarjeta',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: { 
   getheight(){
      
        if (screen.width > 0 && screen.width <= 600) {
            this.xs1 = 1;
            this.sm1 = 0;
        }
        if (screen.width > 600 && screen.width <= 960) {
            this.xs1 = 0;
            this.sm1 = 1;
        }
        if (screen.width > 960 && screen.width <= 1264) {
            this.xs1 = 0;
            this.sm1 = 1;
        }
        if (screen.width > 1264 && screen.width <= 1904) {
            this.xs1 = 0;
            this.sm1 = 1;
        }
        if (screen.width > 1904) {
            this.xs1 = 0;
            this.sm1 = 1;
        }
     },
   enviarId(id){
     this.id_cat=id,
     this.$emit("id_categoria",id),
     this.$emit("activar",true)
    },
   getProgramas(categoria1){
     
     var q=gql`query {
              programas(por_fecha:false,latest:  false,iduniversidad:128,
              categoria: ${categoria1},gestion:2022,unidad_negocio:1
              ){
                id,nombre_compuesto, version,grupo,fecha_inicio,modalidad,portada,
    						postgrado{nombre,id},sede{nombre},
    						universidad{nombre}
              }
          }`;
      this.$apollo.query({
        query: q,
          variables : {
            categoria : 1
          }
      }).then((data) => {
          //this.programas=data.data.programas;
          //this.setPrograma=data.data.programas;
          this.programas=data.data.programas;
          
          window.console.error("ASIG: ",this.programas);
      }).catch((error) => {
          window.console.error(error)
      });
     
    },
 },
  data(){
    return {
      carouselheight: 0,
      programas : {},
      xs1 : false,
      sm1 : false,
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>
.bordes-negro{
text-shadow: -1px -1px 0.2px #333, 0.5px -1px 0.2px #333;
}
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.cursor{
 cursor: pointer;
    color: inherit;
}
.cursor:hover {
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
} 
.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}
.background-00437a{
  background :#00437a;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-white{
  background-color: white;
}
.background-1C759F{
  background-color: #1C759F;
}
.background-bc0000{
  background : #bc0000
}
.background-0b7226{
  background : #0b7226
}



/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
 

.doct-title{
     font-size: 4vw;
}
    .padding-doctorado{
    padding-bottom: 0%;
    padding-top: 3%;
}
.padding-maestria{
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-especialidades{
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-diplomado{
    padding-bottom: 0%;
    padding-top: 3%
}
.padding-cursos{
    padding-bottom: 0%;
    padding-top: 3%
} 
.img-doctorado{
    content:url("../assets/maestrias/1.webp");
}

.img-maestria{
    content:url("../assets/maestrias/2.webp");
}

.img-especialidad{
    content:url("../assets/maestrias/3.webp");
}

.img-diplomado{
    content:url("../assets/maestrias/4.webp");
}

.img-curso{
    content:url("../assets/maestrias/1.webp");
}

 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
   /** sive todo */

.doct-title{
     font-size: 1vw;
}
    .padding-doctorado{
    padding-left: 20%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 35%
}
.padding-maestria{
    padding-left: 26%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 35%
}
.padding-especialidades{
    padding-left: 15%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 35%
}
.padding-diplomado{
    padding-left: 6%;
    padding-right: 6%;
    padding-bottom: 0%;
    padding-top: 37%;
}
.padding-cursos{
    padding-left: 14%;
    padding-right: 14%;
    padding-bottom: 0%;
    padding-top: 36%;
} 
}
/** Tablet Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
   /** sive todo */

.doct-title{
     font-size: 1vw;
}
    .padding-doctorado{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 41%
}
.padding-maestria{
    padding-left: 30%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 41%
}
.padding-especialidades{
    padding-left: 19%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 41%
}
.padding-diplomado{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 36%;
}
.padding-cursos{
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 0%;
    padding-top: 36%;
} 
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
 .doct-title{
     font-size: 1.4vw;
}
    .padding-doctorado{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 41%
}
.padding-maestria{
    padding-left: 30%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 41%
}
.padding-especialidades{
    padding-left: 19%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 41%
}
.padding-diplomado{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 35%;
}
.padding-cursos{
    padding-left: 16%;
    padding-right: 16%;
    padding-bottom: 0%;
    padding-top: 35%;
}
 }
@media (min-width: 1905px)  { 
    .doct-title{
     font-size: 1vw;
}
    .padding-doctorado{
    padding-left: 25%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 41%
}
.padding-maestria{
    padding-left: 30%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 41%
}
.padding-especialidades{
    padding-left: 19%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 41%
}
.padding-diplomado{
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 0%;
    padding-top: 35%;
}
.padding-cursos{
    padding-left: 16%;
    padding-right: 16%;
    padding-bottom: 0%;
    padding-top: 35%;
}
 }



</style>
