<template>
  <div class="hello" :v-model="getProgramas(id_categoria,condicion)">
    <div class="row" v-if="condicion" >
      <div class=" row margin-20" id="prosgrados" v-if="conposgrado" >
        
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            >
              <v-btn variant="text" class="background-F20780 text-white" v-on:click="getcondi (),changeFocus ('posgrado')" icon="mdi-close-circle"></v-btn>
          </v-col>
            <v-col
            cols="12"
            sm="7"
            md="7"
            lg="7"
            xl="7"
            >
                <h2 class="text-center text-001C29 text-KeepCalm-Medium">{{posgrado.nombre}}</h2>
                <h4 class="text-001C29 text-KeepCalm-Heavy">OBJETIVO</h4>
                <span class="text-001C29 text-KeepCalm-Book">{{posgrado.objetivo}}</span>
                <h4 class="text-001C29 text-KeepCalm-Heavy">Duración: {{posgrado.duracion}} meses</h4>
                <h4 class="text-001C29 text-KeepCalm-Heavy">{{posgrado.carga_horaria}} horas academicas</h4>
                <h4 class="text-001C29 text-KeepCalm-Heavy">Inicio: {{fecha}}</h4>
                <h4 class="text-001C29 text-KeepCalm-Heavy">DIRIGIDO A</h4>
                <span class="text-001C29 text-KeepCalm-Book">{{posgrado.dirigido}}</span>
            </v-col>
            <v-col
            cols="12"
            sm="5"
            md="5"
            lg="5"
            xl="5"
            >
              <v-img
                  v-bind:src="imagen"
                  aspect-ratio="1"
                  width="100%"
              >
              </v-img>
            </v-col>
            <v-col
            cols="12"
            class="d-flex justify-center"
            >
              <v-btn
                color="success"
                @click="formSuscripcion()"
                size="x-large"
              >
                  INSCRIBIRME <v-icon>mdi-account-plus</v-icon>
              </v-btn>
              <v-btn
                class="background-F59C1B text-white "
                v-on:click="formInformacion(posgrado)"
              >
                  + información <v-icon>mdi-information-outline</v-icon>
              </v-btn>
            </v-col>
        </v-row>

      </div>
      <div class=" margin-20 " id="programa"  v-if="!conposgrado">
        <v-row>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            >
              <v-btn variant="text" class="background-F20780 text-white"  v-on:click="setposgrado(),changeFocus ('inicio')" icon="mdi-close-circle"></v-btn>
          </v-col>
          
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            v-if="programas.length==0 && !progress"
             class="text-center"
            >
            <h4 class="text-001C29 text-KeepCalm-Heavy">ESTAMOS TRABAJANDO PARA OPTIMIZAR ESTA SECCIÓN. GRACIAS POR TU PACIENCIA</h4>
          </v-col> 
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            v-if="progress"
            >
            <div class="text-center">
              <v-progress-circular
                :size="100"
                :rotate="360"
                :width="20"
                color="amber"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-col>
          <template v-for="(prog, index) in programas" v-bind:key="index" >
          
          <v-col  
          cols="12"
          sm="4"
          md="3"
          lg="3"
          xl="3"
          v-if="prog.id !=1873">
            <div v-if="index === con"  :v-model="getFecha(prog.fecha_inicio,prog.modalidad)"></div>
            <v-card 
            >
            <v-row >
              <v-col
                cols="8"
                offset="2"
              >
             
                <v-img
                    v-bind:src="'https://esam.edu.bo/webdata/portadas/'+prog.portada"
                    width="100%"
                    aspect-ratio="1"
                    v-on:click="getPosgrado(prog.postgrado.id) , getimagen(prog.portada),changeFocus ('programa')"
                    class="cursor"
                >
                </v-img>
              </v-col>
            </v-row>

              <v-card-title class="d-flex justify-center">
                <h6 class=" text-001C29 text-KeepCalm-Heavy text-ta text-center">{{prog.postgrado.nombre}}</h6>
              </v-card-title>

              <v-card-subtitle class="d-flex justify-center">
                <p class="text-001C29 text-KeepCalm-Book text-justify text-tan " >{{modalidad}}</p>
                <p class="text-001C29 text-KeepCalm-Book text-justify text-tan " >{{fecha}}</p>
              </v-card-subtitle>
            </v-card>
          </v-col>
          </template>
        </v-row>
      </div>
      <v-dialog
        v-model="dialogSuscripcion"
        persistent
      >
          <v-card width="100%">
            <v-form>
            <v-toolbar
              color="success"
            >Suscripcion</v-toolbar>
            <v-card-text>
                  <v-row>
                    <input v-model="suscripcion.id" hidden/>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
                    <v-text-field
                      label="Nombre"
                      required
                      v-model="suscripcion.nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
                    <v-text-field
                      label="Apellido"
                      v-model="suscripcion.apellido"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
                    <v-text-field
                      label="Celular"
                      required
                      v-model="suscripcion.celular"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
                    <v-text-field
                      label="Correo"
                      required
                      v-model="suscripcion.correo"
                    ></v-text-field>
                  </v-col>
                  
                </v-row>
              <!--<div class="text-h2 pa-12">Hello world!</div>-->
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                href="#prosgrados"
                @click="dialogSuscripcion=false"
              >Cancelar</v-btn>
              <v-btn
                variant="text"
                href="#prosgrados"
                @click="nueva_suscripcion()" 
              >Guardar</v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogInformacion"
        persistent
      >
          <v-card width="100%">
            <v-form>
            <v-toolbar 
              class="background-F59C1B text-white "
            >Solicita informacion </v-toolbar>
            <v-card-title >
              <h1 class="text-center">Curso interesado </h1>
            </v-card-title>
            <v-card-title>
              <h3 class="text-center">{{informacion.nombre_posgrado}}</h3>
            </v-card-title>
            <v-card-text>
                  <v-row>
                    <input v-model="informacion.id" hidden/>
                    <input v-model="informacion.id_posgrado" hidden/>
                    <input v-model="informacion.nombre_posgrado" hidden/>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
                    <v-text-field
                      label="Nombre"
                      required
                      v-model="informacion.nombre"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
                    <v-text-field
                      label="Apellido"
                      v-model="informacion.apellido"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
                    <v-text-field
                      label="Celular"
                      required
                      v-model="informacion.celular"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                  >
                    <v-text-field
                      label="Correo"
                      required
                      v-model="informacion.correo"
                    ></v-text-field>
                  </v-col>
                  
                </v-row>
              <!--<div class="text-h2 pa-12">Hello world!</div>-->
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                href="#prosgrados"
                @click="dialogInformacion=false"
              >Cancelar</v-btn>
              <v-btn
                variant="text"
                href="#prosgrados"
                @click="nueva_informacion()" 
              >Guardar</v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
      </v-dialog>
      
      
    </div>
    
  </div>

</template>

<script>
//import M from 'materialize-css'
import gql from "graphql-tag"
import axios from 'axios'
var url_suscripcion ='http://localhost/backend-esamlatam/crud-suscripcion.php';
var url_informacion ='http://localhost/backend-esamlatam/crud-informacion.php';

export default {
  name: 'EsamPrograma',
  props: {
    id_categoria: Number,
    condicion : Boolean,
  },
  components : {
  },
 methods: {
  
    changeFocus (hash) {
     window.location.hash = hash;
  },
   // metodos para suscripciones  
  formSuscripcion(){
    this.dialogSuscripcion=true;
    this.suscripcion.nombre = "";
    this.suscripcion.apellido = "";
    this.suscripcion.celular = "";
    this.suscripcion.correo = "";
   },
  nueva_suscripcion (){
    axios.post(url_suscripcion, {opcion:2, nombre:this.suscripcion.nombre, apellido:this.suscripcion.apellido, celular:this.suscripcion.celular,correo: this.suscripcion.correo })
      .then(response =>{
        response;
      });  
    
    this.suscripcion.nombre = "";
    this.suscripcion.apellido = "";
    this.suscripcion.celular = "";
    this.suscripcion.correo = ""; 
    this.dialogSuscripcion=false;
  },
  //metodos de informacion 
  formInformacion(posgrado1){
    this.informacion.id_posgrado = posgrado1.id;
    this.informacion.nombre_posgrado = posgrado1.nombre;
    this.dialogInformacion=true;
    this.informacion.nombre = "";
    this.informacion.apellido = "";
    this.informacion.celular = "";
    this.informacion.correo = "";
   },
  nueva_informacion (){
    axios.post(url_informacion, {opcion:2, id_posgrado : this.informacion.id_posgrado, nombre_posgrado : this.informacion.nombre_posgrado,
    nombre:this.informacion.nombre, apellido:this.informacion.apellido, celular:this.informacion.celular,correo: this.informacion.correo })
      .then(response =>{
        response;
      });  
    this.informacion.id_posgrado = "";
    this.informacion.nombre_posgrado = "";
    this.informacion.nombre = "";
    this.informacion.apellido = "";
    this.informacion.celular = "";
    this.informacion.correo = "";
    this.dialogInformacion=false;
  },
   getFecha(fec,moda){
      this.con++;
    //window.console.log("get posgrado  = "+this.i++ +" fechas =  sssss" +this.con);
     
     fec=parseInt(fec);
    this.fecha =new Date(fec).toLocaleDateString();
    if(moda=="V"){
      this.modalidad="Virtual"
    }
    if(moda=="S"){
      this.modalidad="Semiprensencial"
    }
    return ;
    //window.console.log(this.fecha);
   },
   getModalidad(moda){
     
console.log("nontador "+ this.con);
    if(moda=="V"){
      this.modalidad="Virtual"
    }
    if(moda=="S"){
      this.modalidad="Semiprensencial"
    }
   },
   getcondi (){
     this.conposgrado=false;
   },
   getimagen(img){
     this.imagen='https://esam.edu.bo/webdata/portadas/'+img;
   },
   getProgramas(categoria1,c){
     
     var q=gql`query {
              programas(por_fecha:false,latest:  false,iduniversidad:128,
              categoria: ${categoria1},gestion:2022,unidad_negocio:1
              ){
                id,nombre_compuesto, version,grupo,fecha_inicio,modalidad,portada,
    						postgrado{nombre,id},sede{nombre},
    						universidad{nombre}
              }
          }`;
      this.$apollo.query({
        query: q,
          variables : {
            categoria : 1
          }
      }).then((data) => {
          //this.programas=data.data.programas;
          //this.setPrograma=data.data.programas;
        this.programas=data.data.programas;
        this.progress=false;
         // window.console.error("ASIG: ",this.setPrograma);
      }).catch((error) => {
          window.console.error(error)
      });
     if(!c){
       this.conposgrado=false;
       this.con=0;
       this.conprogra=false;
     }
     if(c){
       
      this.conprogra=true;
     }
     
    },
    setposgrado()
    {
      this.$emit("condicionp",false)

    },
   getPosgrado(id){
    
     ///this.imagen='https://esam.edu.bo/webdata/portadas/'+imagen;
     var q=gql`query {
              postgrado(id:${id}
              ){
                id,nombre, objetivo, dirigido, duracion, carga_horaria, portada
              }
          }`;
      this.$apollo.query({
        query: q
      }).then((data) => {
          //window.console.error("hola: ",data);
          this.posgrado=data.data.postgrado;
         // window.console.error("ASIG: ",this.setPrograma);
      }).catch((error) => {
          window.console.error(error)
      });
   this.conprogra=false;
     this.conposgrado=true;
    },
 },
  data(){
    return {
      programas : [],
      categorias : [],
      posgrado : [],
      progress : true,
      fecha :"",
      modalidad: "",
      imagen :"",
      conposgrado : false,
      i: 0,
      con :0 ,
      conprogra : false,
      dialogSuscripcion : false,
      suscripcion : {
        id: null,
        nombre : "",
        apellido : "",
        celular : "",
        correo : "",
      },
      dialogInformacion : false,
      informacion : {
        id: null,
        id_posgrado : null,
        nombre_posgrado : "",
        nombre : "",
        apellido : "",
        celular : "",
        correo : "",
      }
    };
  },
  mounted(){
    }

      

}
</script>

<style scoped>
.cursor{
 cursor: pointer;
    color: inherit;
}
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
.doctorado{
  background-image: url("../assets/logosEsamLatam.webp");
}

/** fin nav */
nav{
    background-color: #001C29;
}
.nav-wrapper .input-field input[type=search] {
    /* height: inherit; */
    padding-left: 4rem;
    width: calc(100% - 4rem);
    border: 0;
    box-shadow: none;
}

.input-field input[type=search] {
    /* display: block; */
    line-height: inherit;
    transition: .3s background-color;
}
/** fin nav */
/** Banner */

.banner {
    position: relative;
}
.banner .banner-image {
    position: relative;
}
/** Fin banner */
/** inicio descuento */
.descuento {
    position: relative;
}
.descuento .descuento-image {
    position: relative;
}
/** fin descuento */

.text-0-8vw{
   font-size: 0.8vw
}

.b-left {
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 5%;
    padding-bottom: 32%;
    padding-top: 0%;
}

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}
.img-nosotros-ta{
  height: 100px;
  margin: 0px;
}
.titulo-nosotros-ta{
  height: 40px;
  margin: 0px;
}
.text-nosotros-ta{
  height: 140px;
  margin: 0px;
}
.img-prog-ta{
  height: 130px;
  margin: 0px;
}
.titulo-prog-ta{
  height: 90px;
  margin: 0px;
}
.div-prog-ta{
  height: 350px;
}
.text-prog-ta{
  height: 150px;
  margin: 0px;
}
.margin-70{
  margin: 70px 0 0 -20px;
}
.margin-50{
  margin: 50px 0 0 -20px;
}
.margin-30{
  margin: 30px 0 0 -20px;
}
.margin-20{
  margin: 20px 0 0 -20px;
}
.margin-0{
  margin: 0px;
}
.div-margin{
  margin: 40px;
}
.div-ta{
  height: 300px;
  margin: 10px;
}
.div-ta0{
  height: 5px;
  margin: 0px;
}
.div-ta1{
  height: 10px;
  margin: 0px;
}
.div-ta2{
  height: 40px;
}
.div-ta3{
  height: 50px;
}
.div-ta4{
  height: 400px;
  margin: 0px;
}
.div-ta5{
  height: 700px;
  margin: 0px;
}
.div-ta6{
  height: 500px;
  margin: 0px;
}

.div-ta7{
  height: 700px;
  margin: 0px;
}

::placeholder { 
  color: #001C29; 
  padding: 15px;  
  }

.text-001C29{
  color: #001C29;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.border-001C29{
  border: #001C29;
  border-style: solid;
  border-color: green;
  border-width: 10px;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.tam-logo{
  height: 45px;
  margin: 10px 0 0 20px;
}

/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 601px) { 
  
.text-tan{
  font-size: 5vw;
}
  .text-ta{
    font-size: 3.5vw;
  }

  .p-table{
    display: none
  }
  .p-cel{
    display: block
  }
  .su-table{
    display: none;
  }
  .su-cel{
    display: block;
    padding : 25px 0 0 0;
  }
  .gmail-table{
    display: none
  }
  .gmail-cel{
    display: block
  }
  .sede-table{
    display: none
  }
  .sede-cel{
    display: block
  }
  .bi-table{
    display: none
  }
  .bi-cel{
    display: block
  }
  .t-table{
    display: none
  }
  .t-cel{
    display: block
  }
  .e-table{
    display: none
  }
  .e-cel{
    display: block
  }
  .e-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .e-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.e-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
  .b-right{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 2%;
    padding-bottom: 32%;
    padding-top: 33%;
  }
  .b-left{
    position: absolute;
    cursor: pointer;
    padding-right: 19%;
    padding-left: 70%;
    padding-bottom: 32%;
    padding-top: 33%;
  }
  .t-carousel{
    height: 270px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .t-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.t-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 270px;
    position: absolute;
    top: 0;
    left: 0;
}
  .bi-carousel{
    height: 484px;    
    overflow: hidden;
    position: relative;
    width: 100%;
    perspective: 500px;
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
  }
  .bi-carousel.carousel-slider {
    top: 0;
    left: 0;
}
.bi-carousel.carousel-slider .carousel-item {
    width: 100%;
    height: 100%;
    min-height: 400px;
    position: absolute;
    top: 0;
    left: 0;
}
  .align-n{
    text-align: center;
  }
   /** inicio descuento */
  .text-descuento-30p{
    font-size: 12vw
  }
  .text-descuento-title{
    font-size: 5vw
  }
  .text-descuento-title1{
    font-size: 3vw
  }
  /*.descuento .descuento-title{
      font-size: 1vw;
  }*/
  .descuento .descuento-image .descuento-title {
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      /*padding-top: 80%;*/
      padding-right: 10%;
      padding-left: 40%;
      padding-bottom: 10%;
  }
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 65px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 18vw
  }
  .text-concluido-titulo{
    font-size: 4vw
  }
/** fin de cuso concluido */
/** inicio de estudiar con nosotros */
  .text-estudiar-tan{
    font-size: 6vw
  }
/** fin de estuar con nosotros  */
/** inicio de asociados */
  .text-asociados-tan{
    font-size: 8vw
  }
/** fin de asociados  */
/** inicio de logros */
  .text-logros-tan{
    font-size: 7vw
  }
/** fin de logros  */
/** Banner */
/*.banner-titulo{
     font-size: 10vw;
}*/
.title-futuro{
  font-size: 5vw;
}
.text-descrip{
  display: none
}
.title-inge{
  font-size: 4vw;
}
.margin-ing{
  margin: 8px;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 6px;
    display: inline-block;
    height: 13px;
    line-height: 16px;
    /* padding: 0px 20px; */
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 10%;
    padding-right: 0%;
}
.padding-banner1{
    padding-left: 32%;
    padding-bottom: 80%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 77%;
}
/** fin Banner */
/** maestrias */
  .table{
    display: none
  }
  .cel{
    display: block
  }
  /** fin maestrias */
  /** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 6vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 2%;
}
.padding-doctorado{
    padding-left: 20%;
}
.padding-maestria{
    padding-left: 25%;
}
.padding-especialidades{
    padding-left: 11%;
}
.padding-diplomado{
    padding-left: 20%;
}
.padding-cursos{
    padding-left: 30%;
}
/** fin Doctorados */
/* Incio de nemu*/
.nav .brand-logo {
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;
}

/* Fin de nemu*/
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
    .text-tan{
  font-size: 2vw;
}
  .text-ta{
    font-size: 1.5vw;
  }
  .p-table{
    display: block
  }
  .p-cel{
    display: none;
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display : none;
    
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: block
  }
  .e-cel{
    display: none
  }
  .tiltle-content-tan{
    font-size: 3vw;
  }
  .padding-content{
    /*padding: 10px 20px;
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 10px;
    padding-left: 20px;*/
    padding-left: 0px;
  }
  .collection.with-header .collection-item {
    padding-left: 0px;
  }
   /** inicio descuento */
  .text-descuento-30p{
    font-size: 12vw
  }
  .text-descuento-title{
    font-size: 5vw
  }
  .text-descuento-title1{
    font-size: 3vw
  }

  /*.descuento .descuento-title{
      font-size: 1vw;
  }*/
  .descuento .descuento-image .descuento-title {
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding-top: 80%;
      padding-right: 10%;
      padding-left: 47%;
      padding-bottom: 15%;
  }
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 25px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 80px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 70px;
    margin: 0px;
  }
  /** fin Bilotecas masterclass */
  /** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 18vw
  }
  .text-concluido-titulo{
    font-size: 5vw
  }
  .text-concluido-titulo1{
    font-size: 5vw
  }
  .text-concluido-conte{
    font-size: 1.6vw
  }
/** fin de cuso concluido */
  
/** inicio de estudiar con nosotros */
  .text-estudiar-tan{
    font-size: 3.3vw
  }
/** fin de estuar con nosotros  */
/** inicio de asociados */
  .text-asociados-tan{
    font-size: 3vw
  }
/** fin de asociados  */
/** inicio de logros */
  .text-logros-tan{
    font-size: 3vw
  }
/** fin de logros  */
/** Banner */

/*.banner .banner-title{
     font-size: 1vw;
}*/
.banner-tittulo{
     font-size: 6vw;
}

.title-inge{
  font-size: 3vw;
}
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 10%;
}
.padding-banner1{
    padding-left: 25%;
    padding-bottom: 20%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 20%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 
     .text-tan{
  font-size: 1.5vw;
}
  .text-ta{
    font-size: 1.1vw;
  }
  .p-table{
    display: block
  }
  .p-cel{
    display: none
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display: none
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: block
  }
  .e-cel{
    display: none
  }
  /** inicio descuento */
.text-descuento-30p{
   font-size: 12vw
}
/*.descuento .descuento-title{
     font-size: 1vw;
}*/
.descuento .descuento-image .descuento-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 80%;
    padding-right: 10%;
    padding-left: 52%;
    padding-bottom: 20%;
}
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 60px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 12vw
  }
/** fin de cuso concluido */
/** Banner */

/*.banner .banner-title{
     font-size: 1vw;
}*/
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 35%;
}
.padding-banner1{
    padding-left: 35%;
    padding-bottom: 5%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 6%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
 }

/** Large Desktop Devices */
@media  (min-width: 1265px) and (max-width: 1904px)  { 
 .text-tan{
  font-size: 1.3vw;
}
  .text-ta{
    font-size: 1vw;
  }  
  .p-table{
    display: block
  }
  .p-cel{
    display: none
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display: none
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: block
  }
  .e-cel{
    display: none
  }
   /** inicio descuento */
.text-descuento-30p{
   font-size: 12vw
}
/*.descuento .descuento-title{
     font-size: 1vw;
}*/
.descuento .descuento-image .descuento-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 80%;
    padding-right: 10%;
    padding-left: 52%;
    padding-bottom: 20%;
}
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 60px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 12vw
  }
/** fin de cuso concluido */
/** Banner */
/*.banner .banner-title{
     font-size: 1vw;
}*/
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 35%;
}
.padding-banner1{
    padding-left: 35%;
    padding-bottom: 5%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 6%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
 }
 @media  (min-width: 1905px)   { 
 .text-tan{
  font-size: 1.1vw;
}
  .text-ta{
    font-size: 1.0vw;
  } 
  .p-table{
    display: block
  }
  .p-cel{
    display: none
  }
  .su-table{
    display: block;
    padding : 25px 0 0 0;
  }
  .su-cel{
    display: none
  }
  .gmail-table{
    display: block
  }
  .gmail-cel{
    display: none
  }
  .sede-table{
    display: block
  }
  .sede-cel{
    display: none
  }
  .bi-table{
    display: block
  }
  .bi-cel{
    display: none
  }
  .t-table{
    display: block
  }
  .t-cel{
    display: none
  }
  .e-table{
    display: block
  }
  .e-cel{
    display: none
  }
   /** inicio descuento */
.text-descuento-30p{
   font-size: 12vw
}
/*.descuento .descuento-title{
     font-size: 1vw;
}*/
.descuento .descuento-image .descuento-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 80%;
    padding-right: 10%;
    padding-left: 52%;
    padding-bottom: 20%;
}
/** fin descuento */
/** Contenido sedes */
  .text-content-sedes-ta{
    height: 60px;
  }
/** Fin descuento sedes */
/** Bilotecas masterclass */

  .img-noticia-ta{
    height: 120px;
    margin: 70px 0 0 -20px;
  }
  .text-titulo-noticia-ta{
    height: 110px;
    margin: 0px;
  }
  .text-content-noticia-ta{
    height: 100px;
    margin: 0px;
  }
  
/** fin Bilotecas masterclass */
/** incio de cuso concluido */
  .text-concluido-2000{
    font-size: 12vw
  }
/** fin de cuso concluido */
/** Banner */
/*.banner .banner-title{
     font-size: 1vw;
}*/
.banner .banner-image .banner-title {
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 0%;
    padding-right: 35%;
}
.padding-banner1{
    padding-left: 35%;
    padding-bottom: 5%;
}
.padding-banner2{
    padding-left: 10%;
    padding-bottom: 6%;
}
.btn1, .btn-large, .btn-small, .btn-flat {
    border: none;
    border-radius: 2px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    text-transform: uppercase;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
/** fin Banner */
/** Doctorados */
.doct {
    position: relative;
}
.doct .doct-image {
    position: relative;
}
.doct .doct-title{
     font-size: 1vw;
}
.doct .doct-image .doct-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 100%;
    padding-top: 50%;
    padding-right: 50%;
    padding-bottom: 50%;
}
.padding-doctorado{
    padding-left: 25%;
}
.padding-maestria{
    padding-left: 28%;
}
.padding-especialidades{
    padding-left: 16%;
}
.padding-diplomado{
    padding-left: 26%;
}
.padding-cursos{
    padding-left: 32%;
}
/** fin Doctorados */
 }



</style>
