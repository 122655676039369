import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import NavbarEsamView from '../views/NavbarEsamView.vue'
import Prueba from '../views/prueba.vue'
import ProgramaView from '@/components/Programa'
import config from '@/components/Configuracion'
import Posgrado from '@/components/Posgrado.vue'
import Login from '@/components/Login.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: NavbarEsamView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/Posgrado',
    name: 'Posgrado',
    component : Posgrado
  },
  {
    path: '/homes',
    name: 'homes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component : ProgramaView
  },
  {
    path: '/config',
    name: 'config',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component : config
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component : Login
  },
  {
    path: '/Prueba',
    name: 'Prueba',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component : Prueba
  },
  {
    path: '/Politicas-privacidad',
    name: 'politicas-de-privacidad',
    component: () => import(/* webpackChunkName: "Politicas" */ '../views/Politicas/PoliticasView.vue')
  },
  {
    path: '/Politicas-Cookies',
    name: 'politicas-de-cookies',
    component: () => import(/* webpackChunkName: "Cookies" */ '../views/Politicas/PoliticasCookiesView.vue')
  },
  {
    path: '/Partners',
    name: 'Partnes-convenios',
    component: () => import(/* webpackChunkName: "Partnes y convenios" */ '../views/PartnesView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
