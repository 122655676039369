<template>
<div v-for="(cat, index) in areas" v-bind:key="index">
  <v-row :style="'background-color : rgb('+cat.color+')'" justify="center" class="py-5" v-if="1==0">
    <v-col cols="10">
      <v-row>
        <v-col cols="7" class=" d-flex">        
          <div
              class="d-flex"
            >
              <h3 class="align-self-center">Invita a tus colegas y ahorra un 10% en tu matrícula</h3>
          </div>  
        </v-col>
        <v-col cols="5">    
          <div class="d-flex justify-end">   
              <v-btn
              :rounded="0"
              color="white"
              class="text-black"
              height="50"
              >
              INVITAR AHORA
              </v-btn>
          </div> 
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</div>
<div v-if="1 == 0">
  <v-row class="py-5">
    <v-col cols="1">
      <v-img
        src="../../assets/logos/EsamLatam-text-black.png"                          
        >
        </v-img> 
    </v-col>
    <v-col cols="11" v-for="(cat, index) in areas" v-bind:key="index" class="d-flex">      
      <v-sheet
          class="d-flex"
        >
          <v-sheet  :style="'color : rgb('+cat.color+')'" class="align-self-center">Oline</v-sheet>
      </v-sheet>
    </v-col>
  </v-row>
</div>
<div>
   <v-img
    class="align-end text-white"
    src="../../assets/portadapostgrado.webp"                            
    >
      <v-row justify="end" class="mb-10 mr-10">
        <v-col cols="4">
          <div class="background-white ">
            <h3 class="text-black px-5 py-5 text-Montserrat-SemiBold">Obtén el folleto</h3>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              class="px-5 pb-5"
            >
              <v-text-field
                v-model="nombre"
                :rules="nameRules"
                label="Nombre(s)"
                required
                variant="outlined"
                class="text-001C29 height-input"
              ></v-text-field>
              <v-text-field
                variant="outlined"
                v-model="apellido"
                :rules="nameRules"
                label="Apellidos(s)"
                class="text-001C29 height-input"
                required
              ></v-text-field>
              <v-text-field
                variant="outlined"
                v-model="email"
                :rules="nameRules"
                label="Email"
                required
                class="text-001C29 height-input"
              ></v-text-field>
              <v-text-field
                variant="outlined"
                v-model="numero"
                :rules="nameRules"
                label="Numero celular"
                class="text-001C29 height-input"
                required
              ></v-text-field>
              <v-text-field
                variant="outlined"
                v-model="pais"
                :rules="nameRules"
                label="Pais"
                required
                class="text-001C29 height-input"
              ></v-text-field>
              <p class="text-black text-size-p" v-if="1 == 1">Al hacer clic en el botón de abajo, aceptas recibir información de ESAM LATAM sobre este y otros programas relevantes, 
vía email/llamada/WhatsApp/SMS. </p>

              <template v-for="(cat, index) in areas" v-bind:key="index" >
                <v-btn block :style="'background-color : rgb('+cat.color+')'"
                class="text-black ">
                  descarga el fulleto
                </v-btn>
              </template>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-img> 
</div>
<div v-for="(cat, index) in areas" v-bind:key="index">
  <div :style="'background-color : rgb('+cat.color+')'">
    <v-row justify="center">
      <v-col cols="10" class="py-7">
        <v-row>
          <v-col cols="4">
            <div class="d-flex justify-center">
              <v-icon icon="mdi-book-arrow-right-outline" class="text-white icon-size pr-5"></v-icon>
              <div>
                <h3 class="text-Montserrat-Bold"> COMIENZA EL</h3>
                <h3 class="text-Montserrat-Bold"> 26 de Septiempre del 2022</h3>
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-center">
              <v-icon icon="mdi-clock-time-four-outline text-white icon-size pr-5"></v-icon>
              <div>
                <h3 class="text-Montserrat-Bold"> DURACIÓN</h3>
                <h3 class="text-Montserrat-Bold"> 6 Días</h3>
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-center">
              <v-icon icon="mdi-account-cash" class="text-white icon-size pr-5"></v-icon>
              <div>
                <h3 class="text-Montserrat-Bold"> INVERSIÓN</h3>
                <h3 class="text-Montserrat-Bold"> 600 Bs.-</h3>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</div>
<div class="py-10">
  <h3 class="text-center text-Montserrat-Bold">INSCRIPCIÓN ANTICIPADA</h3>
  <v-row justify="center">
    <v-col cols="10">
      <p class="text-Montserrat-Medium">
        Recibe un beneficio económico al iniciar tu aplicación y reservar tu lugar en cualquiera de nuestras rondas.
      </p>
    </v-col>
    <v-col cols="10" class="background-D8D6D6 " >
      <div class="d-flex justify-end">
        <p class="text-Montserrat-Medium">
          Costo del programa
        </p>
      </div>
      <div class="d-flex justify-end">
        <h4 class="text-Montserrat-Bold">
          700 Bs.-
        </h4>
      </div>
      <div class="d-flex justify-end">
        <h4 class="text-Montserrat-Bold">
          Paga antes del 26 de septiembre del 2022 a las 07:59 PM
        </h4>
      </div>
    </v-col>
  </v-row>
</div>
<div>
  <v-row justify="center">
    <v-col cols="6">  
      <Separador :id="1"/>
    </v-col>
    <v-col cols="6">
      <h2 class="text-Montserrat-Bold">INTRODUCCIÓN</h2>
    </v-col>
    <v-col cols="10">
      <p class="text-justify text-Montserrat-Medium mb-4">
        La tecnología avanza y con ella se crean nuevos puestos de trabajo. Los RPAS (Remotely Piloted Aircraft System), o vehículos aéreos no tripulados, más conocidos como “drones”, han venido para quedarse. La Unión Europea, estima que se crearán hasta 150.000 puestos de trabajo vinculados al pilotaje de drones antes de 2050. 
        Enfocando la formación de los pilotos a diversos sectores, tras la finalización del curso los participantes habrán desarrollado habilidades para operar como piloto de drones. Por otro lado, el objetivo es facilitarle una rápida inserción al mercado laboral. Dentro del amplio espectro de empleos que ofrecen los drones, este curso corto pretende preparar al participante, para que conozca los tipos de trabajos que se realizan dentro del sector de pilotaje de drones y sobretodo, cómo se realizan. 
      </p>
    </v-col>
  </v-row>
</div>
<div>
  <v-row justify="center">
    <v-col cols="6">  
      <Separador :id="1"/>
    </v-col>
    <v-col cols="6">
      <h2 class="text-Montserrat-Bold">OBJETIVOS</h2>
    </v-col>
    <v-col cols="10">
      <h3 class="text-Montserrat-Bold">GENERAL</h3>
      <p class="text-justify text-Montserrat-Medium">
      El estudiante será capaz de:
      Desarrollar habilidades de pilotaje de un vehículo aéreo no tripulado; drone, a través del conocimiento de sus características físicas y técnicas para el aprovechamiento del vuelo, captura y otras funcionalidades de esta herramienta, para sus diversos fines.
      </p>
    </v-col>
    <v-col cols="10">
      <h3 class="text-Montserrat-Bold">ESPECÍFICOS</h3>
      <p class="text-justify text-Montserrat-Medium mb-4">
       •	Entender el Funcionamiento de los componentes de un drone.
•	Entender el uso y las aplicaciones de la tecnología drone.
•	Aprender los diferentes modos de vuelo de los drones. 
•	Conceptos de seguridad muy importantes para el momento del vuelo, para cuidar la seguridad personal, del entorno y de los equipos.
      </p>
    </v-col>
  </v-row>
</div>
<div>
  <v-row justify="center">
    <v-col cols="6">  
      <Separador :id="1"/>
    </v-col>
    <v-col cols="6">
      <h2 class="text-Montserrat-Bold">CARACTERÍSTICAS DEL PROGRAMA</h2>
    </v-col>
    <v-col cols="10">
      <h3 class="text-Montserrat-Bold">1.	CRITERIOS DE ADMISIÓN</h3>
      <p class="text-justify text-Montserrat-Medium">
      Los postulantes para ser admitidos en el curso “Pilotaje de Drones” no requieren tener conocimientos previos sobre el mismo. Deberán ser mayores de 18 años.
        Así mismo, presentar los siguientes documentos:
        a.	Fotocopia simple de cédula de identidad.
        b.	Completar un formulario de registro.
        c.	Realizar el depósito por el precio asignado del curso.
      </p>
    </v-col>
    <v-col cols="10">
      <h3 class="text-Montserrat-Bold">2.	DURACIÓN</h3>
      <p class="text-justify text-Montserrat-Medium mb-4">
       El programa tiene una duración total de 8 clases meses, de los cuales 3 estarán destinadas al desarrollo teórico y 5 a la experiencia práctica para lograr la integralidad del curso.
El programa está organizado para que cada clase tenga una duración de 90 minutos.
      </p>
    </v-col>
  </v-row>
</div>
<div>
  <v-row justify="center">
    <v-col cols="6">  
      <Separador :id="1"/>
    </v-col>
    <v-col cols="6">
      <h2 class="text-Montserrat-Bold">PLAN TEMÁTICO GENERAL</h2>
    </v-col>
    <v-col cols="10">
      <h3 class="text-Montserrat-Bold">SEMANA 1 :</h3>
      <p class="text-justify text-Montserrat-Medium">
      I.-    Fundamentos Teóricos
II.-  Telemetría y Control
      </p>
    </v-col>
    <v-col cols="10">
      <h3>SEMANA 2 :</h3>
      <p class="text-justify text-Montserrat-Medium mb-4">
       III.- Operación y Control
IV.- Mantenimiento Correctivo y Preventivo
      </p>
    </v-col>
  </v-row>
</div>
<div>
  <v-row justify="center">
    <v-col cols="6">  
      <Separador :id="1"/>
    </v-col>
    <v-col cols="6">
      <h2 class="text-Montserrat-Bold">DOCENTE</h2>
    </v-col>
    <v-col cols="10">
      <v-row>
        <v-col cols="3">
        <v-img
          src="../../assets/docente.png"                            
          >
          </v-img> 
        </v-col>
        <v-col cols="9">
          <h3 class="text-Montserrat-Bold">ing. Gonzalo Loredo Espinoza</h3>
          <p class="text-Montserrat-Medium">
            • Piloto, Instructor y Examinador Teórico y Práctico de Drones Multirotor y de Ala Fija.
            • Experto en drones con más de 8 años de experiencia y 15 de trayectoria.                                                          
            • Fundador de la Primera Escuela de Pilotos de Drones Multirotor y Ala fija de Bolivia.
            • Capacitador en la Formación de Pilotos de Drones Multirotor y de Ala fija en diferentes Empresas, Universidades y Público en general.
            • Asesor y docente del Diplomado en Aplicaciones de Drones en Ingeniería .
            • Conocedor del Reglamento sobre aeronaves pilotadas a distancia (RPAS) en Bolivia. 
            •Ingeniero especialista en la fabricación, mantenimiento y Reparación de Drones de Ala Fija
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</div>
<div >
  <v-row justify="center">
    <v-col cols="6">  
      <Separador :id="1"/>
    </v-col>
    <v-col cols="6">
      <h2 class="text-Montserrat-Bold">CERTIFICADO</h2>
    </v-col>
    <v-col cols="10" class="py-5">
      <v-row>
        <v-col cols="9">
          <p class="text-Montserrat-Medium">
            Todos los participantes que concluyen satisfactoriamente el programa reciben un Certificado Digital Verificado de conclusión de estudios otorgado por la UNIVERSIDAD NACIONAL SIGLO XX.
          </p>
          
        <div v-for="(cat, index) in areas" v-bind:key="index">   
            <v-btn
            :rounded="0"
            class="text-black"
            height="50"
            :style="'background-color : rgb('+cat.color+')'" absolute
             v-if="1 == 0"
            >
            INVITAR AHORA
            </v-btn>
        </div>
        </v-col>
        <v-col cols="3">
        <v-img
          src="../../assets/certificado.png"                            
          >
          </v-img> 
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</div>
<div>
  <v-row>
    <v-col cols="12">
      <Separador :id="1"/>
    </v-col>
  </v-row>
</div>
<div class="py-5">  
  <div v-for="(cat, index) in areas" v-bind:key="index" class="d-flex justify-center">   
      <v-btn
      :rounded="0"
      class="text-black"
      height="50"
      :style="'background-color : rgb('+cat.color+')'"
      >
      INVITAR AHORA
      </v-btn>
  </div>
  <h3 class="text-center mb-4">
    Regístrate lo antes posible. El cupo es limitado.
  </h3>
</div>
<div>
  <v-row>
    <v-col cols="12">
      <Separador :id="1"/>
    </v-col>
  </v-row>
</div>
<div class="py-3">
  <p class="text-center mb-4">
    ESAM LATAM tiene una alianza con LA UNIVERSIDAD NACIONAL SIGLO XX,  para ofrecer un portafolio educativo de alto impacto
  </p>
</div>
</template>

<script>

import axios from 'axios'
var url ='backend-esamlatam/crud-posgrado.php';
var url_area ='backend-esamlatam/crud-area.php';
import Separador from '@/components/Separador.vue'

export default {
  name: 'EsamPostgrado',
  props: {
    id: Number,
  },
  components : {
    Separador,
  },
 methods: { 
   href(href){
    window.open(href);
   },
    
    mostrar_area(){
      axios.post(this.baseUrl+''+url_area,{opcion:6,id:6})
      .then(Response =>{
        this.areas=Response.data
      })
    },   
    mostrar_posgrado(){
      axios.post(this.baseUrl+''+url,{opcion:1,areas_id:6})
      .then(Response =>{
        this.posgrados=Response.data
      })
    }, 
 },
  computed: {
    ifcol(){
      
      if (screen.width > 0 && screen.width <= 600) {
        return true;
      }

      return true;
    },
      
  },
  data(){
    return {
      //baseUrl : "http://localhost/",
      baseUrl : "https://esamlatam.com/",
       baseImagen : "imagenes/img-postgrado/",
       areas : {},
       posgrados : {}
    };
  },
  mounted(){
    this.mostrar_area();
    this.mostrar_posgrado();
    }     

}
</script>

<style scoped>
.text-keep-calm-font{
  font-family: "keep-calm-font";
}
.text-MontserratAlternates-Regular{
  font-family: "MontserratAlternates-Regular";
}
.text-Montserrat-SemiBold{
  font-family: "Montserrat-SemiBold";
}
.text-Montserrat-Bold{
  font-family: "Montserrat-Bold";
}
.text-Montserrat-MediumItalic{
  font-family: "Montserrat-MediumItalic";
}
.text-Montserrat-Medium{
  font-family: "Montserrat-Medium";
}

.text-KeepCalm-Heavy{
  font-family: "KeepCalm-Heavy";
}
.text-KeepCalm-Medium{
  font-family: "KeepCalm-Medium";
}
.text-KeepCalm-Book{
  font-family: "KeepCalm-Book";
}
.text-KeepCalm-Light{
  font-family: "KeepCalm-Light";
}
.text-OpenSans-SemiBold{
  font-family: "OpenSans-SemiBold";
}

.margin-0{
  margin: 0px;
}
.margin-title{
  margin: 50px 10px 10px 10px;
}

.text-001C29{
  color: #001C29;
}
.text-94ccd8{
  color: #94ccd8;
}
.text-df9d34{
  color: #df9d34;
}
.text-cb1c7d{
  color: #cb1c7d;
}
.text-c6d04c{
  color: #c6d04c;
}
.text-6CD6E0{
  color: #6CD6E0;
}
.text-f8e865{
  color : #f8e865;
}
.text-F59C1B{
  color : #F59C1B;
}

.text-F20780{
  color: #F20780;
}
.text-1c75bc{
  color: #1c75bc;
}

.background-F20780{
  background-color: #F20780;
}
.background-F59C1B{
  background-color: #F59C1B;
}
.background-071D28{
  background-color: #071D28;
}
.background-D8D6D6{
  background-color: #D8D6D6;
}

.background-FFEA5A{
  background-color: #FFEA5A;
}

.background-C2D13D{
  background-color: #C2D13D;
}

.background-6CD6E0{
  background-color: #6CD6E0;
}

.background-1C75BC{
  background-color: #1C75BC;
}

.background-001C29{
  background-color: #001C29;
}
.background-DADADA{
  background-color: #DADADA;
}
.background-1C759F{
  background-color: #1C759F;
}
.background-white{
  background-color: white;
}
.text-0-8vw{
   font-size: 0.8vw
}
.text-size-icon{
   font-size: 6vw
}
.text-areas{
  color: #0076c0;
}
.avtar-text{ 
    -webkit-margin-end: 2px; 
    margin-inline-end: 2px;
}
.size-text-titulo{
    font-size: 5vh;
}
.width-buscador{
    width: 39%;
}
.cursor{
 cursor: pointer;
    color: inherit;
}
.cursor:hover {
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
} 


.bordes-negro{
letter-spacing: 0;
text-shadow: -1px -1px 0.2px #333, 0.5px -1px 0.2px #333;
}
.background-degradado{

background: linear-gradient(to  right, rgba(15,117,188,0), rgba(15,117,188,1),rgba(15,117,188,1), rgba(15,117,188,0))
}
.height-100{
  height:100%
}
.height-input{
  height: 70px;
}
.text-size-p{
  font-size: 1.5vh;
}
.icon-size{
  font-size: 8vh;
}
/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) { 
/** fin Banner */
.p-text{
    padding: 0% 0% 30% 0%;
}
.size-text-titulo{
    font-size: 1.8vh;
}
.size-text-area{
  font-size: 3.5vh;
}
.width-buscador{
    width: 80%;
}
 }

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) { 
    .size-text-titulo{
    font-size: 2.4vh;
}

.width-buscador{
    width: 58%;
}
.p-text{
    padding: 0% 0% 26% 0%;
}
.size-text-area{
  font-size: 3.5vh;
}
/** fin Banner */
 }

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) { 

.size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 57%;
}

.p-text{
    padding: 0% 0% 26% 0%;
}
.size-text-area{
  font-size: 3.8vh;
}
}
  
/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) { 
    .size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 42%;
}
.p-text{
    padding: 0% 0% 20% 0%;
}
.size-text-area{
  font-size: 3.8vh;
}
 }
@media (min-width: 1905px)  { 
  .size-text-titulo{
    font-size: 4vh;
}

.width-buscador{
    width: 42%;
}
.p-text{
    padding: 0% 0% 20% 0%;
}
.size-text-area{
  font-size: 3vh;
}
 }



</style>
