<template>
  <div class="home">
    <Postgrados />
  </div>
</template>

<script>
// @ is an alias to /src
import Postgrados from '@/components/Postgrados/PostgradosView.vue'

export default {
  name: 'NavbarEsamView',
  components: {
    Postgrados
  }
}
</script> 
