<template>
    <div class="margin-bandera">
        <v-row justify="center">
            <v-col cols="11">
                <v-window v-model="onboarding" show-arrows>
                    <v-window-item>
                        <v-row justify="center " class="d-flex">
                            <v-col cols="5">
                                <v-row justify="center">
                                    <v-col cols="4">
                                        <div>
                                            <v-hover v-slot="{ isHovering, props }">
                                                <v-img :aspect-ratio="16/9" width="95%" v-bind="props"
                                                    src="../../assets/Banderas/eeuu.webp">
                                                    <v-expand-transition>
                                                        <div v-if="isHovering"
                                                            class="d-flex transition-fast-in-fast-out  background-white v-card--reveal text-h2 text-white"
                                                            style="height: 100%;">
                                                            <v-row justify="center">
                                                                <v-col cols="12">
                                                                    <v-img width="100%" v-bind="props"
                                                                        src="../../assets/logos/cefayr.png">
                                                                    </v-img>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-expand-transition>
                                                </v-img>
                                            </v-hover>
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <div>
                                            <v-hover v-slot="{ isHovering, props }">
                                                <v-img :aspect-ratio="16/9" width="95%" v-bind="props"
                                                    src="../../assets/Banderas/spain.webp">
                                                    <v-expand-transition>
                                                        <div v-if="isHovering"
                                                            class="d-flex transition-fast-in-fast-out  background-white v-card--reveal text-h2 text-white"
                                                            style="height: 100%;">
                                                            <v-row justify="center">
                                                                <v-col cols="12">
                                                                    <v-carousel cycle interval="1000"
                                                                        height="max-height" hide-delimiter-background
                                                                        :show-arrows="false" hide-delimiters>
                                                                        <v-carousel-item
                                                                            src="../../assets/logos/ucam.png">
                                                                        </v-carousel-item>
                                                                        <v-carousel-item
                                                                            src="../../assets/logos/uemc.png">
                                                                        </v-carousel-item>
                                                                    </v-carousel>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-expand-transition>
                                                </v-img>
                                            </v-hover>
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <div>
                                            <v-hover v-slot="{ isHovering, props }">
                                                <v-img :aspect-ratio="16/9" width="95%" v-bind="props"
                                                    src="../../assets/Banderas/mexico.webp">
                                                    <v-expand-transition>
                                                        <div v-if="isHovering"
                                                            class="d-flex transition-fast-in-fast-out  background-white v-card--reveal text-h2 text-white"
                                                            style="height: 100%;">
                                                            <v-row justify="center">
                                                                <v-col cols="12">
                                                                    <v-img width="100%" v-bind="props"
                                                                        src="../../assets/logos/ubj.png">
                                                                    </v-img>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-expand-transition>
                                                </v-img>
                                            </v-hover>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="2">
                                <v-row justify="center">
                                    <v-col cols="10">
                                        <div>
                                            <v-hover v-slot="{ isHovering, props }">
                                                <v-img :aspect-ratio="16/9" width="95%" v-bind="props"
                                                    src="../../assets/Banderas/panama.webp">
                                                    <v-expand-transition>
                                                        <div v-if="isHovering"
                                                            class="d-flex transition-fast-in-fast-out background-white v-card--reveal text-h2 text-white"
                                                            style="height: 100%;">
                                                            <v-row justify="center">
                                                                <v-col cols="11">
                                                                    <v-img width="100%" v-bind="props"
                                                                        src="../../assets/logos/umecit.png">
                                                                    </v-img>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-expand-transition>
                                                </v-img>
                                            </v-hover>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="5">
                                <v-row justify="center">
                                    <v-col cols="4">
                                        <div>
                                            <v-hover v-slot="{ isHovering, props }">
                                                <v-img :aspect-ratio="16/9" width="95%" v-bind="props"
                                                    src="../../assets/Banderas/colombia.webp">
                                                    <v-expand-transition>
                                                        <div v-if="isHovering"
                                                            class="d-flex transition-fast-in-fast-out background-white v-card--reveal text-h2 text-white"
                                                            style="height: 100%;">
                                                            <v-row justify="center">
                                                                <v-col cols="11">
                                                                    <v-img width="100%"
                                                                        src="../../assets/logos/ndalatam.png">
                                                                    </v-img>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-expand-transition>
                                                </v-img>
                                            </v-hover>
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <div>
                                            <v-hover v-slot="{ isHovering, props }">
                                                <v-img :aspect-ratio="16/9" width="95%" v-bind="props"
                                                    src="../../assets/Banderas/bolivia.webp">
                                                    <v-expand-transition>
                                                        <div v-if="isHovering"
                                                            class="d-flex transition-fast-in-fast-out  background-white v-card--reveal text-h2 text-white"
                                                            style="height: 100%;">
                                                            <v-row justify="center">
                                                                <v-col cols="12">
                                                                    <v-carousel cycle interval="1000"
                                                                        height="max-height" hide-delimiter-background
                                                                        :show-arrows="false" hide-delimiters>
                                                                        <v-carousel-item
                                                                            src="../../assets/logos/UAJMS.png">
                                                                        </v-carousel-item>
                                                                        <v-carousel-item
                                                                            src="../../assets/logos/UNSXX.png">
                                                                        </v-carousel-item>
                                                                    </v-carousel>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-expand-transition>
                                                </v-img>
                                            </v-hover>
                                        </div>
                                    </v-col>
                                    <v-col cols="4">
                                        <div>
                                            <v-hover v-slot="{ isHovering, props }">
                                                <v-img :aspect-ratio="16/9" width="95%" v-bind="props"
                                                    src="../../assets/Banderas/ecuador.webp">
                                                    <v-expand-transition>
                                                        <div v-if="isHovering"
                                                            class="d-flex transition-fast-in-fast-out background-white v-card--reveal text-h2 text-white"
                                                            style="height: 100%;">
                                                            <v-row justify="center">
                                                                <v-col cols="11">
                                                                    <v-img width="100%"
                                                                        src="../../assets/logos/anasca.png">
                                                                    </v-img>
                                                                </v-col>
                                                            </v-row>
                                                        </div>
                                                    </v-expand-transition>
                                                </v-img>
                                            </v-hover>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                    </v-window-item>
                </v-window>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'EsamBandera',
    props: {
        id_categoria: Number,
        condicion: Boolean,
    },
    components: {
    },
    computed: {

        width_video() {
            if (screen.width > 0 && screen.width <= 600) {
                return "440";
            }

            if (screen.width > 600 && screen.width <= 960) {
                return "640";
            }

            if (screen.width > 960 && screen.width <= 1264) {
                return "840";
            }

            if (screen.width > 1264 && screen.width <= 1904) {
                return "1040";
            }

            if (screen.width > 1904) {
                return "1240";
            }
            return "small";
        },
    },
    methods: {
        getheight() {
            if (screen.width > 0 && screen.width <= 600) {
                this.carouselheight = 250
            }
            if (screen.width > 600 && screen.width <= 960) {
                this.carouselheight = 350
            }
            if (screen.width > 960 && screen.width <= 1264) {
                this.carouselheight = 400
            }
            if (screen.width > 1264 && screen.width <= 1904) {
                this.carouselheight = 500
            }
            if (screen.width > 1904) {
                this.carouselheight = 650
            }
        },


    },
    data() {
        return {
            carouselheight: 0,
            baseUrl: "https://esamlatam.com/",
            //baseUrl : "http://localhost/",
            baseImagen: "imagenes/img-slider/",
            sliders: [],
            length: 3,
            onboarding: 0,
        };
    },
    mounted() {
    }



}
</script>

<style scoped>
.text-KeepCalm-Heavy {
    font-family: "KeepCalm-Heavy";
}

.text-KeepCalm-Medium {
    font-family: "KeepCalm-Medium";
}

.text-KeepCalm-Book {
    font-family: "KeepCalm-Book";
}

.text-KeepCalm-Light {
    font-family: "KeepCalm-Light";
}

.text-OpenSans-SemiBold {
    font-family: "OpenSans-SemiBold";
}


.text-001C29 {
    color: #001C29;
}

.text-6CD6E0 {
    color: #6CD6E0;
}

.text-F59C1B {
    color: #F59C1B;
}

.text-F20780 {
    color: #F20780;
}

.background-F20780 {
    background-color: #F20780;
}

.background-F59C1B {
    background-color: #F59C1B;
}

.background-FFEA5A {
    background-color: #FFEA5A;
}

.background-C2D13D {
    background-color: #C2D13D;
}

.background-6CD6E0 {
    background-color: #6CD6E0;
}

.background-1C75BC {
    background-color: #1C75BC;
}

.background-001C29 {
    background-color: #001C29;
}

.background-DADADA {
    background-color: #DADADA;
}

.background-1C759F {
    background-color: #1C759F;
}

.margin-bandera {
    margin: 50px 0px 50px 0px;
}

/** Mobile Devices  **/
@media (min-width: 0px) and (max-width: 600px) {
    /** fin Banner */

}

/** Tablet Devices */
@media (min-width: 601px) and (max-width: 960px) {}

/** fin Banner */

/** Desktop Devices */
@media (min-width: 961px) and (max-width: 1264px) {}

/** Large Desktop Devices */
@media (min-width: 1265px) and (max-width: 1904px) {}

@media (min-width: 1905px) {}
</style>
